import React from 'react'
import { Button } from '@material-ui/core'
import { ExampleFiles } from './example-files'
import { ToggleSidebar, SidebarSectionProps } from '@marketing-milk/frontend'

export const sections: SidebarSectionProps[] = [
  {
    title: 'Upload Status',
    text: (
      <>
        The Upload Status falls into 3 categories:
        <ul>
          <li>
            <span className="text-info">Data Check:</span> your file is being checked to see that
            its format meets the requirement of the example files, and that facebook can accept the
            data correctly. This allows is to prevent future upload issues, affecting match rates.
            Until then, we are unable to allow you to submit for review.
          </li>
          <li>
            <span className="text-info">Review:</span> your file is sent to your director and is
            being looked over for any issues. It will return as either accepted or rejected with
            feedback on what to change on the most recent Version details page.
          </li>
          <li>
            <span className="text-info">Upload:</span> your file was processed by the system and
            either finished or failed. If the process failed, please be sure to contact support.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Actions',
    text: 'The submit upload section is where you can submit your upload to be reviewed and/or send the data for processing once the file has passed all of its validations. PLEASE NOTE: You can only submit for approval if the data check has passed. This is also where you can change the format from Events to Historical Results if you would like. See examples below.',
  },
  {
    title: 'File Locked',
    text: 'When your file has either been submitted for review, or already sent for processing, your file will be locked from further editing for the time being. In the case that your file is in review, you may cancel the submission at any time if you have changes to make.',
  },
  {
    title: 'Upload New Version',
    text: 'This is where you will update this upload with a new version of a file. Any data concerns address in the version details should be changed on the file and uploaded here. To see expected format of uploaded files, please see the example files below.',
  },
]

export function UploadDetailsHelp() {
  return (
    <ToggleSidebar
      toggleContent={<Button variant="contained">Help</Button>}
      title="Upload Details"
      description="The upload details page keeps track of the status of the current upload as well as a list of previously uploaded versions."
      note={
        <div className="p-2">
          <p>
            <span className="font-weight-bold">Note:</span> You can only submit the most recent
            version for approval. Also, currently aggregate data (monthly data) is always recognized
            as NOT benchmark data format. So that needs to be changed by the AM upon upload of new
            version.
          </p>
          <p className="font-weight-bold mb-0">
            Insights will be fetched after 24 hours has lapsed since an upload. We will fetch
            insights once more 48 hours after an upload to ensure we fetch any late attribution.
          </p>
        </div>
      }
      sections={sections}
    >
      <hr />
      <div className="row text-center">
        <ExampleFiles />
      </div>
    </ToggleSidebar>
  )
}
