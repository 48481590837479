import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'
import { DataSource } from '@marketing-milk/interfaces'

export const DataSourceActions = bindActions(
  store => ({
    toggle: (state: any, dataSource: DataSource) => {
      const { dataSources } = state.pages.business
      const finalDataSources: number[] = dataSources.includes(dataSource.id)
        ? dataSources.filter(x => x !== dataSource.id)
        : [...dataSources, dataSource.id]

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            dataSources: finalDataSources,
          },
        },
      })
    },
  }),
  Store
)
