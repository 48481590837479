import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { MaterialDataTable } from '@marketing-milk/frontend'
import { SaveDraftButton } from './save-draft-button/SaveDraft'
import { ValidationTableDataErrorsProps } from './DataErrors.types'
import { GridCellParams, GridToolbarContainer, GridToolbarExport } from '@material-ui/x-grid'
import { createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .super-app.negative': {
        backgroundColor: 'rgba(244, 81, 108, 0.55)',
      },
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
)

export function ValidationTableDataErrors({ report, upload }: ValidationTableDataErrorsProps) {
  const classes = useStyles()
  const [data, setData] = useState<object[]>([])
  const [draftMode, setDraft] = useState(false)

  useEffect(() => {
    function getData(): object[] {
      let newData = [{}]
      if (report.type === 'data') {
        report.errors.forEach((error, index1) => {
          let object = {}
          error.cells.forEach((cell, index2) => {
            //create regular column row
            object[report.headers[index2]] = cell
            newData[index1] = object
            //add row number for error location detection
            newData[index1]['id'] = index1 + 2
            //add errors
            newData[index1]['errors'] = report.errors[index1].errors ?? {}
          })
        })
      }
      //unshift "id" to headers array so that when the column is mapped this custom column gets added to the table
      report.headers.unshift('id')

      //sort data by error count
      //@ts-ignore - cant type object since object is created dynamically based on validation report
      newData.sort((a, b) => b.errors.length - a.errors.length)

      return newData
    }

    setData(getData())
  }, [report])

  function onEditCell(params: any) {
    if (!draftMode) {
      setDraft(true)
    }

    setData(currentData => {
      const newData = [...currentData]

      newData.find(_ => (_ as any).id === params.id)![params.field] = params.props.value

      return newData
    })
  }

  return (
    <>
      <MaterialDataTable
        sortingOrder={['asc', 'desc']}
        rows={data}
        className={classes.root}
        columns={report.headers.map(_ => ({
          field: _,
          headerName: _,
          width: 150,
          type: 'string',
          editable: !upload.locked,
          renderCell: ({ field, row, value }: GridCellParams) => {
            const cellError = (row as any)?.errors?.find(_ => _.field === field)?.error

            if (!cellError) {
              return <>{value}</>
            }

            return (
              <>
                {cellError && (
                  <>
                    <div>{value}</div>
                    &nbsp;
                    <small className="text-danger">
                      <i className="fa fa-exclamation-triangle mr-2" />
                      {cellError}
                    </small>
                  </>
                )}
              </>
            )
          },
          cellClassName: ({ row, field }: GridCellParams) => {
            const cellError = (row as any)?.errors?.find(_ => _.field === field)?.error
            return clsx('super-app', {
              negative: !!cellError,
            })
          },
        }))}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarExport />
            </GridToolbarContainer>
          ),
        }}
        rowHeight={38}
        headerHeight={50}
        density="comfortable"
        columnBuffer={10}
        checkboxSelection={false}
        pagination
        pageSize={10}
        onEditCellChangeCommitted={onEditCell}
      />
      {draftMode && (
        <div className="row mt-4">
          <div className="col-12 text-right">
            <SaveDraftButton upload={upload} newData={data} />
          </div>
        </div>
      )}
    </>
  )
}
