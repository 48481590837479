import { DataSourceService } from './DataSource.types'
import { DataSourceImplementation } from './DataSource'
import { DataSourceMockImplementation } from './DataSource.mock'

export * from './DataSource'
export * from './DataSource.mock'
export * from './DataSource.types'

export const dataSourceService: DataSourceService =
  process.env.NODE_ENV === 'test'
    ? new DataSourceMockImplementation()
    : new DataSourceImplementation()
