import Tables from './tables'
import Redux from 'app/interface/redux'
import React, { PureComponent } from 'react'
import Wizard from 'components/base/wizard'
import Loading from 'components/message/loading'
import Portlet from 'components/reusable/portlet'
import { DeletedBusinessesModal } from './deleted-businesses-modal/DeletedBusinessesModal'

interface State {
  refresh: number
  loading: boolean
  showDeletedBusinesses: boolean
}

class List extends PureComponent<Redux, State> {
  state: State = {
    refresh: 0,
    loading: false,
    showDeletedBusinesses: false,
  }

  toggleLoading = () => {
    this.setState(_ => ({
      ..._,
      loading: !_.loading,
    }))
  }

  toggleDeletedBusinesses = () => {
    this.setState(_ => ({
      ..._,
      showDeletedBusinesses: !_.showDeletedBusinesses,
    }))
  }

  refreshData = () => {
    this.setState(_ => ({
      ..._,
      refresh: _.refresh + 1,
    }))
  }

  buttons = [
    {
      to: '/admin/business/beta/accounts/create',
      class: 'btn btn-info',
      text: 'Create Account',
    },
    {
      to: this.toggleDeletedBusinesses,
      class: 'btn btn-danger text-white',
      text: 'Recover Account',
    },
  ]

  render() {
    const { loading, showDeletedBusinesses, refresh } = this.state
    return (
      <>
        <Portlet title="Businesses" buttons={this.buttons}>
          <div style={{ float: 'right' }}>
            <DeletedBusinessesModal
              key={`delete_${refresh}`}
              onDelete={this.refreshData}
              onClose={this.toggleDeletedBusinesses}
              isOpen={showDeletedBusinesses}
            />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <Wizard
              key={`table_${refresh}`}
              steps={[
                {
                  text: 'All Businesses',
                  component: <Tables.Good onChange={this.refreshData} />,
                },
                {
                  text: 'Businesses With Errors',
                  component: <Tables.Bad onChange={this.refreshData} />,
                },
              ]}
              type="horizontal_stretch"
              portlet={false}
            />
          )}
        </Portlet>
      </>
    )
  }
}

export default List
