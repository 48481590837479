import { Auth0Role } from '@marketing-milk/interfaces'
import React, { useEffect, useState } from 'react'
import Select, {
  ActionMeta,
  GroupedOptionsType,
  OptionsType,
  OptionTypeBase,
  ValueType,
} from 'react-select'
import { customDropdownStyles } from './DropdownStyles'

type CustomSelectProps<T> = {
  options: OptionsType<T & OptionTypeBase> | GroupedOptionsType<T & OptionTypeBase> | undefined
  onChange: (
    value: ValueType<T & OptionTypeBase, boolean>,
    actionMeta: ActionMeta<T & OptionTypeBase>
  ) => void
  clearSelection: boolean
  placeHolderText: string
  fieldName: string
  isMulti: boolean
  error?: boolean
  disabled?: boolean
  defaultValues?: any
  disableMultiSelectExpansion?: boolean
}

export function CustomSelect<T>({
  options,
  onChange,
  clearSelection,
  placeHolderText,
  fieldName,
  isMulti,
  error,
  disabled,
  defaultValues,
  disableMultiSelectExpansion = true,
}: CustomSelectProps<T>) {
  const [selectRef, setSelectRef] = useState<any>()

  useEffect(() => {
    if (clearSelection || disabled) {
      selectRef?.select?.clearValue()
    }
  }, [clearSelection, disabled])

  return (
    <Select
      ref={c => {
        setSelectRef(c)
      }}
      defaultValue={defaultValues}
      styles={customDropdownStyles(error, disableMultiSelectExpansion)}
      menuPortalTarget={document.body}
      placeholder={placeHolderText}
      isMulti={isMulti}
      name={fieldName}
      onChange={onChange}
      options={options}
      className={`select-${fieldName}`}
      classNamePrefix="select"
      isDisabled={disabled}
    />
  )
}
