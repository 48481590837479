import Group from './group'
import Interface from './interface'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import React, { Component, FormEvent } from 'react'

export default class extends Component<Interface> {
  state = {
    errors: [],
  }

  submit = (event: FormEvent): void => {
    const { errors } = this.state
    event.preventDefault()

    if (errors.length === 0) {
      this.props.onSubmit()
    } else {
      toast.error('Please fix the errors before saving')
    }
  }

  onError = (column: string, value: boolean): void => {
    // @ts-ignore
    let final: Array<string> = [].concat(prevState.errors)
    const exists = !!final.find(x => x === column)

    if (value && !exists) {
      final.push(column)
    } else if (!value && exists) {
      final = final.filter(x => x !== column)
    }

    this.setState({
      errors: final,
    })
  }

  render() {
    const { cancel, groups, onChange, save = true } = this.props
    const { errors } = this.state
    return (
      <div className="m-wizard__form">
        <div className="m-wizard__form-step m-wizard__form-step--current">
          <form className="m-form m-form--fit" onSubmit={this.submit}>
            {groups.map((group, i) => (
              <Group
                key={i}
                errors={errors}
                onChange={onChange}
                onError={this.onError}
                {...group}
              />
            ))}
            {save || cancel ? (
              <div className="m-portlet__foot m-portlet__no-border m-portlet__foot--fit mt-4">
                <div className="row">
                  <div className="col-md-12 text-right">
                    {cancel ? (
                      <Link className="btn btn-secondary cancel-button" to={cancel}>
                        Cancel
                      </Link>
                    ) : null}
                    {save ? (
                      <button
                        className="btn btn-success mr-2"
                        disabled={errors.length > 0}
                        type="submit"
                      >
                        Save
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    )
  }
}
