import { exampleUser, UploadSubmission } from '@marketing-milk/interfaces'
import { UploadSubmissionService } from 'app/service/upload-submission/UploadSubmissionService'

export const uploadSubmissionService = UploadSubmissionService

export const examplePendingUploadVersionSubmission: UploadSubmission = {
  id: 0,
  displayName: 'Mind Body',
  businessID: 1,
  businessName: 'Anne Marie',
  submittedUser: exampleUser,
  approved: false,
  pending: true,
  createdAt: '10-10-2013',
  updatedAt: '10-10-2013',
  aggregate: false,
  versionID: 1,
  uploadID: 1,
}

export const exampleApprovedUploadVersionSubmission: UploadSubmission = {
  id: 1,
  displayName: 'Ring Central',
  businessID: 1,
  businessName: 'Anne Marie',
  submittedUser: exampleUser,
  reviewedUser: exampleUser,
  approved: true,
  pending: false,
  createdAt: '10-10-2013',
  updatedAt: '10-10-2013',
  aggregate: true,
  versionID: 1,
  uploadID: 1,
}

export const exampleRejectedUploadVersionSubmission: UploadSubmission = {
  id: 2,
  displayName: 'Drive',
  businessID: 1,
  businessName: 'Anne Marie',
  submittedUser: exampleUser,
  reviewedUser: exampleUser,
  approved: false,
  pending: false,
  message: 'this suck',
  createdAt: '10-10-2013',
  updatedAt: '10-10-2013',
  aggregate: false,
  versionID: 1,
  uploadID: 1,
}

export const exampleUploadVersionSubmissions: UploadSubmission[] = [
  examplePendingUploadVersionSubmission,
  exampleApprovedUploadVersionSubmission,
  exampleRejectedUploadVersionSubmission,
]
