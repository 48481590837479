import Select from 'react-select'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { businessService } from 'app/service/business'
import { useCreateFetchHook } from '@marketing-milk/frontend'
import { badDataSubmissionContext } from '../../../submission-context/BadDataSubmissionContext'
import { uniq } from 'lodash'

export function SelectOfflineEvent() {
  const params: { business: string } = useParams()
  const { submission, setSubmission } = useContext(badDataSubmissionContext)
  const eventMappings = useCreateFetchHook(() =>
    params.business
      ? businessService.getEventMappings(Number(params.business))
      : Promise.resolve([])
  )

  const options = uniq(eventMappings?.map(event => event.incoming)).map(incoming => ({
    label: incoming,
    value: incoming,
  }))

  return eventMappings ? (
    <Select
      options={options}
      value={submission.eventName ? options!.find(_ => _.label === submission.eventName) : null}
      onChange={(opt: any) => setSubmission!({ eventName: opt.value })}
    />
  ) : (
    <>
      <i className="fa fa-spinner fa-spin mr-2" /> Loading event mappings
    </>
  )
}
