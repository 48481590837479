import Components, { Types } from './types'
import React, { PureComponent } from 'react'

export interface IWizard {
  type: keyof Types
  steps: Array<IStep>
  portlet?: boolean
  buttons?: boolean
}

export interface IWizardState {
  active: number
}

export interface IStep {
  text: any
  component: any
}

export interface IWizardComponent {
  active: number
  steps: Array<IStep>
  setActive: Function
}

class Wizard extends PureComponent<IWizard, IWizardState> {
  state = {
    active: 0,
  }

  setActive = (index: number): void => {
    this.setState({
      active: index,
    })
  }

  getView = () => {
    const { type, steps } = this.props
    const { active } = this.state

    const props = {
      active: active,
      steps: steps,
      setActive: this.setActive,
    }

    const Component = Components[type]

    return <Component {...props} />
  }

  goBack = () => {
    this.setState({
      active: this.state.active - 1,
    })
  }

  goForward = () => {
    this.setState({
      active: this.state.active + 1,
    })
  }

  render() {
    const { active } = this.state
    const { buttons = false, portlet = true, steps } = this.props

    return (
      <div className={portlet ? 'kt-portlet' : ''}>
        <div className="kt-portlet__body kt-portlet__body--fit">
          {this.getView()}
          {buttons ? (
            <div className="kt-form__actions">
              <div className="row">
                <div className="col-6">
                  {active > 0 ? (
                    <div
                      className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                      onClick={this.goBack}
                    >
                      Previous
                    </div>
                  ) : null}
                </div>
                <div className="col-6 text-right">
                  {active !== steps.length - 1 ? (
                    <div
                      className="btn btn-primary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                      onClick={this.goForward}
                    >
                      Next Step
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default Wizard
