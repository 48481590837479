import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { clientUploadService } from '@marketing-milk/frontend'
import { DownloadUploadButtonProps } from './DownloadUploadButton.types'

export function DownloadUploadButton({ upload, version }: DownloadUploadButtonProps) {
  const [isLoading, setLoading] = useState(false)

  const latestVersion = version ?? upload.versions[0]

  if (!latestVersion) {
    return null
  }

  async function onDownload() {
    try {
      if (isLoading) {
        return
      }

      setLoading(true)
      await clientUploadService.downloadVersion(
        upload.businessID,
        upload.id,
        latestVersion.id,
        latestVersion.fileName
      )
    } catch (e) {
      toast.error(`There was a problem when attempting to download ${latestVersion.fileName}`)
      throw e
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button color="primary" onClick={onDownload} variant="contained">
      <i className={`fa ${isLoading ? 'fa-spinner fa-spin' : 'fa-download'}`} />
    </Button>
  )
}
