import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { badDataSubmissionContext } from './BadDataSubmissionContext'
import { CreateBadDataSubmission } from '@marketing-milk/interfaces'
import { clientUploadService, useCreateFetchHook } from '@marketing-milk/frontend'
import {
  BadDataSubmissionContextProviderProps,
  defaultSubmissionContext,
} from './BadDataContext.types'

export function BadDataSubmissionContextProvider({
  children,
}: BadDataSubmissionContextProviderProps) {
  const params: { business: string } = useParams()
  const uploads = useCreateFetchHook(() => clientUploadService.getFiles(Number(params.business)))
  const [submission, setSubmission] = useState<CreateBadDataSubmission>(
    defaultSubmissionContext.submission
  )

  function updateSubmission(changes: Partial<CreateBadDataSubmission>) {
    setSubmission(_ => ({
      ..._,
      ...changes,
    }))
  }

  return (
    <badDataSubmissionContext.Provider
      value={{ submission, setSubmission: updateSubmission, uploads: uploads ?? [] }}
    >
      {children}
    </badDataSubmissionContext.Provider>
  )
}
