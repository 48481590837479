import React, { useState, useEffect } from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Auth0Role } from '@marketing-milk/interfaces'

export interface DeleteRoleModalProps {
  deleteRole?: Auth0Role
  onDelete: (roleID: string) => void
  onCancel?: () => void
}

export function DeleteRoleModal(props: DeleteRoleModalProps) {
  const [open, setOpen] = useState(!!props.deleteRole)

  useEffect(() => {
    // Check for role to delete, if one is present show modal
    !!props.deleteRole ? setOpen(true) : setOpen(false)
  }, [props.deleteRole])

  const onDelete = () => {
    setOpen(false)
    props.deleteRole && props.onDelete(props.deleteRole.id!)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Delete Role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the role:{' '}
          <strong>{props.deleteRole && props.deleteRole.name}</strong>? This can't be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button className="text-danger" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
