import { MaterialDataTable } from '@marketing-milk/frontend'
import { Franchise } from '@marketing-milk/interfaces'
import { Button, IconButton } from '@material-ui/core'
import { GridCellParams, GridColDef, GridColumnTypesRecord } from '@material-ui/x-grid'
import { useDebounce } from 'hooks'
import { useFetchAllFranchises } from 'hooks/franchiseHooks'
import { PageHeader } from 'pages/user-management/PageHeader'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FranchiseCreate } from './FranchiseCreate'
import { FranchiseDelete } from './FranchiseDelete'

enum FranchiseTableTypes {
  Name = 'Name',
  Actions = 'Actions',
}

const franchiseColTypes = (
  history: ReturnType<typeof useHistory>,
  resetTableState: () => void
): GridColumnTypesRecord => {
  return {
    [FranchiseTableTypes.Name]: {
      type: 'string',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 4,
      renderCell: ({ row: data }: GridCellParams) => {
        const row = data as Franchise
        return (
          <div
            style={{
              display: 'contents',
              cursor: 'pointer',
            }}
            onClick={() => history.push(`${row.id}/edit`)}
          >
            {row.name}
          </div>
        )
      },
    },
    [FranchiseTableTypes.Actions]: {
      type: 'button',
      headerName: '',
      disableColumnMenu: true,
      flex: 1,
      resizable: false,
      renderCell: ({ row }: GridCellParams) => {
        const franchise = row as Franchise

        return <FranchiseDelete franchise={franchise} onDelete={resetTableState} />
      },
    },
  }
}

const franchiseTableColumns: GridColDef[] = [
  {
    field: 'name',
    type: FranchiseTableTypes.Name,
    headerName: 'Name',
  },
  {
    field: '',
    type: FranchiseTableTypes.Actions,
    headerName: 'Actions',
  },
]

export const FranchiseList = () => {
  const history = useHistory()
  const franchiseHook = useFetchAllFranchises()
  const [searchTerm, setSearchTerm] = useState('')
  const { isDebouncing, debouncedValue } = useDebounce(searchTerm, 1000)

  useEffect(() => {
    franchiseHook.setPaginationParams(prevParams => ({
      ...prevParams,
      searchTerm: debouncedValue.trim().toLowerCase(),
      page: 0,
    }))
  }, [debouncedValue])

  const resetTableState = () => {
    franchiseHook.refetch()
    setSearchTerm('')
    franchiseHook.setPaginationParams(prevParams => ({
      ...prevParams,
      searchTerm: '',
      page: 0,
    }))
  }

  return (
    <>
      <PageHeader
        title="Franchises"
        filterValue={searchTerm}
        onChangeFilter={value => {
          setSearchTerm(value)
        }}
      >
        <div style={{ flex: 'none' }}>
          <FranchiseCreate onSuccess={resetTableState} />
        </div>
      </PageHeader>

      <div>
        <MaterialDataTable
          columns={franchiseTableColumns}
          columnTypes={franchiseColTypes(history, resetTableState)}
          rows={franchiseHook?.franchises ?? []}
          pagination
          pageSize={franchiseHook.pageSize}
          page={franchiseHook.page}
          paginationMode={'server'}
          onPageChange={change => {
            franchiseHook.setPaginationParams(prevParams => ({
              ...prevParams,
              page: change.page + 1,
            }))
          }}
          rowCount={franchiseHook.count}
          height={'calc(100vh - 15rem)'}
          density="comfortable"
        />
      </div>
    </>
  )
}
