import React from 'react'
import Row from './row'
import Interface from './interface/group'

export default ({ header, rows, onChange, onError, disabled }: Interface) => (
  <div className="m-form__section">
    <div className="m-form__heading">
      <h3 className="m-form__heading-title">{header}</h3>
    </div>
    {rows.map((row, i) => (
      <Row key={i} onChange={onChange} onError={onError} disabled={disabled} {...row} />
    ))}
    <div className="m-form__seperator m-form__seperator--dashed mt-5 mb-3" />
  </div>
)
