import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'

interface Message {
  column: string
  message: string
}

const Errors = bindActions(
  () => ({
    setErrors: (state: any, messages: Array<Message>) => ({
      core: {
        ...state.core,
        errors: messages,
      },
    }),

    delErrors: (state: any, column: string) => ({
      core: {
        ...state.core,
        errors: state.core.errors.filter(x => x.column !== column),
      },
    }),

    clear: (state: any) => ({
      core: {
        ...state.core,
        errors: [],
      },
    }),
  }),
  Store
)

export default Errors
