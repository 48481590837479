import React, { useState } from 'react'
import { Auth0Role } from '@marketing-milk/interfaces'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { IconButton, Menu, MenuItem, Divider } from '@material-ui/core'

export interface RolesTableProps extends Omit<RolesDropdownProps, 'role'> {
  roles?: Auth0Role[]
}

export function RolesTable(props: RolesTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.roles?.map(role => (
            <TableRow key={role.id}>
              <TableCell
                className="cursor-pointer"
                component="th"
                scope="row"
                onClick={() => props.onView(role)}
              >
                {role.name}
              </TableCell>
              <TableCell className="cursor-pointer" align="left" onClick={() => props.onView(role)}>
                {role.description}
              </TableCell>
              <TableCell align="right">
                <RolesDropdown role={role} {...props} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export interface RolesDropdownProps {
  role: Auth0Role
  onView: (role: Auth0Role) => void
  onAssignUsers?: (roleID: string) => void
  onDelete: (role: Auth0Role) => void
}

export function RolesDropdown({ role, onView, onAssignUsers, onDelete }: RolesDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const clickView = (role: Auth0Role) => {
    handleClose()
    onView(role)
  }
  const clickAssignUsers = (roleID: string) => {
    handleClose()
    onAssignUsers && onAssignUsers(roleID)
  }
  const clickDelete = (role: Auth0Role) => {
    handleClose()
    onDelete(role)
  }
  return (
    <>
      <IconButton
        id="roles-dropdown-button"
        aria-controls={open ? 'roles-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="roles-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'roles-dropdown-button',
        }}
      >
        <MenuItem onClick={() => clickView(role)}>View Details</MenuItem>
        <MenuItem onClick={() => clickAssignUsers(role.id!)}>Assign Users</MenuItem>
        <Divider />
        <MenuItem className="text-danger" onClick={() => clickDelete(role)}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}
