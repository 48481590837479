import React from 'react'

interface IErrorProps {
  body?: string
}

class Error extends React.Component<IErrorProps> {
  render() {
    const { body } = this.props
    return (
      <div>
        <i className="fa fa-warning fa-2x" />
        <h4>Error Occurred</h4>
        {body ? <p>{body}</p> : <p>An error occurred and this data can't be displayed.</p>}
      </div>
    )
  }
}

export default Error
