import { EventName, UserUpload } from '@marketing-milk/interfaces'

export interface ChooseEventNameProps {
  upload: UserUpload
}

export interface ChooseEventNameState {
  disableSelect: boolean
}

export const defaultChooseEventNameState: ChooseEventNameState = {
  disableSelect: true,
}

export interface Option {
  label: EventName
  value: EventName
}
