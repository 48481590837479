import { Component } from '.'
import Icons from 'app/lib/icons.json'
import Row from 'components/base/form/row'
import React from 'react'
import Input from 'components/base/form/interface/input'
import { SupportStringified } from 'app/interface/data/funnel/support'
import StageActions from 'app/redux/actions/pages/business/funnel/stage'
import { StageTarget } from '@marketing-milk/interfaces'

const options: Array<Options> = [
  {
    label: 'Conversions',
    value: 'conversions',
  },
  {
    label: 'Revenue',
    value: 'revenue',
  },
]

const stageTargetOptions: Array<TargetOptions> = [
  {
    label: 'Leads',
    value: 'leads',
  },
]

const costPerOptions: Array<costPerOptions> = [
  {
    label: 'Cost Per 1 Conversions',
    value: 1,
  },
  {
    label: 'Cost Per 1000 Conversions',
    value: 1000,
  },
]

interface Options {
  label: string
  value: SupportStringified
}

interface costPerOptions {
  label: string
  value: number
}

interface TargetOptions {
  label: string
  value: StageTarget
}

const IconsFormatted = Icons.map(x => ({
  label: (
    <div>
      <i className={`${x} mr-2`} />
      <span>{x.split(' ')[1]}</span>
    </div>
  ),
  value: x,
}))

export default ({
  conversion_name,
  est_conversion_name,
  name,
  icon,
  desc,
  support,
  estimate,
  show_total,
  is_kpi,
  is_sub_kpi,
  show_overview,
  cost_per_metric,
  stage_target,
}: Component) => {
  const onChange = (key, value) => {
    if (key === 'support') {
      StageActions.setValue(key, value ? value.map(x => x.value) : ['conversions'])
    } else if (key === 'cost_per_metric') {
      StageActions.setValue(key, value ? value.value : '')
    } else if (key === 'stage_target') {
      StageActions.setValue(key, value ? value.value : null)
    } else {
      StageActions.setValue(key, value)
    }
  }

  return (
    <div className="mt-5">
      <h3>General Settings</h3>
      <Row
        column={4}
        fields={[
          {
            column: 'name',
            text: 'Stage Name',
            type: 'regular',
            value: name || '',
          } as Input,
          {
            column: 'icon',
            text: 'Stage Icon',
            type: 'select',
            options: IconsFormatted,
            selector: {
              value: 'value',
            },
            value: IconsFormatted.find(x => x.value === icon) || {},
          } as Input,
          {
            column: 'desc',
            text: 'Stage Description',
            type: 'regular',
            value: desc || '',
          } as Input,
          {
            column: 'support',
            text: 'Stage Type',
            type: 'select',
            options: options,
            value: options.filter(x => support.includes(x.value)),
            selector: {
              clearable: false,
              isMulti: true,
            },
          } as Input,
          {
            column: 'conversion_name',
            text: 'Conversion Name',
            type: 'regular',
            value: conversion_name || '',
          } as Input,
          {
            column: 'est_conversion_name',
            disabled: !estimate,
            text: 'Estimated Conversion Name',
            type: 'regular',
            value: est_conversion_name || '',
          } as Input,
          {
            column: 'cost_per_metric',
            text: 'Cost Per Metric',
            type: 'select',
            selector: {
              clearable: false,
            },
            options: costPerOptions,
            value: costPerOptions.filter(x => x.value === cost_per_metric),
          } as Input,
          {
            column: 'estimate',
            text: 'Estimate Data?',
            type: 'toggle',
            value: estimate,
          } as Input,
          {
            column: 'show_total',
            text: 'Show Total',
            type: 'toggle',
            value: show_total,
          } as Input,
          {
            column: 'is_kpi',
            text: 'KPI Stage',
            type: 'toggle',
            value: is_kpi,
          } as Input,
          {
            column: 'is_sub_kpi',
            text: 'Sub KPI Stage',
            type: 'toggle',
            value: is_sub_kpi,
          } as Input,
          {
            column: 'show_overview',
            text: 'Show On Overview Graph',
            type: 'toggle',
            value: show_overview,
          } as Input,
          {
            column: 'stage_target',
            text: 'Stage Target',
            type: 'select',
            options: stageTargetOptions,
            value: stageTargetOptions.find(x => x.value === stage_target) || null,
          } as Input,
        ]}
        onChange={onChange}
      />
    </div>
  )
}
