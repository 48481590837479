import React from 'react'
import { ModalProps } from './'
import { Modal as ModalBase, ModalBody } from 'reactstrap'

export function Modal({ children, isOpen, onToggle, className = '' }: ModalProps) {
  return (
    <ModalBase isOpen={isOpen} toggle={onToggle} className={className}>
      <ModalBody children={children} />
    </ModalBase>
  )
}
