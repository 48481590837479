import React from 'react'
import Item from '../item'
import Wizard from 'components/base/wizard'

interface Props {
  stages: any[]
}

export default ({ stages }: Props) => {
  const steps = stages.map(x => ({
    text: (
      <span>
        {x.name}
        {x.is_kpi ? (
          <i className="fa fa-arrow-to-left text-brand ml-2" />
        ) : x.is_sub_kpi ? (
          <i className="fa fa-arrow-to-left text-info ml-2" />
        ) : null}
      </span>
    ),
    component: <Stage stage={x} />,
  }))

  return (
    <Item header="Customer Journey">
      <p className="ml-4">
        <i className="fa fa-arrow-to-left mr-2 text-brand" />
        Indicates KPI stage
      </p>
      <p className="ml-4">
        <i className="fa fa-arrow-to-left mr-2 text-info" />
        Indicates your Sub KPI stage
      </p>
      <Wizard steps={steps} type="vertical" portlet={false} />
    </Item>
  )
}

const Stage = ({ stage }: any) => {
  const columns = [
    {
      key: 'Name',
      value: stage.name,
    },
    {
      key: 'Description',
      value: stage.desc,
    },
    {
      key: 'Estimated Data',
      value: stage.estimate,
    },
  ]

  return (
    <div>
      <div>
        <h6>Stage Info</h6>
        <div className="row">
          {columns.map(column => (
            <div className="col-6" key={column.key}>
              <div className="form-group">
                <label>{column.key}</label>
                <input className="form-control" disabled value={column.value} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h6>Data Groups</h6>
        {stage.groups.map((group, i) => (
          <button className="btn btn-primary btn-pill mr-2" key={i}>
            {group.name}
          </button>
        ))}
      </div>
    </div>
  )
}
