import { NestAPI } from 'app/nestapi'
import { AxiosResponse } from 'axios'

export interface RingCentralServiceI {
  doAuth(
    businessID: string | number,
    clientID: string,
    clientSecret: string
  ): Promise<{ url: string }>
  getStatus: (businessID: string | number) => Promise<boolean>
}

class RingCentralServiceImpl implements RingCentralServiceI {
  async doAuth(
    businessID: string | number,
    clientID: string,
    clientSecret: string
  ): Promise<{ url: string }> {
    const res: { url: string } = await NestAPI.post(
      `api/integrations/ring_central/authorize/${businessID}`,
      { clientID, clientSecret }
    )
    return res
  }

  async getStatus(businessID: string | number): Promise<boolean> {
    const status: { enabled: boolean } = await NestAPI.get(
      `api/integrations/ring_central/status/${businessID}`
    )
    return status.enabled
  }
}

export const RingCentralService: RingCentralServiceI = new RingCentralServiceImpl()
