import React from 'react'

interface Interface {
  desc: string
  name: string
  icon: string
  index: number
  focus: () => void
  active: number | null
}

export default ({ icon, index, focus, active }: Interface) => (
  <div className="kt-notes__item h100">
    <div className="kt-notes__media cursor-pointer" onClick={focus}>
      <span className={active === index ? 'bg-brand kt-notes__icon' : 'kt-notes__icon'}>
        <i className={active === index ? `${icon} fa-2x text-white` : `${icon} fa-2x`} />
      </span>
    </div>
  </div>
)
