import React, { useContext } from 'react'
import { DeleteButton } from '@marketing-milk/frontend'
import { clientUploadService } from '@marketing-milk/frontend'
import { DeleteUploadButtonProps } from './DeleteUploadButton.types'
import { previousUploadsContext } from '../../context/PreviousUploadsContext'

export function DeleteUploadButton({ upload }: DeleteUploadButtonProps) {
  const { deleteUpload } = useContext(previousUploadsContext)

  async function onConfirmDelete() {
    await clientUploadService.deleteFile(upload.businessID, upload.id)
    deleteUpload(upload.id)
  }

  const latestVersion = upload.versions[0]

  // You can only delete uploads that haven't been published yet
  if (upload.locked || latestVersion?.uploaded) {
    return null
  }

  return <DeleteButton label="Upload" onDelete={onConfirmDelete} />
}
