import Tags from './tags'
import Uploads from './uploads'
import Accounts from './accounts'
import { BadDataReviewSubmissions } from './review-bad-data/BadDataReviewSubmissions'

export default {
  Tags,
  Uploads,
  Accounts,
  ReviewBadData: BadDataReviewSubmissions,
}
