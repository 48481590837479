import { Paper } from '@material-ui/core'
import React from 'react'

const NetworkError = () => {
  return (
    <div className="mm-login">
      <img alt="logo" src="/assets/admin/img/logo.png" />
      <Paper className="text-center m-4 pb-5 pt-3">
        <p className="font-weight-bold">
          An error occurred while trying to connect to Marketing Milk
        </p>
        <p style={{ color: 'darkred' }}>
          Please try again in a few minutes, and if the issue persists, contact
          <a href="mailto:support@marketingmilk.com"> support@marketingmilk.com</a>
        </p>
      </Paper>
    </div>
  )
}

export default NetworkError
