import { Franchise } from '@marketing-milk/interfaces'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'

interface FranchiseRemoveBusinessesProps {
  disabled: boolean
  franchise: Franchise
  onConfirm: () => void
}

export const FranchiseRemoveBusinesses = (props: FranchiseRemoveBusinessesProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleRemove = () => {
    props.onConfirm()
    setOpen(false)
  }

  return (
    <>
      <Button
        disabled={props.disabled}
        onClick={() => setOpen(true)}
        variant="contained"
        color="secondary"
        style={{
          marginLeft: '1em',
        }}
      >
        Remove Selected
      </Button>

      {open && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent style={{ width: '400px' }}>
            Are you sure you want to remove the selected businesses from{' '}
            <span className="font-weight-bold text-danger">{props.franchise.name}</span>? The
            businesses being removed will <strong>not</strong> be deleted.
          </DialogContent>
          <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
            <Button onClick={() => setOpen(false)} variant="contained">
              Cancel
            </Button>
            <Button type="submit" onClick={handleRemove} color="primary" variant="contained">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
