import React from 'react'
import Select from 'react-select'

interface Props {
  onChange: (data: any) => void
  value: any
}

export default ({ onChange, value }: Props) => {
  const options = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ]

  return (
    <Select options={options} value={options.find(x => x.value === value)} onChange={onChange} />
  )
}
