import './SubmitVersionModal.scss'
import { toast } from 'react-toastify'
import { ModalFooter } from 'reactstrap'
import React, { PureComponent } from 'react'
import { Modal } from 'components/generic/modal'
import { UserUpload } from '@marketing-milk/interfaces'
import { clientUploadService } from '@marketing-milk/frontend'
import { VerifyUploadModal } from '../../../verify-upload-modal/VerifyUploadModal'
import {
  defaultSubmitVersionModalState,
  SubmitVersionModalProps,
  SubmitVersionModalState,
} from './SubmitVersionModal.types'

export class SubmitVersionModal extends PureComponent<
  SubmitVersionModalProps,
  SubmitVersionModalState
> {
  state: SubmitVersionModalState = defaultSubmitVersionModalState

  componentDidMount(): void {
    this.getLastEventName()
  }

  createSubmission = async (): Promise<void> => {
    const { businessID, upload, version } = this.props
    try {
      this.setState({ showSpinner: true })
      await clientUploadService.submitFile(businessID, upload.id, version.id!)
      this.onNewVersion()
    } catch (e) {
      toast.error(
        'Your upload could not be submitted at this time. Please make sure you have chosen an Event Name.'
      )
      this.setState({ showSpinner: false })
    }
  }

  onNewVersion = (): void => {
    this.setState(defaultSubmitVersionModalState)
    this.props.onCreation()
  }

  onToggle = (): void => {
    this.setState(defaultSubmitVersionModalState)
  }

  getLastEventName = async (): Promise<void> => {
    const { businessID, upload } = this.props
    try {
      const uploads: UserUpload[] = await clientUploadService.getFiles(businessID)
      const filteredUploads: UserUpload[] = uploads.filter(
        x => x.dataSource.id === upload.dataSource.id && x.eventName !== null
      )

      filteredUploads.length > 0 &&
        this.setState({
          lastUpload: filteredUploads[0],
        })
    } catch {
      toast.error('We experienced issues checking for your previous event name')
    }
  }

  onShowModal = (): void => {
    this.setState({
      showModal: true,
    })
  }

  render() {
    const { upload, version } = this.props
    const { showModal, showSpinner } = this.state

    const buttonIcon: string = showSpinner ? 'fa fa-spinner fa-spin mr-2' : 'fa fa-file-upload mr-2'

    const buttonText: string = showSpinner ? 'Creating...' : 'Submit Version'

    return (
      <>
        {version!.validationStatus === 'valid' ? (
          <button className="btn btn-info spec-cancel-btn" onClick={this.onShowModal}>
            Submit For Approval
          </button>
        ) : (
          <VerifyUploadModal upload={upload} version={version}>
            <button className="btn btn-danger spec-cancel-btn">
              <i className="fas fa-exclamation-triangle mr-2" /> File Invalid
            </button>
          </VerifyUploadModal>
        )}
        <Modal isOpen={showModal} onToggle={this.onToggle}>
          <div className="text-muted lead submission-warning">
            <i className="fas fa-exclamation-circle fa-3x text-info mt-2" />
            <p className="my-4 text-dark">
              Are you sure you want to submit{' '}
              <span className="text-info">version {version.version}</span> of{' '}
              <span className="text-info">{upload.displayName}</span> to be reviewed? If this file
              is approved by the reviewer, you will NOT be able to make any changes prior to
              uploading.
            </p>
            <p className="text-dark">
              By clicking "Submit Version" you agree to the Marketing Milk{' '}
              <a
                href="https://www.marketingmilk.com/privacy-policy/"
                target="_blank"
                className="text-brand"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>{' '}
              and authorize the platform to handle your data.
            </p>
          </div>
          <ModalFooter className="pr-0 pb-0">
            <button
              className="btn btn-primary mr-0"
              disabled={showSpinner}
              onClick={this.createSubmission}
            >
              <i className={buttonIcon} />
              {buttonText}
            </button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}
