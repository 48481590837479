import Pages from 'pages'
import { IParent } from 'app/interface/config/router'
import { EGuard } from '@marketing-milk/frontend'

const routes: Array<IParent> = [
  // Everyone (Core Routes)
  {
    guard: EGuard.Everyone,
    prefix: '',
    meta: {},
    routes: [
      {
        path: 'login',
        title: 'Login',
        component: Pages.Session.Login,
      },
      {
        path: 'invalid_user',
        title: 'Invalid User',
        component: Pages.Session.InvalidUser,
      },
      {
        path: 'network_error',
        title: 'Network Error',
        component: Pages.Session.NetworkError,
      },
      {
        path: '500',
        title: 'Server Error',
        component: Pages.Error.Server,
      },
    ],
  },

  // Authenticated Routes
  {
    component: Pages.Master,
    guard: EGuard.AccessDashboardApp,
    prefix: '',
    meta: {},
    routes: [
      {
        path: 'dashboard',
        title: 'Dashboard',
        component: Pages.Session.Dashboard,
      },
      {
        path: 'logout',
        title: 'Logout',
        component: Pages.Session.Logout,
      },
    ],
  },

  // Business Accounts - Beta
  {
    component: Pages.Master,
    guard: EGuard.AccessAdminApp,
    prefix: 'business/beta/accounts',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'View Businesses',
        component: Pages.Business.Accounts.List,
      },
      {
        path: 'create',
        title: 'Create Business',
        component: Pages.Business.Accounts.Create,
      },
      {
        path: 'edit/:business/:view?',
        title: 'Edit Business',
        component: Pages.Business.Accounts.Edit,
        meta: {
          actions: [
            {
              text: 'Dashboard',
              path: '/:id/dashboard',
              icon: 'tachometer',
            },
          ],
        },
      },
    ],
  },

  // Business - Bad Data
  {
    component: Pages.Master,
    guard: EGuard.ReviewBadDataRequest,
    prefix: 'business/bad-data',
    meta: {},
    routes: [
      {
        path: 'submissions',
        title: 'Bad Data - Submissions',
        component: Pages.Business.ReviewBadData,
      },
    ],
  },

  // Business - Uploads - Admin
  {
    component: Pages.Master,
    guard: EGuard.ReviewOfflineEventUpload, //#TODO: upgrade to desired role (director?)
    prefix: 'business/uploads',
    meta: {},
    routes: [
      {
        path: 'submissions',
        title: 'Upload Submissions - Marketing Milk - Drive Social Media',
        component: Pages.Business.Uploads.Submissions.SubmissionList,
      },
    ],
  },

  // Business - Tags
  {
    component: Pages.Master,
    guard: EGuard.AccessAdminApp,
    prefix: 'business/tags',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'All Business Tags',
        component: Pages.Business.Tags.List,
      },
      {
        path: 'create',
        title: 'Create Business Tag',
        component: Pages.Business.Tags.Create,
      },
      {
        path: ':id/edit',
        title: 'Edit Business Tag',
        component: Pages.Business.Tags.Edit,
      },
    ],
  },

  // Branches
  {
    component: Pages.Master,
    guard: EGuard.AccessAdminApp,
    prefix: 'branches',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'Branches List',
        component: Pages.BranchList,
      },
      // {
      //   path: ':id/edit',
      //   title: 'Edit Branch',
      //   component: Pages.BranchManage,
      // },
    ],
  },

  // Franchises
  {
    component: Pages.Master,
    guard: EGuard.AccessAdminApp,
    prefix: 'franchises',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'Franchise List',
        component: Pages.FranchiseList,
      },
      {
        path: ':id/edit',
        title: 'Edit Franchise',
        component: Pages.FranchiseEdit,
      },
    ],
  },

  // Ownership Groups
  {
    component: Pages.Master,
    guard: EGuard.AccessAdminApp,
    prefix: 'ownership-groups',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'Ownership Groups List',
        component: Pages.OwnershipGroupList,
      },
      {
        path: ':id/edit',
        title: 'Edit Ownership Group',
        component: Pages.OwnershipGroupEdit,
      },
    ],
  },

  // Employee - Pods
  {
    component: Pages.Master,
    guard: EGuard.AccessAdminApp,
    prefix: 'employee/pods',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'All Pods',
        component: Pages.Employee.Pods.PodsList,
      },
    ],
  },

  // --------------------------
  // New Roles Routes
  // --------------------------
  {
    component: Pages.Master,
    guard: EGuard.ManageRoles,
    prefix: 'roles',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'All Roles',
        component: Pages.UserManagement.Roles,
      },
      {
        path: ':id/details',
        title: 'Role Details',
        component: Pages.UserManagement.RoleDetails,
      },
    ],
  },
  {
    component: Pages.Master,
    guard: EGuard.ManageRoles,
    prefix: 'permissions',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'All Permissions',
        component: Pages.UserManagement.Permissions,
      },
    ],
  },
  {
    component: Pages.Master,
    guard: EGuard.AccessAdminApp,
    prefix: 'users',
    meta: {},
    routes: [
      {
        path: 'list',
        title: 'All Users',
        component: Pages.UserManagement.Users,
      },
      {
        path: ':id/details',
        title: 'User Details',
        component: Pages.UserManagement.UserDetails,
      },
    ],
  },
  // --------------------------
  // End New Roles Routes
  // --------------------------
]

export default routes
