import Moment from 'moment'
import React, { useContext, useEffect } from 'react'
import { CreateBadDataSubmission, UserUpload } from '@marketing-milk/interfaces'
import { Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { badDataSubmissionContext } from '../../submission-context/BadDataSubmissionContext'

export function isWithinDateRange(
  submission: CreateBadDataSubmission,
  upload: UserUpload
): boolean {
  const [uploadStart, uploadEnd] = [Moment(upload.startDate), Moment(upload.endDate)]
  const [submissionStart, submissionEnd] = [
    Moment(submission.startDate),
    Moment(submission.endDate),
  ]
  return uploadStart.isSameOrAfter(submissionStart) && uploadEnd.isSameOrBefore(submissionEnd)
}

export function hasMatchingEvent(submission: CreateBadDataSubmission, upload: UserUpload): boolean {
  return upload.eventName === submission.eventName
}

export function isMatchingUpload(submission: CreateBadDataSubmission, upload: UserUpload) {
  return isWithinDateRange(submission, upload) && hasMatchingEvent(submission, upload)
}

export function BadUploadList() {
  const { submission, setSubmission, uploads } = useContext(badDataSubmissionContext)

  useEffect(() => {
    const matchingUploads = uploads.filter(_ => isMatchingUpload(submission, _))
    setSubmission!({
      uploadIDs: matchingUploads.map(_ => _.id),
    })
  }, [submission.startDate, submission.endDate, submission.eventName])

  const renderedUploads = submission.uploadIDs.map(
    uploadID => uploads.find(_ => _.id === uploadID)!
  )

  function getSecondary(upload: UserUpload) {
    const name = upload.versions[0]?.user?.firstName
      ? `${upload.versions[0].user.firstName} ${upload.versions[0].user.lastName}`
      : 'N/A'
    return (
      <>
        Uploaded by <b>{name}</b>
      </>
    )
  }

  return (
    <>
      <h4>The following uploads will also be deleted:</h4>
      <div style={{ maxHeight: 400, overflowY: 'auto' }}>
        <List component="nav">
          {renderedUploads.map(upload => (
            <ListItem button divider key={`upload_${upload.id}`}>
              <ListItemText secondary={getSecondary(upload)}>
                <Typography variant="subtitle1">{upload.displayName}</Typography>
              </ListItemText>
            </ListItem>
          ))}
          <Divider />
        </List>
      </div>
    </>
  )
}
