import React from 'react'
import { IWizardComponent } from '../'

export default ({ active, setActive, steps }: IWizardComponent) => {
  const current = steps[active]
  return (
    <div
      className="m-wizard m-wizard--5 m-wizard--primary m-wizard--step-first"
      id="m_wizard"
      style={{ overflow: 'visible', minHeight: '400px', position: 'relative' }}
    >
      <div className="m-wizard__head" style={{ marginTop: '-2.5%' }}>
        <div className="m-wizard__nav">
          <div className="m-wizard__steps">
            {steps.map((step, i: number) => (
              <div
                className={`m-wizard__step mouse-pointer ${
                  active === i ? 'm-wizard__step--current' : ''
                }`}
                key={i}
                onClick={() => setActive(i)}
              >
                <div className="m-wizard__step-info">
                  <span className="m-wizard__step-number">
                    <span className="m-wizard__step-label">{step.text}</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="m-wizard__form-step m-wizard__form-step--current">
        {current ? current.component : null}
      </div>
    </div>
  )
}
