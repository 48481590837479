import Tabular from './tabular'
import Vertical from './vertical'
import Horizontal from './horizontal'
import HorizontalStretch from './horizontal-stretch'

export class Types {
  tabular = Tabular
  vertical = Vertical
  horizontal = Horizontal
  horizontal_stretch = HorizontalStretch
}

export default new Types()
