import React, { useState } from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { Auth0Role } from '@marketing-milk/interfaces'
import * as yup from 'yup'
import { useFormik } from 'formik'

const validationSchema = yup.object({
  name: yup.string().max(50).required('Role name is Required'),
  description: yup.string().max(50).required('Role description is Required'),
})

export interface CreateRoleModalProps {
  onSubmit?: (roleDTO: Auth0Role) => void
}

export function CreateRoleModal(props: CreateRoleModalProps) {
  const [open, setOpen] = useState(false)

  const initialValues: Auth0Role = {
    name: '',
    description: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      props.onSubmit && props.onSubmit(values)
      handleClose()
    },
  })
  const canSubmit = !formik.isSubmitting && formik.isValid

  const handleClose = () => {
    setOpen(false)
    formik.resetForm()
  }

  return (
    <>
      <Button
        data-testid="create-role-test-id"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Create Role
      </Button>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Create Role</DialogTitle>
        <form className="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Create your role by giving it a name and description.
            </DialogContentText>
            <TextField
              autoFocus
              className="mb-3"
              label="Name"
              fullWidth
              variant="standard"
              placeholder="e.g. Administrator"
              InputLabelProps={{ shrink: true }}
              // formik props
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              label="Description"
              fullWidth
              variant="standard"
              placeholder="e.g. A user with all permissions."
              InputLabelProps={{ shrink: true }}
              className="pb-3"
              // formik props
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={!canSubmit} type="submit">
              Create Role
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
