import React from 'react'
import Card from './components/card'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import FunnelActions from 'app/redux/actions/pages/business/funnel'

const Presets = ({
  pages: {
    business: { funnel },
  },
  data: {
    funnel: { presets },
  },
}: Redux) => (
  <div>
    <h4 style={{ color: '#575962', fontSize: '1.3rem' }}>Templates</h4>
    <div className="row mt-4">
      {presets.data.map(template => (
        <div className="col-md-3" key={template.id}>
          <Card
            active={funnel.preset === template.id}
            key={template.id}
            modify={() => FunnelActions.setPreset(template.id)}
            {...template}
          />
        </div>
      ))}
    </div>
  </div>
)

export const PresetsStandalone = Presets
export default Connect(Presets)
