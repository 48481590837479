import Select from 'react-select'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import { Connected } from 'app/interface/redux'

class Selector extends PureComponent<Connected> {
  onChange = async business => {
    this.props.history.push(`/admin/business/beta/accounts/edit/${business.id}`)
  }

  render() {
    // @ts-ignore
    const id = parseInt(this.props.match.params.business)
    const { data } = this.props.data.generic.businesses
    const value = data.find(x => x.id === id) || null

    return (
      <div style={{ width: 500, marginTop: '2.5%' }}>
        <Select
          options={data}
          value={value}
          onChange={this.onChange}
          getOptionLabel={x => x.businessName}
          getOptionValue={x => `${x.id}`}
        />
      </div>
    )
  }
}

export default Connect(Selector)
