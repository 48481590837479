import React, { useState } from 'react'
import { TableProps, TableState, defaultState } from './'
import { TableBody } from './table-body'
import { TableHeader } from './table-header'
import './Table.scss'

export function Table({ columns, rows, pageSize, count, page, changePage }: TableProps<any>) {
  const [state, setState] = useState<TableState>(defaultState)
  const { sortDirection, sortedHeader } = state

  // This function is not good. It...
  //   - mutates props (i.e. rows)
  //   - derives new state from old state without using the setState updater callback
  function sortColumn(sortBy: any, header: string) {
    rows?.sort(function (a, b) {
      const textA = sortBy(a)?.toString().toUpperCase() ?? ''
      const textB = sortBy(b)?.toString().toUpperCase() ?? ''
      if (sortDirection === 'down') return textA < textB ? -1 : textA > textB ? 1 : 0
      return textA < textB ? 1 : textA > textB ? -1 : 0
    })
    const direction = sortDirection === 'up' ? 'down' : 'up'
    setState({ ...state, sortDirection: direction, sortedHeader: header })
  }

  return (
    <>
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">
          <TableHeader
            columns={columns}
            sortedHeader={sortedHeader}
            sortDirection={sortDirection}
            sortRows={(sortBy: string, header: string) => sortColumn(sortBy, header)}
          />
          <TableBody columns={columns} rows={rows} pageSize={pageSize} page={page} />
        </table>
      </div>
      {
        <div className="row pagination">
          <div className="col-4">
            <button
              className="btn btn-info float-left page-btn page-prev"
              disabled={page === 1}
              onClick={() => changePage(page - 1)}
            >
              Prev
            </button>
          </div>
          <div className="col-4 text-center page">
            Page: {page} / {Math.ceil(count / pageSize)}
          </div>
          <div className="col-4">
            <button
              className="btn btn-info float-right page-btn page-next"
              disabled={page === Math.ceil(count / pageSize)}
              onClick={() => changePage(page + 1)}
            >
              Next
            </button>
          </div>
        </div>
      }
    </>
  )
}
