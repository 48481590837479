import './TableRow.scss'
import React from 'react'
import { TableRowProps } from './'
import { TableData } from '../table-data'

export function TableRow({ columns, row }: TableRowProps<any>) {
  return (
    <tr className="kt-datatable__row">
      {columns.map((column, columnIndex) => {
        const { value, width = 100 / columns.length } = column
        return (
          <TableData key={columnIndex} width={width + '%'}>
            {value ? value(row) : ''}
          </TableData>
        )
      })}
    </tr>
  )
}
