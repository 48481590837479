import React from 'react'
import { ToggleSidebar, SidebarSectionProps } from '@marketing-milk/frontend'

export function SubmissionsListHelp() {
  const sections: SidebarSectionProps[] = [
    {
      title: 'Data',
      text: 'This page shows you both the open and closed submissions for all businesses.',
    },
    {
      title: 'Actions',
      text: (
        <ul>
          <li>
            The <i className="fas fa-check text-success" /> icon approves the file to be processed
            and uploaded to facebook.
          </li>
          <li>
            The <i className="fas fa-times text-danger" /> icon rejects the file and prompts you to
            enter notes summarizing why the data is no good.
          </li>
          <li>
            The <i className="fas fa-cloud-download-alt text-info" /> icon downloads the file to
            your computer so you can take a closer look at what's going on.
          </li>
        </ul>
      ),
    },
  ]

  return (
    <ToggleSidebar
      toggleContent={
        <span className="btn btn-brand">
          Help <i className="fal fa-question-circle" />
        </span>
      }
      title="Submissions List"
      sections={sections}
    />
  )
}
