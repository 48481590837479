import React from 'react'
import Guard from 'components/utility/guard'
import { Label } from 'app/interface/config/sidebar'

export default ({ guard, text }: Label) => (
  <Guard guard={guard}>
    {({ status }) => {
      return !status ? null : (
        <li className="kt-menu__section">
          <h4 className="kt-menu__section-text">{text}</h4>
        </li>
      )
    }}
  </Guard>
)
