import Store from 'app/redux/store'
import _ from 'lodash'
import { bindActions } from 'redux-zero/utils'

export const AttrWindowActions = bindActions(
  () => ({
    update: (state, key: string, value: any) => {
      const attrReportType = key.split('.')[0]
      const attrType = key.split('.')[1]
      const newState = _.cloneDeep(state)
      newState.pages.business.main.attr_windows[attrReportType][attrType] = value
      return newState
    },
  }),
  Store
)
