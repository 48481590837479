import Moment from 'moment'
import React, { PureComponent } from 'react'
import Interface from '../../interface/input'
import { SingleDatePicker } from 'react-dates'

interface State {
  focused: boolean
}

export default class extends PureComponent<Interface, State> {
  midnight = Moment('0', 'H')

  state = {
    focused: false,
  }

  onFocusChange = (): void => {
    this.setState({
      focused: !this.state.focused,
    })
  }

  onChange = date => {
    if (date) {
      this.props.onChange(this.props.column, date.format('YYYY-MM-DD'))
    }
  }

  render() {
    const { value } = this.props
    const { focused } = this.state

    const date = value ? Moment(value).local() : null

    return (
      <SingleDatePicker
        date={date}
        onDateChange={this.onChange}
        focused={focused}
        isOutsideRange={date => date.isSameOrAfter(this.midnight)}
        onFocusChange={this.onFocusChange}
      />
    )
  }
}
