import React from 'react'

interface Props {
  children: any
  header: string
}

export default ({ children, header }: Props) => (
  <div className="mt-5">
    <h4 className="ml-4" style={{ textTransform: 'uppercase' }}>
      {header}
    </h4>
    <div>{children}</div>
  </div>
)
