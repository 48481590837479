import React from 'react'
import { Chip } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { GridCellParams } from '@material-ui/x-grid'
import { BadDataSubmission } from '@marketing-milk/interfaces'
import {
  badDataService,
  Loading,
  MaterialDataTable,
  useCreateFetchHook,
} from '@marketing-milk/frontend'

export function BadDataPreviousSubmissionsTable() {
  const history = useHistory()
  const params: { business: string } = useParams()
  const badDataSubmissions: BadDataSubmission[] | undefined = useCreateFetchHook(
    () =>
      params.business
        ? badDataService.getSubmissionsForBusiness(Number(params.business))
        : Promise.resolve([]),
    Number(params.business)
  )

  if (badDataSubmissions === undefined) {
    return <Loading />
  }

  return (
    <MaterialDataTable
      sortingOrder={['asc', 'desc']}
      rows={badDataSubmissions ?? []}
      rowHeight={38}
      headerHeight={50}
      density={'comfortable'}
      columnBuffer={10}
      columns={[
        {
          field: 'name',
          headerName: 'Report Name',
          description: 'The name of the bad data report',
          type: 'string',
          flex: 800,
        },
        {
          field: 'startDate',
          headerName: 'Start Date',
          description: 'What day the bad data started',
          type: 'string',
          width: 150,
        },
        {
          field: 'endDate',
          headerName: 'End Date',
          description: 'What day the bad data ended',
          type: 'string',
          width: 150,
        },
        {
          field: 'eventName',
          headerName: 'Offline Event',
          description: 'What incoming offline event is having issues',
          type: 'string',
          width: 150,
        },
        {
          field: 'uploads',
          headerName: 'Affected Uploads',
          description: 'Uploads affected by the bad data',
          type: 'string',
          width: 300,
          renderCell: ({ row }: GridCellParams) => (
            <div className="d-flex overflowY-scroll">
              {row.uploads.map(_ => (
                <Chip
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push({ search: `?tab=uploads&uploadID=${_.id}` })}
                  key={`upload_${_.id}`}
                  label={_.displayName}
                  variant="outlined"
                  color="primary"
                />
              ))}
            </div>
          ),
        },
        {
          field: 'submittedBy',
          headerName: 'Reported By',
          description: 'The user who submitted the bad data report',
          type: 'string',
          width: 150,
          renderCell: ({ row }: GridCellParams) => (
            <>
              {row.submittedBy.firstName} {row.submittedBy.lastName}
            </>
          ),
        },
        {
          // this is incorrectly named approvedBy ....
          field: 'approvedBy',
          headerName: 'Reviewed By',
          description: 'The user who reviewed the bad data report',
          type: 'string',
          width: 150,
          renderCell: ({ row }: GridCellParams) => (
            <>{row.approvedBy ? `${row.approvedBy.firstName} ${row.approvedBy.lastName}` : 'N/A'}</>
          ),
        },
        {
          field: 'approvedAt',
          headerName: 'Review Status',
          description: 'The status of the bad data submission',
          type: 'string',
          width: 150,
          renderCell: ({ row }: GridCellParams) => (
            <>{!row.approvedBy ? 'Pending' : row.approved ? 'Approved' : 'Rejected'}</>
          ),
        },
      ]}
      checkboxSelection={false}
      pagination
      pageSize={10}
    />
  )
}
