import Info from './info'
import Review from './review'
import Start from './funnel/start'
import Calculations from './calculations'
import Configuration from './configuration'
import Wizard from 'components/base/wizard'
import React, { PureComponent } from 'react'
import Connect from 'app/redux/connect'
import { Connected } from 'app/interface/redux'

interface Props extends Connected {
  submit: () => void
  new: boolean
}

class Edit extends PureComponent<Props> {
  render() {
    return (
      <Wizard
        steps={[
          {
            text: 'Settings',
            component: <Info new={this.props.new} />,
          },
          {
            text: 'Calculations',
            component: <Calculations />,
          },
          {
            text: 'Funnel Stages',
            component: <Start />,
          },
          {
            text: 'Configuration',
            component: <Configuration />,
          },
          {
            text: 'Review',
            component: <Review onSubmit={this.props.submit} />,
          },
        ]}
        type="horizontal"
      />
    )
  }
}

export default Connect(Edit)
