import { ReactNode } from 'react'
import { PropTypes } from '@material-ui/core'
import { Branch, Business, PodConfigDTO, User } from '@marketing-milk/interfaces'

export interface PodWizardProps {
  users: User[]
  branches: Branch[]
  businesses: Business[]
  pod: PodConfigDTO
  children: ReactNode
  header: ReactNode
  headerStyle?: object
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: PropTypes.Color
  onSave(refresh: PodConfigDTO): Promise<void>
}

export const emptyPodConfig: PodConfigDTO = {
  podName: undefined,
  podNumber: undefined,
  branchID: undefined,
  googleConfig: undefined,
  podMembers: [],
  affiliateMembers: [],
  podBusinesses: [],
}
