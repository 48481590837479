import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { leadService } from '@marketing-milk/frontend'
import { Button, Typography } from '@material-ui/core'
import { ReloadLeadsButtonProps } from './ReloadLeadsButton.types'

export function ReloadLeadsButton({ businessID }: ReloadLeadsButtonProps) {
  const [isLoading, setLoading] = useState(false)

  const [buttonIcon, buttonText] = isLoading
    ? ['spinner fa-spin', 'Reloading Leads...']
    : ['sync', 'Reload Leads']

  async function onReload() {
    try {
      setLoading(true)
      await leadService.backload(businessID)
      toast.info('Leads are being refreshed. They may not appear immediately.')
      setLoading(false)
    } catch (e) {
      toast.error('Leads could not be refreshed at this time due to an internal server error.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Typography variant="h5">Missing Leads?</Typography>
      <Typography variant="body1">
        If your business is missing leads, you can perform a lead sync which will automatically
        backload all leads from the past 90 days and setup a webhook for new leads.
      </Typography>
      <Button color="primary" variant="contained" onClick={onReload}>
        <i className={`fa fa-${buttonIcon} mr-2`} />
        {buttonText}
      </Button>
    </>
  )
}
