import Card from './card'
import React from 'react'
import Accordion from 'components/base/accordion'
import { BusinessManagerGroup as Group } from '@marketing-milk/interfaces'

interface Props {
  groups: Group[]
}

export default ({ groups }: Props) => {
  const cards = groups.map((group: Group, index: number) => ({
    icon: 'database',
    text: group.name,
    component: <Card group={group} index={index} />,
  }))
  return <Accordion cards={cards} />
}
