import React from 'react'
import { DataSource, UserUpload } from '@marketing-milk/interfaces'

export interface VersionTypeProps {
  dataSource: DataSource
}

export interface DataSourceProps {
  upload?: UserUpload
}

export interface DataSourceOption {
  icon: string
  label: string
  value: string
}

export function VersionType({ dataSource }: VersionTypeProps) {
  const dataType: DataSourceOption = {
    icon: dataSource.icon,
    label: dataSource.name,
    value: `${dataSource.id}`,
  }

  return (
    <>
      <i className={`${dataType.icon} mr-2`} />
      <span>{dataType.label}</span>
    </>
  )
}
