export const customDropdownStyles = (error, disableMultiSelectExpansion = true) => {
  return {
    control: (base, state) => ({
      ...base,
      minHeight: 35,
      borderColor: error ? '#fd397a' : 'lightgrey',
      boxShadow: 0,
      '&:hover': {
        borderWidth: state.isFocused ? '2px' : '1px',
        borderColor: state.isFocused ? (error ? '#fd397a' : '#a044ff') : 'inherit',
      },
      ...(disableMultiSelectExpansion
        ? {
            height: 52.44,
          }
        : {}),
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: 'inherit',
      ...(disableMultiSelectExpansion
        ? {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flexWrap: 'nowrap',
          }
        : {}),
    }),
    input: (provided, state) => ({
      ...provided,
      minWidth: '5%',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: 'inherit',
    }),
    singleValue: base => ({
      ...base,
      color: 'inherit',
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
    }),
  }
}
