import { franchiseService } from '@marketing-milk/frontend'
import {
  Franchise,
  PaginatedFranchiseResponse,
  PaginationQueryParams,
} from '@marketing-milk/interfaces'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export function useFetchAllFranchises() {
  const pageSize = 15
  const [franchises, setFranchises] = useState<Franchise[]>()
  const [paginationParams, setPaginationParams] = useState<PaginationQueryParams>({
    searchTerm: '',
    searchProp: 'name',
    sortProp: 'createdAt',
    sortDirection: 'DESC',
    page: 1,
    take: pageSize,
  })

  const query = useQuery<PaginatedFranchiseResponse, string>(
    ['franchises-paginated', paginationParams],
    () => franchiseService.getAll(paginationParams),
    {
      keepPreviousData: true,
      staleTime: 5000,
      retry: false,
    }
  )
  const { data } = query

  useEffect(() => {
    if (!data) return
    setFranchises(data.franchises)
  }, [data])

  return {
    franchises,
    count: data?.count || 0,
    current: data?.franchises.length,
    pageSize,
    ...paginationParams,
    setPaginationParams,
    ...query,
  }
}
