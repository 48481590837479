import Moment from 'moment'
import { DateRangePicker } from 'react-dates'
import React, { useContext, useState } from 'react'
import { SelectDateRangeProps } from './SelectDateRange.types'
import { badDataSubmissionContext } from '../../../submission-context/BadDataSubmissionContext'

export function SelectDateRange({ defaultFocus = 'startDate' }: SelectDateRangeProps) {
  const [focus, setFocus] = useState(defaultFocus)
  const { submission, setSubmission } = useContext(badDataSubmissionContext)

  function changeRange({
    startDate,
    endDate,
  }: {
    startDate: Moment.Moment
    endDate: Moment.Moment
  }) {
    setSubmission!({
      startDate: startDate ? startDate.format('YYYY-MM-DD') : submission.startDate,
      endDate: endDate ? endDate.format('YYYY-MM-DD') : submission.endDate,
    })
  }

  return (
    <DateRangePicker
      startDate={submission.startDate ? Moment(submission.startDate) : null}
      startDateId="start_date"
      endDate={submission.endDate ? Moment(submission.endDate) : null}
      endDateId="end_date"
      onDatesChange={changeRange}
      onFocusChange={setFocus}
      focusedInput={focus}
      isOutsideRange={() => false}
    />
  )
}
