import Form from 'components/base/form'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import Basic from 'app/redux/actions/pages/business/basic'
import Input from 'components/base/form/interface/input'
class Calculator extends PureComponent<Redux> {
  onChangeClosePercent = (column, value): void => {
    Basic.update('close_percentage', Number(value))
  }

  componentDidUpdate(old: Redux): void {
    const calculate = ['yvc_spend', 'yvc_party_size', 'yvc_visits']

    calculate.forEach(column => {
      if (old.pages.business.main[column] !== this.props.pages.business.main[column]) {
        this.calculateYVOC()
      }
    })
  }

  calculateYVOC = () => {
    const { yvc_spend, yvc_party_size, yvc_visits } = this.props.pages.business.main
    const yvoc = (yvc_spend * yvc_party_size * yvc_visits).toFixed(2)
    Basic.update('yearly_value', yvoc)
  }

  render() {
    const { main } = this.props.pages.business
    return (
      <div className="row">
        <div className="col-6">
          <Form
            save={false}
            onChange={Basic.update}
            onSubmit={() => null}
            groups={[
              {
                header: 'Yearly Value of a Customer',
                rows: [
                  {
                    column: 12,
                    fields: [
                      {
                        column: 'override_yvc',
                        text: 'Estimate YVC',
                        type: 'toggle',
                        value: main.override_yvc,
                      } as Input,
                      {
                        column: 'yvc_spend',
                        disabled: !main.override_yvc,
                        text: 'Spend',
                        type: 'regular',
                        subType: 'number',
                        value: main.yvc_spend || '',
                        other: {
                          regular: false,
                          prepend: <i className="fa fa-usd" />,
                        },
                      } as Input,
                      {
                        column: 'yvc_party_size',
                        disabled: !main.override_yvc,
                        text: 'Party Size',
                        type: 'regular',
                        subType: 'number',
                        value: main.yvc_party_size || '',
                        other: {
                          regular: false,
                          prepend: <i className="fa fa-times" />,
                        },
                      } as Input,
                      {
                        column: 'yvc_visits',
                        disabled: !main.override_yvc,
                        text: 'Number Of Visits',
                        type: 'regular',
                        subType: 'number',
                        value: main.yvc_visits || '',
                        other: {
                          regular: false,
                          prepend: <i className="fa fa-times" />,
                        },
                      } as Input,
                      {
                        column: 'yearly_value',
                        disabled: !main.override_yvc,
                        readOnly: true,
                        text: 'Yearly Value Of Customer',
                        type: 'regular',
                        value: main.yearly_value || '',
                        other: {
                          regular: false,
                          prepend: <i className="fa fa-equals" />,
                        },
                      } as Input,
                    ],
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="col-6">
          <Form
            save={false}
            onChange={this.onChangeClosePercent}
            onSubmit={() => null}
            groups={[
              {
                header: 'Close Percentage',
                rows: [
                  {
                    column: 12,
                    fields: [
                      {
                        column: 'close_percentage',
                        text: 'Close Percentage',
                        type: 'regular',
                        subType: 'number',
                        value: main.close_percentage,
                        other: {
                          regular: false,
                          prepend: '%',
                        },
                      } as Input,
                    ],
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    )
  }
}

export default Connect(Calculator, state => ({
  core: {
    errors: state.errors,
  },
  pages: {
    business: state.pages.business,
  },
}))
