import { getEnvOrFail } from '@marketing-milk/frontend'
import { Environment } from '@marketing-milk/interfaces'

const MM_ENV = getEnvOrFail('REACT_APP_MM_ENV')

export const AUTH0_DOMAIN =
  MM_ENV === Environment.Development
    ? 'marketing-milk-dev.us.auth0.com'
    : MM_ENV === Environment.Staging
    ? 'marketing-milk-staging.us.auth0.com'
    : MM_ENV === Environment.Beta
    ? 'marketing-milk-beta.us.auth0.com'
    : MM_ENV === Environment.Production
    ? 'marketing-milk.us.auth0.com'
    : 'Error'

export const AUTH0_CLIENT_ID =
  MM_ENV === Environment.Development
    ? '9jTd0xvpqqldqAywGLHk0oArqwpoJmdR'
    : MM_ENV === Environment.Staging
    ? 'xfvVqQ2kETxXO0ZGYZFppXflklI5xvOe'
    : MM_ENV === Environment.Beta
    ? 'wG6Mt5FjZTXPkziz8KweHAAOhxyIbmMl'
    : MM_ENV === Environment.Production
    ? '8SSi3wp1yVaTXnSRYVXwIXDkoNG8xpr5'
    : 'Error'

export const AUTH0_AUDIENCE =
  MM_ENV === Environment.Development
    ? 'https://marketing-milk-dev.com'
    : MM_ENV === Environment.Staging
    ? 'https://marketing-milk-staging.com'
    : MM_ENV === Environment.Beta
    ? 'https://marketing-milk-beta.com'
    : MM_ENV === Environment.Production
    ? 'https://marketing-milk-prod.com'
    : 'Error'

// if specified, forces reauthentication via auth0 and will force reauth in future when sessions expires, if time elapsed since last authentication > max age (seconds)
// https://auth0.com/docs/login/max-age-reauthentication#max_age-authentication-request-parameter
export const AUTH0_MAX_AGE = process.env['REACT_APP_AUTH0_MAX_AGE']
