import { UserService } from './User.types'
import { UserServiceMock } from './User.mock'
import { UserServiceImplementation } from './User'
import { serviceProvider } from '@marketing-milk/frontend'

export const userService: UserService = serviceProvider(
  new UserServiceImplementation(),
  new UserServiceMock(),
  'USER_SERVICE'
)()
