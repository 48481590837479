import Wizard from 'components/base/wizard'
import React, { PureComponent } from 'react'
import Connect from 'app/redux/connect'
import { Connected } from 'app/interface/redux'
import { PreloadData } from './preload-data/PreloadData'
import AttributionChecker from './attribution-checker/AttributionChecker'

interface Props extends Connected {
  submit: () => void
  new: boolean
}

class UsefulTools extends PureComponent<Props> {
  render() {
    return (
      <Wizard
        steps={[
          {
            text: 'Preload Data',
            component: <PreloadData />,
          },
          {
            text: 'Attribution Check',
            component: <AttributionChecker />,
          },
        ]}
        type="horizontal"
      />
    )
  }
}

export default Connect(UsefulTools)
