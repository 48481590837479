import React from 'react'
import Tabs from './tabs'
import Wizard from 'components/base/wizard'

export default props => {
  return (
    <div>
      <h4>Tell us about this business</h4>
      <Wizard
        steps={[
          {
            text: 'Overview',
            component: <Tabs.Overview new={props.new} />,
          },
          {
            text: 'Social Media',
            component: <Tabs.Social />,
          },
          {
            text: 'Users',
            component: <Tabs.Users />,
          },
          {
            text: 'Data Types',
            component: <Tabs.DataSources />,
          },
          {
            text: 'Additional Info',
            component: <Tabs.Options />,
          },
        ]}
        type="vertical"
        portlet={false}
        buttons={true}
      />
    </div>
  )
}
