import { Roles, RoleDetails } from './roles'
import { Permissions } from './permissions'
import Users, { UserDetails } from './users'

export default {
  Roles,
  RoleDetails,
  Permissions,
  Users,
  UserDetails,
}
