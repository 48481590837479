import React from 'react'
import { TableDataProps } from './'

export function TableData({ children, width }: TableDataProps) {
  return (
    <td className="kt-datatable__cell" style={{ width, textAlign: 'left' }}>
      {children}
    </td>
  )
}
