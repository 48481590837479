import React from 'react'
import Interface, { InputRegularType } from '../../interface/input'

export default ({
  column,
  className,
  disabled = false,
  onBlur,
  onChange,
  subType,
  readOnly = false,
  value,
  placeholder,
  other,
}: Interface) => (
  <input
    className={className}
    disabled={disabled}
    onBlur={onBlur}
    onChange={event => onChange(column, event.target.value)}
    type={subType ? subType : InputRegularType.Text}
    readOnly={readOnly}
    value={value}
    placeholder={placeholder}
    list={other?.datalist ? other.datalist.id : undefined}
  />
)
