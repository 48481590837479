import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'

const Options = bindActions(
  store => ({
    edit: (state: any, option: number, value: string) => {
      let { options } = state.pages.business

      // Check if option is already defined
      if (options.find(x => x.branch_options_id === option)) {
        options.find(x => x.branch_options_id === option).field_value = value
      }
      // Otherwise create it
      else {
        options.push({
          branch_id: state.pages.business.main.branch_id,
          branch_options_id: option,
          field_value: value,
        })
      }

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            options: options,
          },
        },
      })
    },
  }),
  Store
)

export default Options
