import React from 'react'
import { IWizardComponent } from '../'

export default ({ active, setActive, steps }: IWizardComponent) => {
  const current = steps[active]
  return (
    <div className="kt-grid kt-wizard-v3 kt-wizard-v3--white">
      <div className="kt-grid__item">
        <div className="kt-wizard-v3__nav">
          <div className="kt-wizard-v3__nav-items">
            {steps.map((step, i) => (
              <button
                className="kt-wizard-v3__nav-item navigation-link"
                key={i}
                onClick={() => setActive(i)}
                data-ktwizard-state={active === i ? 'current' : 'step'}
              >
                <div className="kt-wizard-v3__nav-body">
                  <div className="kt-wizard-v3__nav-label">
                    <span>&nbsp;</span> {step.text}
                  </div>
                  <div className="kt-wizard-v3__nav-bar" />
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div style={{ padding: '2.5%' }}>{current ? current.component : null}</div>
    </div>
  )
}
