import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'
import Business from 'app/interface/data/business'
import BusinessDefault from 'app/redux/default/pages/business'

const BusinessActions = bindActions(
  () => ({
    initEmpty: state => ({
      pages: {
        ...state.pages,
        business: BusinessDefault,
      },
    }),

    init: (state: any, business: Business) => ({
      pages: {
        ...state.pages,
        business: {
          ...BusinessDefault,
          ...business,
        },
      },
    }),

    resetError: (state: any) => ({
      pages: {
        ...state.pages,
        business: {
          ...state.pages.business,
          error: null,
        },
      },
    }),
  }),
  Store
)

export default BusinessActions
