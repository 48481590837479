import React from 'react'

interface Interface {
  title: string
  children: any
}

export default ({ children, title }: Interface) => (
  <div className="kt-portlet kt-portlet--mobile">
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title">{title} </h3>
      </div>
    </div>
    <div className="kt-portlet__body">{children}</div>
  </div>
)
