import React from 'react'
import Connect from 'app/redux/connect'
import { Connected } from 'app/interface/redux'

const Actions = (props: Connected) => {
  const { user } = props.core.session
  return (
    <div className="kt-header__topbar">
      <div className="kt-header__topbar-item kt-header__topbar-item--user show">
        <div className="kt-header__topbar-wrapper">
          <div className="kt-header__topbar-user">
            <span className="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
            <span className="kt-header__topbar-username kt-hidden-mobile">
              {user!.firstName + ' ' + user!.lastName}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Connect(Actions)
