import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import { badDataService } from '@marketing-milk/frontend'
import { FeedbackModalProps } from './FeedbackModal.types'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export function FeedbackModal({ submission, onSuccess }: FeedbackModalProps) {
  const [isOpen, setModal] = useState(false)
  const [feedback, setFeedback] = useState('')

  function toggle() {
    setModal(_ => !_)
  }

  async function giveFeedback(approved: boolean) {
    if (!feedback) return
    try {
      await badDataService.reviewSubmission(
        submission.businessID,
        submission.id,
        approved,
        feedback
      )
      setModal(false)
      onSuccess()
    } catch (e) {
      toast.error('There was a problem saving your feedback')
      throw e
    }
  }

  return (
    <>
      <Button className="mr-2" color="primary" size="small" variant="contained" onClick={toggle}>
        Review
      </Button>
      {isOpen && (
        <Modal isOpen toggle={toggle}>
          <ModalHeader toggle={toggle}>Reviewing {submission.name}</ModalHeader>
          <ModalBody>
            <h4>Event Name</h4>
            <p>{submission.eventName}</p>
            <h4>Date Range</h4>
            <p>
              {submission.startDate} - {submission.endDate}
            </p>
            <h4>Uploads Reported</h4>
            {submission.uploads.map(upload => (
              <li key={`upload${upload.id}`}>
                <a
                  href={`${process.env.REACT_APP_DASHBOARD}/${submission.businessID}/tools/data-uploader/`}
                >
                  {upload.displayName}
                </a>
              </li>
            ))}
            <ul></ul>
            <h4>Feedback</h4>
            <TextField
              label="Feedback"
              value={feedback}
              onChange={e => setFeedback(e.target.value)}
              onKeyDownCapture={e => {
                if (e.key === 'Backspace' || e.key === 'Delete') {
                  e.stopPropagation()
                }
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className="mr-2"
              color="primary"
              size="small"
              variant="contained"
              onClick={() => giveFeedback(true)}
            >
              <i className="fa fa-check mr-2" />
              Approve
            </Button>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => giveFeedback(false)}
            >
              <i className="fa fa-times mr-2" />
              Reject
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
