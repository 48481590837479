import React from 'react'
import './TableHeader.scss'
import { TableHeaderProps } from './'

export function TableHeader({
  columns,
  sortRows,
  sortedHeader,
  sortDirection = 'down',
}: TableHeaderProps<any>) {
  return (
    <thead className="kt-datatable__head" style={{ width: '100%' }}>
      <tr className="kt-datatable__row">
        {columns.map((column, index) => {
          const match = column.text === sortedHeader
          const { sortBy, width = 100 / columns.length, text } = column
          return (
            <th
              className={`kt-datatable__cell ${sortBy && 'cursor-pointer'}`}
              key={index}
              style={{ width: width + '%', textAlign: 'left' }}
              onClick={() => sortBy && sortRows(sortBy ?? '', text?.toString() ?? '')}
            >
              <span className={match ? 'text-brand' : ''}>
                {text} {match && <i className={`fal fa-chevron-${sortDirection} text-brand`} />}
              </span>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
