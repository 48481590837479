import { MaterialDataTable } from '@marketing-milk/frontend'
import { Branch } from '@marketing-milk/interfaces'
import { GridCellParams, GridColDef, GridColumnTypesRecord } from '@material-ui/x-grid'
import { useDebounce } from 'hooks'
import { useFetchAllBranches } from 'hooks/branchHooks'
import { PageHeader } from 'pages/user-management/PageHeader'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BranchDelete } from './BranchDelete'
import { BranchManage } from './BranchManage'

enum BranchTableTypes {
  Abbreviation = 'Abbreviation',
  Name = 'Name',
  Actions = 'Actions',
}

const branchColTypes = (
  history: ReturnType<typeof useHistory>,
  resetTableState: () => void
): GridColumnTypesRecord => {
  return {
    [BranchTableTypes.Abbreviation]: {
      type: 'string',
      headerName: 'Abbreviation',
      disableColumnMenu: true,
      flex: 4,
      renderCell: ({ row: data }: GridCellParams) => {
        const row = data as Branch
        return <>{row.branchAbbreviation}</>
      },
    },
    [BranchTableTypes.Name]: {
      type: 'string',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 4,
      renderCell: ({ row: data }: GridCellParams) => {
        const row = data as Branch
        return <>{row.branchName}</>
      },
    },
    [BranchTableTypes.Actions]: {
      type: 'button',
      headerName: '',
      disableColumnMenu: true,
      flex: 1,
      resizable: false,
      renderCell: ({ row }: GridCellParams) => {
        const branch = row as Branch
        return (
          <>
            <BranchManage branch={branch} onSuccess={resetTableState} /> &nbsp;
            <BranchDelete branch={branch} onDelete={resetTableState} />
          </>
        )
      },
    },
  }
}

const branchTableColumns: GridColDef[] = [
  {
    field: 'name',
    type: BranchTableTypes.Name,
    headerName: 'Name',
  },
  {
    field: 'branchAbbreviation',
    type: BranchTableTypes.Abbreviation,
    headerName: 'Abbreviation',
  },
  {
    field: '',
    type: BranchTableTypes.Actions,
    headerName: 'Actions',
  },
]

export const BranchList = () => {
  const history = useHistory()
  const branchHook = useFetchAllBranches()
  const [searchTerm, setSearchTerm] = useState('')
  const { isDebouncing, debouncedValue } = useDebounce(searchTerm, 1000)

  useEffect(() => {
    branchHook.setPaginationParams(prevParams => ({
      ...prevParams,
      searchTerm: debouncedValue.trim().toLowerCase(),
      page: 0,
    }))
  }, [debouncedValue])

  const resetTableState = () => {
    branchHook.refetch()
    setSearchTerm('')
    branchHook.setPaginationParams(prevParams => ({
      ...prevParams,
      searchTerm: '',
      page: 0,
    }))
  }

  return (
    <>
      <PageHeader
        title="Branches"
        filterValue={searchTerm}
        onChangeFilter={value => {
          setSearchTerm(value)
        }}
      >
        <div style={{ flex: 'none' }}>
          <BranchManage onSuccess={resetTableState} />
        </div>
      </PageHeader>

      <div>
        <MaterialDataTable
          columns={branchTableColumns}
          columnTypes={branchColTypes(history, resetTableState)}
          rows={branchHook?.branches ?? []}
          pagination
          pageSize={branchHook.pageSize}
          page={branchHook.page}
          paginationMode={'server'}
          onPageChange={change => {
            branchHook.setPaginationParams(prevParams => ({
              ...prevParams,
              page: change.page + 1,
            }))
          }}
          rowCount={branchHook.count}
          height={'calc(100vh - 15rem)'}
          density="comfortable"
        />
      </div>
    </>
  )
}
