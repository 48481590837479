import React from 'react'
import { SelectOfflineEvent } from './select-offline-event/SelectOfflineEvent'

export function WhatOfflineEvent() {
  return (
    <>
      <h4>What offline event is bad?</h4>
      <span className="text-purple">* You can only select incoming offline events</span>
      <SelectOfflineEvent />
    </>
  )
}
