import React from 'react'

interface Interface {
  name: string
  icon: string
  desc: string
  stages: Array<any>
  active: boolean
  modify: () => void
}

export default ({ name, icon, desc, modify }: Interface) => {
  return (
    <div className="kt-portlet kt-portlet--height-fluid portlet-as-button" onClick={modify}>
      <div className="kt-portlet__body">
        <div className="kt-widget kt-widget--user-profile-2">
          <div className="kt-portlet__head kt-portlet__head--noborder">
            <div className="kt-portlet__head-label">
              <h1 className="kt-portlet__head-title text-brand">
                <i className={`fa fa-${icon} mr-2`} />
                {name}
              </h1>
            </div>
          </div>
          <div className="kt-widget__body">
            <div className="kt-widget__section">{desc}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
