import { useQuery } from 'react-query'
import { roleService } from 'app/service/role'
import { userService } from 'app/service/user'

export function useFetchAllRoles() {
  return useQuery(['roles'], () => roleService.getAllRoles(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

export function useFetchAllPermissions() {
  return useQuery(['allPermissions'], () => roleService.getAllPermissions(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

export function useFetchRole(roleID: string) {
  return useQuery(['role', roleID], () => roleService.getRoleByID(roleID), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

export function useFetchRolePermissions(roleID: string) {
  return useQuery(['rolePermissions', roleID], () => roleService.getRolePermissions(roleID), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

export function useFetchRoleUsers(roleID: string) {
  return useQuery(['users', roleID], () => roleService.getRoleUsers(roleID), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

export function useFetchAllAuth0Users() {
  return useQuery(['auth0Users'], () => userService.getAllAuth0Users(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}
