import React from 'react'
import Denied from 'pages/error/denied'
import GuardHelper from 'components/utility/guard'

interface Interface {
  component: any
  guard: string
  page: string
}

export default ({ component, guard, page }: Interface) => {
  document.title = `Marketing Milk - ${page}`

  return (
    <GuardHelper guard={guard}>
      {({ status }) => {
        return !status ? <Denied /> : component
      }}
    </GuardHelper>
  )
}
