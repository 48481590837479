import { useQuery } from 'react-query'
import { userService } from '@marketing-milk/frontend'

import {
  EntitySearch,
  PaginatedUsersResponse,
  PaginationQueryParams,
  UserWithBusiness,
} from '@marketing-milk/interfaces'
import { useEffect, useState } from 'react'

export function useFetchAllUsers() {
  const pageSize = 18
  const [users, setUsers] = useState<UserWithBusiness[]>()
  const [paginationParams, setPaginationParams] = useState<PaginationQueryParams>({
    searchTerm: '',
    entitySearch: EntitySearch.user,
    searchProp: 'firstName',
    sortProp: 'createdAt',
    sortDirection: 'DESC',
    page: 1,
    take: pageSize,
  })

  const query = useQuery<PaginatedUsersResponse, string>(
    ['users-paginated', paginationParams],
    () => userService.getAllWithBusinesses(paginationParams),
    {
      keepPreviousData: true,
      staleTime: 5000,
      retry: false,
    }
  )
  const { data } = query

  useEffect(() => {
    if (!data) return
    setUsers(data.users)
  }, [data])

  return {
    users,
    count: data?.count || 0,
    current: data?.users.length,
    pageSize,
    ...paginationParams,
    setPaginationParams,
    ...query,
  }
}

export function useFetchEmailActivity(userId: number) {
  return useQuery(
    [`${userId}_email_activity`, userId],
    () => userService.getEmailActivity(userId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
}

export function useFetchEmailDetails(userId: number, messageId: string, enabled: boolean) {
  return useQuery(
    [`${messageId}_email_details`, userId, messageId, enabled],
    () => userService.getEmailDetails(userId, messageId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  )
}

export function useGetAuth0User(userId: number, enabled: boolean) {
  return useQuery(
    [`${userId}_auth0_user`, userId, enabled],
    () => userService.getAuth0User(userId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
      enabled,
    }
  )
}

export function useGetMMUser(userId: number, enabled: boolean) {
  const query = useQuery(
    [`${userId}_mm_user`, userId, enabled],
    () => userService.getByID(userId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled,
    }
  )
  return {
    loadingMMUser: query.isLoading,
    user: query.data,
    fetchMMUser: query.refetch,
    fetchingMMUser: query.isFetching,
  }
}

export function useDebounce<T>(value: T, delay?: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  const [isDebouncing, setIsDebouncing] = useState(false)

  useEffect(() => {
    setIsDebouncing(true)
    const timer = setTimeout(() => {
      setIsDebouncing(false)
      setDebouncedValue(value)
    }, delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return { debouncedValue, isDebouncing }
}
