import React from 'react'
import { Button } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { PreviousUploadsTable } from './previous-uploads/PreviousUploads'
import { UploadDetailsHelp } from './components/upload-help/UploadDetailsHelp'

export function ClientUploads() {
  const params: { business: string } = useParams()
  return (
    <div className="container-fluid">
      <div className="row pb-3">
        <div className="col-6 px-0 center-vertical">
          <h4 className="mb-0 mt-2">Uploads</h4>
        </div>
        <div className="col-6 px-0">
          <div className="d-flex float-right">
            <UploadDetailsHelp />
            <a
              href={`${process.env.REACT_APP_DASHBOARD}/${params.business}/tools/data-uploader/upload`}
              target="_blank"
            >
              <Button variant="contained" color="primary" className="ml-2">
                New Upload
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 px-0">
          <PreviousUploadsTable />
        </div>
      </div>
    </div>
  )
}
