import React from 'react'
import { Navigation } from './navigation'

export const Sidebar = () => (
  <div
    className="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
    id="kt_aside"
  >
    <div className="kt-aside__brand kt-grid__item" id="kt_aside_brand">
      <div className="kt-aside__brand-logo">
        <a href={process.env.REACT_APP_DASHBOARD}>
          <img alt="marketing milk logo" src="/assets/admin/img/logo-small.png" />
        </a>
      </div>
      <div className="kt-aside__brand-tools"></div>
    </div>
    <div
      className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
      id="kt_aside_menu_wrapper"
    >
      <div className="kt-aside-menu kt-scroll ps" id="kt_aside_menu">
        <Navigation />
      </div>
    </div>
  </div>
)
