import Generic from './generic'

export const fetch = async () => {
  await Generic.fetch()
}

export default () => {
  const TEN_MINUTES = 10 * 60 * 1000 // Ten minute in ms
  fetch()
  setInterval(fetch, TEN_MINUTES)
}
