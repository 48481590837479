import React from 'react'
import Form from 'components/base/form'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import Basic from 'app/redux/actions/pages/business/basic'
import Input from 'components/base/form/interface/input'

const Google = (props: Redux) => {
  const { main } = props.pages.business

  return (
    <div className="m-form m-form--fit mb-5 mx-3">
      <div className="m-form__section">
        <Form
          save={false}
          onChange={Basic.update}
          onSubmit={() => null}
          groups={[
            {
              header: 'Google',
              rows: [
                {
                  column: 6,
                  fields: [
                    {
                      column: 'google_looker_embed_url',
                      text: 'Looker Embed URL',
                      type: 'regular',
                      value: main.google_looker_embed_url || '',
                    } as Input,
                  ],
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  )
}

export default Connect(Google)
