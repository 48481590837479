import React from 'react'
import { Connected } from '../../app/interface/redux'
import { BusinessRaw } from '../../app/interface/redux/types/data/generic/businesses'
import Connect from '../../app/redux/connect'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { SearchRounded } from '@material-ui/icons'
import { OwnershipGroup } from '@marketing-milk/interfaces'
import { MaterialDataTable } from '@marketing-milk/frontend'

interface OwnershipGroupAddBusinessesProps {
  ownershipGroup: OwnershipGroup
  onConfirm: (businessIds: number[]) => void
}
export function OwnershipGroupAddBusinessesComponent(
  props: OwnershipGroupAddBusinessesProps & Connected
) {
  const { ownershipGroup, onConfirm } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const reduxBusinesses = props.data.generic.businesses.data
  const [filter, setFilter] = React.useState<string>('')
  const [businesses, setBusinesses] = React.useState<BusinessRaw[]>(reduxBusinesses)
  const [selectedBusinessIds, setSelectedBusinessIds] = React.useState<number[]>([])

  React.useEffect(() => {
    setBusinesses(reduxBusinesses.filter(business => business.businessName.includes(filter)))
  }, [reduxBusinesses, filter])

  const handleAdd = async () => {
    handleClose()
    onConfirm(selectedBusinessIds)
  }

  const handleClose = () => {
    setFilter('')
    setIsOpen(false)
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setIsOpen(true)}>
        Add Businesses
      </Button>
      {isOpen && (
        <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle style={{ minWidth: '600px' }}>
            Add Businesses To {ownershipGroup.name}
          </DialogTitle>
          <DialogContent>
            <Input
              fullWidth
              style={{ marginBottom: '1em' }}
              value={filter}
              onChange={e => setFilter(e.target.value)}
              placeholder="Business Name"
              startAdornment={
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              }
            />
            <MaterialDataTable
              height={400}
              checkboxSelection
              pagination
              paginationMode="client"
              pageSize={5}
              columns={[{ field: 'businessName', headerName: 'Business Name', flex: 1 }]}
              rows={businesses}
              onSelectionModelChange={e => {
                setSelectedBusinessIds(e.selectionModel.map(id => Number(id)))
              }}
            />
          </DialogContent>
          <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button type="submit" onClick={handleAdd} color="primary" variant="contained">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export const OwnershipGroupAddBusinesses = Connect(OwnershipGroupAddBusinessesComponent)
