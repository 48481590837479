import React from 'react'
import { UploadSubmission } from '@marketing-milk/interfaces'
import './SubmissionTable.scss'
import { Actions } from '../actions'
import { SubmissionTableProps } from './'
import { Column, Table } from 'components/generic/table'
import Moment from 'moment'

export function SubmissionTable(props: SubmissionTableProps) {
  const { submissions, showPending, count, page, pageSize, changePage } = props
  const columns: Column<UploadSubmission>[] = [
    {
      text: 'Submission',
      value: submission => submission.displayName,
      sortBy: submission => submission.displayName,
    },
    {
      text: 'Submitted By',
      value: submission =>
        submission.submittedUser
          ? submission.submittedUser.firstName + ' ' + submission.submittedUser.lastName
          : 'N/A',
      sortBy: submission => (submission.submittedUser ? submission.submittedUser.firstName : 'N/A'),
    },
    {
      text: 'Business',
      value: submission => submission.businessName,
      sortBy: submission => submission.businessName,
    },
    {
      text: 'Submission Date',
      value: submission => Moment(submission.createdAt).format('MMMM Do YYYY, h:mm a'),
      sortBy: submission => submission.createdAt,
    },
  ]

  if (showPending) {
    columns.push({
      text: '',
      value: submission => <Actions submission={submission} />,
    })
  } else {
    columns.push({
      text: 'Status',
      value: submission => (
        <span
          className={`btn btn-bold btn-sm btn-font-sm btn-label-${
            submission.pending ? 'info' : submission.approved ? 'success' : 'danger'
          }`}
        >
          {submission.pending ? 'Pending' : submission.approved ? 'Approved' : 'Rejected'}
        </span>
      ),
      sortBy: submission => (submission.pending ? submission.pending : submission.approved),
    })
  }

  return (
    <Table
      columns={columns}
      rows={submissions}
      count={count}
      page={page}
      pageSize={pageSize}
      changePage={changePage}
    />
  )
}
