import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'
import Source from 'app/interface/data/funnel/source'
import { NestAPI } from '../../../../nestapi'

const Dataset = bindActions(
  () => ({
    load: async () => {
      Dataset.update([])
      const result: Source[] = await NestAPI.get('businesses/manage/describe')
      Dataset.update(result)
    },

    loadBusiness: async (state: any, id: number) => {
      Dataset.update([])
      const result: Source[] = await NestAPI.get(`businesses/manage/describe`, {
        params: { business_id: id },
      })
      Dataset.update(result)
    },

    update: (state: any, sources: any[]) => ({
      data: {
        ...state.data,
        funnel: {
          ...state.data.funnel,
          sources: {
            data: sources,
            loading: false,
            timestamp: +new Date(),
          },
        },
      },
    }),
  }),
  Store
)

export default Dataset
