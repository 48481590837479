import React from 'react'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import Branch from 'app/interface/data/branch'
import Input from 'components/base/form/input'
import Loading from 'components/message/loading'
import OptionActions from 'app/redux/actions/pages/business/options'

const Options = ({
  data: {
    generic: { branches },
  },
  pages: {
    business: {
      main: { branch_id },
      options,
    },
  },
}: Redux) => {
  const branch: Branch | undefined = branches.data.find(x => x.id === branch_id)
  let current

  if (!branch?.options || branch?.options?.length === 0) {
    return <p>There are no branch options</p>
  } else {
    return (
      <div>
        <h4 style={{ color: '#575962', fontSize: '1.3rem' }}>Branch Options</h4>
        {branches.loading ? (
          <Loading />
        ) : (
          branch!.options.map(option => {
            current = options.find(x => x.branch_options_id === option.id)
            return (
              <Input
                column={`${option.id}`}
                key={option.id}
                text={option.name}
                value={current ? current.field_value : ''}
                type="regular"
                onChange={(column, value) => OptionActions.edit(option.id, value)}
              />
            )
          })
        )}
      </div>
    )
  }
}

export default Connect(Options)
