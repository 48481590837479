import React from 'react'
import Base from './base'

class Bad extends React.Component {
  render() {
    return <Base filter={{ column: 'api_error', value: true }} {...this.props} />
  }
}

export default Bad
