import React, { useState } from 'react'
import { useFetchAllPermissions } from 'hooks'
import { PermissionsTable } from './PermissionsTable'
import { PageHeader } from '../PageHeader'
import { fuzzySearch } from '../search.helpers'

export function Permissions() {
  const permissions = useFetchAllPermissions()
  const [filter, setFilter] = useState('')
  const filteredPermissions = fuzzySearch(permissions.data ?? [], filter)
  return (
    <div>
      <PageHeader
        title="Permissions"
        filterValue={filter}
        onChangeFilter={value => setFilter(value)}
        placeholder="Search by value, description, etc."
      />
      <PermissionsTable permissions={filteredPermissions} />
    </div>
  )
}
