import React, { useState } from 'react'
import { Chip, Typography } from '@material-ui/core'
import { GridCellParams } from '@material-ui/x-grid'
import { FeedbackModal } from './feedback-modal/FeedbackModal'
import { badDataService, MaterialDataTable, useCreateFetchHook } from '@marketing-milk/frontend'

export function BadDataReviewSubmissions() {
  const [refresh, setRefresh] = useState(0)
  const submissions = useCreateFetchHook(() => badDataService.getSubmissions(false), refresh)

  function refreshBadData() {
    setRefresh(_ => _ + 1)
  }

  return (
    <>
      <Typography variant="h4" style={{ color: 'black' }}>
        Bad Data Submissions
      </Typography>
      <MaterialDataTable
        sortingOrder={['asc', 'desc']}
        rows={submissions ?? []}
        rowHeight={38}
        headerHeight={50}
        density={'comfortable'}
        columnBuffer={10}
        columns={[
          {
            field: 'businessName',
            headerName: 'Business',
            description: 'The name of the business',
            type: 'string',
            flex: 800,
          },
          {
            field: 'name',
            headerName: 'Report Name',
            description: 'The name of the bad data report',
            type: 'string',
            flex: 800,
          },
          {
            field: 'startDate',
            headerName: 'Start Date',
            description: 'What day the bad data started',
            type: 'string',
            width: 150,
          },
          {
            field: 'endDate',
            headerName: 'End Date',
            description: 'What day the bad data ended',
            type: 'string',
            width: 150,
          },
          {
            field: 'eventName',
            headerName: 'Offline Event',
            description: 'What incoming offline event is having issues',
            type: 'string',
            width: 150,
          },
          {
            field: 'uploads',
            headerName: 'Affected Uploads',
            description: 'Uploads affected by the bad data',
            type: 'string',
            width: 300,
            renderCell: ({ row }: GridCellParams) => (
              <>
                {row.uploads.map(_ => (
                  <Chip
                    key={`upload_${_.id}`}
                    label={_.displayName}
                    variant="outlined"
                    color="primary"
                  />
                ))}
              </>
            ),
          },
          {
            field: 'submittedBy',
            headerName: 'Reported By',
            description: 'The user who submitted the bad data report',
            type: 'string',
            width: 150,
            renderCell: ({ row }: GridCellParams) => (
              <>
                {row.submittedBy.firstName} {row.submittedBy.lastName}
              </>
            ),
          },
          {
            field: '',
            headerName: 'Actions',
            type: 'string',
            width: 250,
            renderCell: ({ row }: GridCellParams) => (
              <FeedbackModal submission={row as any} onSuccess={refreshBadData} />
            ),
          },
        ]}
        checkboxSelection={false}
        pagination
        pageSize={10}
      />
    </>
  )
}
