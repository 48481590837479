import React, { useState } from 'react'
import { Business, UserWithBusiness } from '@marketing-milk/interfaces'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import AssignBusinesses from '../../modals/AssignBusinesses'
import { Delete } from '../../modals/Delete'
import { userService } from '@marketing-milk/frontend'
import { toast } from 'react-toastify'
import axios from 'axios'
export interface BusinessTabProps {
  userBusinesses: Business[] | undefined
  mmUser?: UserWithBusiness
  refetchUser: () => void
}

export function BusinessTab({ userBusinesses, mmUser, refetchUser }: BusinessTabProps) {
  const [deleteModal, setDeleteModal] = useState(false)
  const onSave = () => {
    refetchUser()
  }

  const onDelete = async (business: Business) => {
    if (mmUser) {
      try {
        await userService.removeUserFromBusiness(mmUser.id, business.id)
        toast.success('Successfully removed business from user')

        refetchUser()
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data) {
          e.response.data.errors.forEach(err => {
            toast.error(err.description)
          })
        } else {
          toast.error(`We could not delete your businesses at this time`)
        }
      }
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
        <Typography>All Businesses assigned to this User</Typography>
        <AssignBusinesses mmUser={mmUser} onSave={onSave} />
      </div>
      <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 27rem)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Name</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Pod</Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userBusinesses?.map((b, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  <Typography>{b.businessName}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>{b.podName}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Delete
                    title={'Remove business from user?'}
                    body={`Are you sure that you want to remove ${mmUser?.email} from role "${b?.businessName}"?`}
                    onSubmitted={onDelete}
                    item={b}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
