import React, { useEffect, useState } from 'react'
import { useFetchAllRoles } from 'hooks'
import { RolesTable } from './RolesTable'
import { PageHeader } from '../PageHeader'
import { useHistory } from 'react-router-dom'
import { CreateRoleModal } from './CreateRoleModal'
import { DeleteRoleModal } from './DeleteRoleModal'
import { Auth0Role, User } from '@marketing-milk/interfaces'
import { toast } from 'react-toastify'
import { roleService } from 'app/service/role'
import { AddUsersModal } from './AddUsersModal'
import { fuzzySearch } from '../search.helpers'

export function Roles() {
  const history = useHistory()
  const roles = useFetchAllRoles()
  const [filter, setFilter] = useState('')
  const filteredRoles = fuzzySearch(roles.data ?? [], filter)
  const [roleToDelete, setRoleToDelete] = useState<Auth0Role | undefined>()
  // store role ID to query users for add users modal
  const [usersRoleID, setUsersRoleID] = useState<string | undefined>()
  // store users queried for modal
  const [roleUsers, setRoleUsers] = useState<User[]>([])

  const viewRole = (role: Auth0Role) => history.push(`${role.id!}/details`)
  const deleteRole = async (roleID: string) => {
    try {
      await roleService.deleteRole(roleID)
      toast.success('Role successfully deleted.')
      roles.refetch()
    } catch (e) {
      toast.error('There was an error deleting your role.')
      console.log(e) //TODO: send to sentry?
    }
  }
  const createRole = async (roleDTO: Auth0Role) => {
    try {
      const newRole = await roleService.createRole(roleDTO)
      if (newRole) {
        toast.success(`Role successfully created: ${newRole.name}`)
        roles.refetch()
      }
    } catch (e) {
      toast.error(`There was an error creating role: ${roleDTO.name}. Please try again.`)
      console.log(e) //TODO: send to sentry?
    }
  }

  const assignRoleUsers = async (auth0UserIDs: string[]) => {
    if (!!usersRoleID) {
      try {
        await roleService.addRoleUsers(usersRoleID, auth0UserIDs)
        toast.success('User(s) successfully added to this role.')
        // clear role ID to close modal for next role
        setUsersRoleID(undefined)
        // clear role users for next role
        setRoleUsers([])
      } catch (e) {
        toast.error('There was an error adding users to this role.')
        console.log(e) //TODO: send to sentry?
      }
    }
  }

  const handleAssignRoleUsers = async (userIds: number[]) => {
    if (!!usersRoleID) {
      try {
        await roleService.addUsersToRole(usersRoleID, userIds)
        toast.success('User(s) successfully added to this role.')
        // clear role ID to close modal for next role
        setUsersRoleID(undefined)
        // clear role users for next role
        setRoleUsers([])
      } catch (e) {
        toast.error('There was an error adding users to this role.')
      }
    }
  }

  // if user id is present for assign users modal
  // then use role ID to query users for that role and store in state
  useEffect(() => {
    if (!!usersRoleID) {
      roleService.getRoleUsers(usersRoleID).then(users => {
        setRoleUsers(users)
      })
    }
  }, [usersRoleID])

  return (
    <div>
      <PageHeader
        title="Roles"
        filterValue={filter}
        onChangeFilter={value => setFilter(value)}
        placeholder="Search by name, description, etc."
      >
        <div className="center-vertical" style={{ flex: 'none' }}>
          <CreateRoleModal onSubmit={dto => createRole(dto)} />
        </div>
      </PageHeader>
      <RolesTable
        roles={filteredRoles}
        onView={viewRole}
        onAssignUsers={roleID => setUsersRoleID(roleID)}
        onDelete={role => setRoleToDelete(role)}
      />
      <AddUsersModal
        isOpen={!!usersRoleID}
        setIsOpen={() => setUsersRoleID(undefined)}
        addedUsers={roleUsers}
        onSubmit={handleAssignRoleUsers}
      />
      <DeleteRoleModal
        deleteRole={roleToDelete}
        onDelete={roleID => deleteRole(roleID)}
        onCancel={() => setRoleToDelete(undefined)}
      />
    </div>
  )
}
