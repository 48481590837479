import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { Business } from '@marketing-milk/interfaces'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { DeletedBusinessesModalProps } from './DeletedBusinessesModal.types'
import { MaterialDataTable, useCreateFetchHook, businessService } from '@marketing-milk/frontend'

export function DeletedBusinessesModal({ isOpen, onClose, onDelete }: DeletedBusinessesModalProps) {
  const [loading, setLoading] = useState<Number>()
  const [refresh, setRefresh] = useState(0)
  const [callbackOnClose, setCallbackOnClose] = useState(false)
  const deletedBusinesses: Business[] | undefined = useCreateFetchHook(
    businessService.getDeletedBusinesses,
    refresh
  )

  function toggle() {
    if (isOpen && callbackOnClose) {
      onDelete()
      setCallbackOnClose(false)
    }
    onClose()
  }

  async function onDeleteBusiness(business: Business) {
    try {
      if (loading) {
        return
      }
      setLoading(business.id)
      await businessService.restoreBusinessByID(business.id)
      setCallbackOnClose(true)
      setRefresh(_ => _ + 1)
    } catch (e) {
      toast.error(`${business.businessName}'s account could not be restored at this time`)
      throw e
    } finally {
      setLoading(undefined)
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <Modal isOpen size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>Deleted Businesses</ModalHeader>
      <ModalBody>
        <MaterialDataTable
          height={'calc(100vh - 22rem)'}
          sortingOrder={['asc', 'desc']}
          rows={deletedBusinesses || []}
          rowHeight={38}
          headerHeight={50}
          columns={[
            {
              field: 'businessName',
              type: 'string',
              headerName: 'Name',
              width: 200,
            },
            {
              field: 'branchName',
              type: 'string',
              headerName: 'Branch',
              width: 200,
            },
            {
              field: 'podName',
              type: 'string',
              headerName: 'Pod',
              width: 200,
            },
            {
              field: 'actions',
              type: 'string',
              headerName: 'Actions',
              width: 200,
              disableColumnMenu: true,
              renderCell: ({ row }: any) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onDeleteBusiness(row)}
                  disabled={!!loading}
                >
                  Restore
                </Button>
              ),
            },
          ]}
        />
      </ModalBody>
    </Modal>
  )
}
