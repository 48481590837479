import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { Branch, UpdateBranch } from '@marketing-milk/interfaces'
import { branchService } from '@marketing-milk/frontend'
import axios from 'axios'
import { toast } from 'react-toastify'

interface BranchManageProps {
  branch?: Branch
  onSuccess: () => void
}

interface BranchFields {
  name: string
  abbreviation: string
  options: UpdateBranch['options']
}

export const BranchManage = (props: BranchManageProps) => {
  const { branch } = props
  const defaultOptions = branch?.options ?? []
  const [open, setOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<UpdateBranch['options']>(defaultOptions)
  const initialValues: BranchFields = {
    name: branch?.branchName ?? '',
    abbreviation: branch?.branchAbbreviation ?? '',
    options: options,
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async values => {
      const dto: UpdateBranch = {
        branch: {
          branch_name: values.name,
          abbreviation: values.abbreviation,
        },
        options: values.options,
      }

      try {
        if (branch) {
          await branchService.update(branch.id, dto)
        } else {
          await branchService.create(dto)
        }
        handleClose()
        props.onSuccess()
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 400) {
          toast.error('There was an error, please ensure the abbreviation is 3 characters long')
        } else {
          toast.error('Something unexpected went wrong creating the branch.')
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  const addOptions = () => {
    setOptions([...options, { name: '' }])
  }

  const deleteOption = (idx: number) => {
    options.splice(idx, 1)
    setOptions([...options])
  }

  const onChangeOption = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id?: number
  ) => {
    const value = event.target.value
    if (event.target.name.startsWith('option-')) {
      const idx = Number(event.target.name.split('-')[1])
      options[idx] = {
        id,
        name: value,
      }
      setOptions(options)
    }
    formik.handleChange(event)
  }

  return (
    <>
      <Button
        startIcon={branch ? <EditIcon /> : <AddIcon />}
        data-testid="create-user-btn"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        {branch ? 'Edit' : 'Create'}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onKeyDown={e => e.stopPropagation()}
      >
        <DialogTitle id="alert-dialog-slide-title">{branch ? 'Edit' : 'Create'} Branch</DialogTitle>

        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogContent style={{ paddingBottom: '2em' }}>
            <div className="container">
              <div className="row">
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  style={{ width: '400px' }}
                />
              </div>
              <div className="row pt-2">
                <TextField
                  id="abbr"
                  name="abbreviation"
                  label="Abbreviation (must be 3 characters)"
                  value={formik.values.abbreviation}
                  onChange={formik.handleChange}
                  style={{ width: '400px' }}
                />
              </div>
              <div className="row py-4">
                <h5>Branch Options</h5>
                <span>
                  These are optional branch options to add additional qualifiers to businesses
                  belonging to this branch
                </span>
                <div className="container">
                  {options.map((option, idx) => (
                    <div className="row">
                      <TextField
                        id={`option-${idx}`}
                        name={`option-${idx}`}
                        label={`Option ${idx + 1}`}
                        value={option.name}
                        onChange={event => onChangeOption(event, option.id)}
                        style={{ width: '400px' }}
                      />
                      <div className="text-center pt-3 pl-2">
                        <Button
                          className="text-center"
                          style={{ backgroundColor: '#ff5555', color: 'white' }}
                          id={`delete-option-btn-${idx}`}
                          onClick={event => deleteOption(idx)}
                        >
                          <DeleteIcon />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <Button
                  startIcon={<AddIcon />}
                  data-testid="add-option-btn"
                  variant="contained"
                  color="primary"
                  onClick={addOptions}
                >
                  Option
                </Button>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {branch ? 'Edit' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
