import React from 'react'
import Google from './google'
import Facebook from './facebook'

export default () => (
  <div>
    <Facebook />
    <Google />
  </div>
)
