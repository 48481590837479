import React from 'react'
import Moment from 'moment'
import { Avatar, Chip } from '@material-ui/core'
import { GridCellParams } from '@material-ui/x-grid'
import { EditUploadModalProps } from '../EditUploadModal'
import { MaterialDataTable } from '@marketing-milk/frontend'
import { DownloadUploadButton } from '../../download-upload-button/DownloadUploadButton'

export function UploadVersionHistory({ upload }: EditUploadModalProps) {
  return (
    <MaterialDataTable
      sortingOrder={['asc', 'desc']}
      rows={upload.versions}
      rowHeight={38}
      headerHeight={50}
      density={'comfortable'}
      columnBuffer={10}
      columns={[
        {
          field: 'version',
          headerName: 'Version',
          description: 'The version of the upload',
          type: 'string',
          width: 100,
        },
        {
          field: 'fileVerification',
          headerName: 'Valid File',
          description: 'Are the contents of the file in a valid format?',
          type: 'string',
          width: 150,
          renderCell: ({ row }: GridCellParams) => {
            const isValid = row.validationStatus === 'valid'
            const [color, text] = isValid ? ['primary', 'Valid'] : ['secondary', 'Invalid']
            return <Chip color={color as any} label={text} />
          },
        },
        {
          field: 'uploadedBy',
          headerName: 'Uploaded By',
          description: 'The user who created the upload',
          type: 'string',
          width: 150,
          renderCell: ({ row }: GridCellParams) => {
            if (!row.submittedBy) {
              return <>N/A</>
            }

            const fullName = `${row.submittedBy?.firstName} ${row.submittedBy?.lastName}`
            return (
              <Chip
                avatar={
                  <Avatar alt={`${fullName}'s profile pic`} src={row.submittedBy?.profilePicture} />
                }
                label={fullName}
              />
            )
          },
        },
        {
          field: 'uploadedAt',
          headerName: 'Uploaded At',
          description: 'When the upload was made',
          type: 'string',
          width: 150,
          renderCell: ({ row }: GridCellParams) => {
            return <>{Moment(row.dateUploaded).format('MM-DD-YYYY (hh:mmA)')}</>
          },
        },
        {
          field: 'reviewedBy',
          headerName: 'Reviewed By',
          description: 'The user who reviewed the upload',
          type: 'string',
          width: 150,
          renderCell: ({ row }: GridCellParams) => {
            if (!row.reviewedBy) {
              return <>N/A</>
            }

            const fullName = `${row.reviewedBy?.firstName} ${row.reviewedBy?.lastName}`
            return (
              <Chip
                avatar={
                  <Avatar alt={`${fullName}'s profile pic`} src={row.reviewedBy?.profilePicture} />
                }
                label={fullName}
              />
            )
          },
        },
        {
          field: 'download',
          headerName: 'Download',
          description: 'Download this file to look at it locally',
          type: 'string',
          width: 150,
          renderCell: ({ row }: GridCellParams) => {
            return <DownloadUploadButton upload={upload} version={row as any} />
          },
        },
      ]}
      checkboxSelection={false}
      pagination
      pageSize={10}
    />
  )
}
