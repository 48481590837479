import React from 'react'
import Input from './input'
import Interface from './interface/row'

export default ({ column = 6, fields, onChange, onError, disabled }: Interface) => (
  <div className="row mt-3">
    {fields.map((field, i) => (
      <div className={`col-md-${column}`} key={i}>
        <div className="form-group m-form__group">
          <Input {...field} onChange={onChange} onError={onError} />
        </div>
      </div>
    ))}
  </div>
)
