import React from 'react'
import Interface from '../../interface/input'

export default ({ column, className, onChange, value, placeholder }: Interface) => (
  <textarea
    className={className}
    onChange={event => onChange(column, event.target.value)}
    rows={10}
    value={value}
    placeholder={placeholder}
  />
)
