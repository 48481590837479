import { ExampleFile } from '@marketing-milk/interfaces'

export interface ExampleFilesState {
  files: ExampleFile[]
  showSpinner: boolean
}

export const defaultExampleFilesState: ExampleFilesState = {
  files: [],
  showSpinner: true,
}
