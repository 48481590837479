import Connect from 'app/redux/connect'
import Redux, { Connected } from 'app/interface/redux'

const Missing = ({
  core: {
    session: { active },
  },
  history,
}: Connected) => {
  history.push(active ? '/admin/dashboard' : '/admin/login')
  return null
}

const map = (state: Redux) => ({
  core: {
    session: state.core.session,
  },
})

export default Connect(Missing, map)
