import Card from './card'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import FunnelActions from 'app/redux/actions/pages/business/funnel'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

const SortableItem = SortableElement(({ card, funnel, indexMePlease }) => {
  return (
    <Card
      focus={() => FunnelActions.focusStage(indexMePlease)}
      index={indexMePlease}
      active={funnel.active}
      {...card}
    />
  )
})

const SortableList = SortableContainer(({ funnel, items }) => (
  <ul style={{ padding: 0 }}>
    {items.map((card, index) => (
      <SortableItem key={index} index={index} card={card} funnel={funnel} indexMePlease={index} />
    ))}
  </ul>
))

class Stages extends PureComponent<Redux> {
  onDragEnd = result => {
    const { stages } = this.props.pages.business.funnel
    if (stages[result.oldIndex] && stages[result.newIndex]) {
      FunnelActions.changeOrder(result.oldIndex, result.newIndex)
    }
  }

  render() {
    const {
      pages: {
        business: { funnel },
      },
    } = this.props
    const cards = funnel.stages.map(funnel => ({
      desc: funnel.desc,
      name: funnel.name,
      icon: funnel.icon,
      order: funnel.order,
    }))
    return (
      <div className="kt-portlet__body">
        <div className="kt-notes">
          <div className="kt-notes__items">
            <SortableList funnel={funnel} items={cards} onSortEnd={this.onDragEnd} />
            <div className="kt-notes__item">
              <div className="kt-notes__media" onClick={FunnelActions.addStage}>
                <span className="kt-notes__icon bg-info cursor-pointer">
                  <i className="fas fa-plus text-white" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const StageComponent = Stages
export default Connect(Stages)
