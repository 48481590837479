import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import Loading from 'components/message/loading'
import TagActions from 'app/redux/actions/pages/business/tags'
import { BusinessTag } from '@marketing-milk/interfaces'
import { NestAPI } from '../../../../../../../../../../app/nestapi'

interface State {
  available: Array<BusinessTag>
  loading: boolean
}

class Tags extends PureComponent<Redux> {
  state: State = {
    available: [],
    loading: true,
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const tags = await NestAPI.get('businesses/manage/tags')
    this.setState({
      available: tags,
      loading: false,
    })
  }

  onChange = (tag: number): void => {
    TagActions.toggle(tag)
  }

  render() {
    let style
    const { tags } = this.props.pages.business
    const { available, loading } = this.state
    return (
      <div className="mt-5">
        <h4 style={{ color: '#575962', fontSize: '1.3rem' }}>Tags</h4>
        {loading ? (
          <Loading />
        ) : (
          available.map(tag => {
            style = tags.find(x => x === tag.id)
              ? {
                  background: tag.color_hex,
                  border: `1px solid ${tag.color_hex}`,
                  color: 'white',
                }
              : {
                  border: `1px solid ${tag.color_hex}`,
                  color: tag.color_hex,
                }

            return (
              <label
                className="tag-label btn"
                key={tag.id}
                style={style}
                onClick={() => this.onChange(tag.id)}
              >
                {tag.label}
              </label>
            )
          })
        )}
      </div>
    )
  }
}

export default Connect(Tags)
