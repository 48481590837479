import { AxiosResponse } from 'axios'
import { UserService } from './User.types'
import { NestAPI } from '../../nestapi'
import {
  CreateUserDTO,
  UpdateUserDTO,
  UserWithBusiness,
  NewUserResponse,
  PostResponse,
  Auth0User,
} from '@marketing-milk/interfaces'

export class UserServiceImplementation implements UserService {
  async create(userDTO: CreateUserDTO): Promise<PostResponse<NewUserResponse>> {
    const newUser: PostResponse<NewUserResponse> = await NestAPI.post('users', userDTO)
    return newUser
  }
  async getAll() {
    const users: UserWithBusiness[] = await NestAPI.get('users')
    return users
  }
  async getAllAuth0Users() {
    const users: Auth0User[] = await NestAPI.get('users/auth0/users')
    return users
  }
  async getByID(userID: number) {
    const users: UserWithBusiness = await NestAPI.get(`users/${userID}`)
    return users
  }
  async updateByID(userID: number, userDTO: UpdateUserDTO) {
    await NestAPI.patch(`users/${userID}`, userDTO)
  }
  async deleteByID(userID: number) {
    await NestAPI.delete(`users/${userID}`)
  }

  async getAuth0User(email: string) {
    const user: Auth0User = await NestAPI.post(`users/auth0`, { email })
    return user
  }
}
