import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Auth0Role, UserWithBusiness } from '@marketing-milk/interfaces'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useFetchAllRoles } from '../../../../hooks'
import { roleService } from 'app/service/role'
import { RoleSelection } from '../shared/RoleSelection'

type AssignRolesType = {
  mmUser?: UserWithBusiness
  onSave: () => void
  withButton?: boolean
  openModal?: boolean
}

export function AssignRoles({
  mmUser,
  onSave,
  withButton = true,
  openModal = false,
  ...props
}: AssignRolesType & Partial<DialogProps>) {
  const [filteredRoles, setFilteredRoles] = useState<Auth0Role[] | undefined>()
  const { data: auth0Roles } = useFetchAllRoles()

  useEffect(() => {
    const roles = mmUser?.assignedRoles?.length
      ? auth0Roles?.filter(r => !mmUser?.assignedRoles?.map(role => role.id).includes(r.id))
      : auth0Roles

    setFilteredRoles(roles)
  }, [mmUser, auth0Roles])

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  useEffect(() => {
    if (openModal) {
      setOpen(true)
    } else {
      handleClose()
    }
  }, [openModal])

  const formik = useFormik({
    initialValues: {
      auth0Roles: filteredRoles?.map(role => ({
        label: role.name,
        value: role.name,
        item: role,
      })),
    },
    // validationSchema: validationSchema,
    onSubmit: async values => {
      const auth0Roles = values.auth0Roles?.map(value => value.item)
      if (!auth0Roles) return

      try {
        await roleService.addRolesToAuth0User(mmUser!.id, auth0Roles)

        handleClose()
        onSave()
        toast.success('Updated user roles')
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast.error(`Error: ${e.response?.data}`)
        }
      }
    },
  })

  return (
    <>
      {withButton && (
        <Button
          data-testid="assign-roles-button"
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <span>Assign Roles</span>
        </Button>
      )}

      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        keepMounted
        onClose={props.onClose ? props.onClose : handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        {...props}
      >
        <DialogTitle id="alert-dialog-slide-title">Assign Roles</DialogTitle>
        {/*this resets the state of the modal so that if the user closes the modal after modifying the roles, but didn't save them, the next time it opens, the original state will be preserved*/}
        {open ? (
          <form className="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <DialogContent>
              {/* canManageRoles is hard coded to true as this modal isn't available without it */}
              <RoleSelection
                formik={formik}
                open={open}
                setRoles={auth0Roles?.filter(r =>
                  mmUser?.assignedRoles?.map(role => role.id).includes(r.id)
                )}
                auth0Roles={auth0Roles}
                canManageRoles={true}
                disableMultiSelectExpansion={false}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  )
}
