import React from 'react'
import Actions from './actions'
import Navigation from './navigation'

export default () => (
  <div className="kt-header kt-grid__item  kt-header--fixed">
    <Navigation />
    <Actions />
  </div>
)
