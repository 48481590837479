import React, { useContext } from 'react'
import { TextField } from '@material-ui/core'
import { badDataSubmissionContext } from '../../../submission-context/BadDataSubmissionContext'

export function InputReportName() {
  const { submission, setSubmission } = useContext(badDataSubmissionContext)
  return (
    <TextField
      label="Report Name"
      value={submission.name}
      fullWidth
      onChange={e => setSubmission!({ name: e.target.value })}
    />
  )
}
