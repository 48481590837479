import React, { useEffect, useState } from 'react'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import Input from 'components/base/form/input'
import { leadCardService } from '@marketing-milk/frontend'
import { LeadCard } from '@marketing-milk/interfaces'
import Select from 'react-select'
import BusinessActions from 'app/redux/actions/pages/business/basic'
import { Option } from 'react-select/src/filters'

const LeadPolling = ({
  pages: {
    business: { main, id },
  },
}: Redux) => {
  const [leadPolling, setLeadPolling] = useState(
    !!main.fb_leads_polling_form_ids && main.fb_leads_polling_form_ids.length > 0
  )
  // console.log(main.fb_leads_polling_form_ids);
  const [leadCards, setLeadCards] = useState<LeadCard[]>([])

  const leadOptions = leadCards.map(_ => ({
    label: _.formName,
    value: _.fbID,
  }))

  const onToggle = () => {
    // if toggling off, make sure the config is set to empty
    if (leadPolling) {
      BusinessActions.update('fb_leads_polling_form_ids', null)
    }
    setLeadPolling(_ => !_)
  }

  const onChooseLeadCards = (selected: Option[] | null) => {
    BusinessActions.update(
      'fb_leads_polling_form_ids',
      selected && selected.length ? selected.map(_ => _.value) : null
    )
  }

  useEffect(() => {
    const getLeadCards = async (businessID: number) => {
      setLeadCards(await leadCardService.getAll(businessID))
    }

    if (id) {
      getLeadCards(id)
    }
  }, [id])

  return (
    <>
      {!!id ? (
        <div className="row">
          <div className="col-12">
            <h4>Leads Polling</h4>
            <p>
              This optionally allows a business to poll for leads every 10 minutes on selected
              leadgen forms.
            </p>
          </div>
          <div className="col" style={{ maxWidth: 200 }}>
            <Input text="Poll for Leads?" type="toggle" onChange={onToggle} value={leadPolling} />
          </div>
          <div className="col">
            <div className="form-group">
              <label>Leadgen Forms</label>
              <Select
                options={leadOptions}
                value={leadOptions.filter(_ => main.fb_leads_polling_form_ids?.includes(_.value))}
                // @ts-expect-error admin is a disaster
                onChange={e => onChooseLeadCards(e)}
                isMulti
                isDisabled={!leadPolling}
                isClearable={true}
                menuPlacement="auto"
              />
            </div>
          </div>
        </div>
      ) : (
        <span className="text-info">Unavailable when creating a business</span>
      )}
    </>
  )
}

export default Connect(LeadPolling)
