import React, { useState } from 'react'
import { getInitials, ProfilePicture } from '@marketing-milk/frontend'
import { User } from '@marketing-milk/interfaces'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { AddUsersModal } from './AddUsersModal'
import { DeleteUserModal } from './DeleteUserModal'
import { Alert } from '@material-ui/lab'

interface UsersSectionProps {
  users?: User[]
  handleAddUsers: (userIds: number[]) => void
  handleRemoveUser: (userId: number) => void
}

export function UsersSection(props: UsersSectionProps) {
  const [addUsersOpen, setAddUsersOpen] = useState(false)
  const [userToDelete, setUserToDelete] = useState<User | undefined>()

  if (!props.users) return <></>

  return (
    <div>
      <div className="d-flex mb-4">
        <Typography variant="body1">
          View users assigned to this role, as well as remove them or assign more.
        </Typography>
        <div className="mr-0 ml-auto d-flex">
          <div className="center-vertical" style={{ flex: 'none' }}>
            <Button variant="contained" color="primary" onClick={() => setAddUsersOpen(true)}>
              Add Users
            </Button>
          </div>
        </div>
      </div>
      {props.users && props.users.length > 0 ? (
        <TableContainer className="mb-4" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.users?.map(user => (
                <TableRow key={user.id}>
                  <TableCell className="cursor-pointer" component="th" scope="row">
                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                      <ProfilePicture
                        initials={getInitials(user.firstName, user.lastName)}
                        image={user.profilePicture}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <div className="center-vertical pl-3">
                        <Typography style={{ height: 15 }}>
                          {`${user.firstName}  ${user.lastName}`.trim()}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="cursor-pointer" align="left">
                    {user.email}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => setUserToDelete(user)}>
                      <DeleteIcon className="text-danger" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert
          variant="standard"
          severity="info"
          className="mt-3"
          action={
            <Button
              className="bg-white"
              disableElevation
              variant="contained"
              onClick={() => setAddUsersOpen(true)}
            >
              Add Users
            </Button>
          }
        >
          <Typography variant="h6" className="mt-n1">
            No Users Assigned
          </Typography>
          No users have been added to this role. Once users have been assigned they will show up
          here.
        </Alert>
      )}
      <AddUsersModal
        isOpen={addUsersOpen}
        setIsOpen={isOpen => setAddUsersOpen(isOpen)}
        addedUsers={props.users}
        onSubmit={props.handleAddUsers}
      />
      <DeleteUserModal
        deleteUser={userToDelete}
        removeUser={userId => {
          props.handleRemoveUser(userId)
          setUserToDelete(undefined)
        }}
        onCancel={() => setUserToDelete(undefined)}
      />
    </div>
  )
}
