import React, { useEffect } from 'react'
import { CustomSelect } from './CustomSelect'

interface RoleSelectionProps {
  auth0Roles: any
  canManageRoles: boolean
  formik: any
  open: boolean
  setRoles?: any
  disableMultiSelectExpansion?: boolean
}

export function RoleSelection({
  auth0Roles,
  canManageRoles,
  formik,
  open,
  setRoles,
  disableMultiSelectExpansion,
}: RoleSelectionProps) {
  const [availableRoles, setAvailableRoles] = React.useState<any>(auth0Roles)
  const [filteredRoles, setFilteredRoles] = React.useState<any>(setRoles)

  useEffect(() => {
    setFilteredRoles(setRoles)
  }, [setRoles])

  useEffect(() => {
    setAvailableRoles(auth0Roles)
  }, [auth0Roles])

  return (
    <CustomSelect
      options={availableRoles
        ?.filter(role => canManageRoles || ['Client', 'Employee'].includes(role.name))
        .map(role => ({
          label: role.name,
          value: role.name,
          item: role,
        }))}
      defaultValues={filteredRoles
        ?.filter(role => canManageRoles || ['Client', 'Employee'].includes(role.name))
        .map(role => ({
          label: role.name,
          value: role.name,
          item: role,
        }))}
      onChange={(value, action) => {
        if (value && value.length > 1) {
          if (action.action === 'select-option') {
            if (action.option!.value !== 'Client' && value[0].value !== 'Client') {
              formik.setFieldValue('auth0Roles', value ? value : [])
            } else {
              // @ts-ignore -- value is an array, so it will have splice
              value.splice(value.length - 1, 1)
            }
          } else {
            formik.setFieldValue('auth0Roles', value ? value : [])
          }
        } else {
          formik.setFieldValue('auth0Roles', value ? value : [])
        }
      }}
      clearSelection={!open && !setRoles}
      placeHolderText="Role"
      fieldName="auth0Roles"
      isMulti={true}
      error={!!(formik.touched?.auth0Roles && formik.errors?.auth0Roles)}
      disableMultiSelectExpansion={disableMultiSelectExpansion}
    />
  )
}
