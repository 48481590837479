import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import { IngestAPIBridge } from './ingest'
import Portlet from 'components/base/portlet'
import { RingCentralAPIBridge } from './ringcentral'

class APIComponent extends PureComponent<Redux> {
  render() {
    const { id } = this.props.pages.business

    if (!id) {
      return <p>You can only view this page after creating your business</p>
    }

    return (
      <Portlet title="API Bridges">
        <IngestAPIBridge business={this.props.pages.business} />
        <hr />
        <RingCentralAPIBridge id={id} />
      </Portlet>
    )
  }
}

export default Connect(APIComponent)
