import { NestAPI } from '../../nestapi'
import { BusinessService } from './Business.types'
import {
  AttrWindowsBySourceType,
  DailyAttributionTotal,
  EventMapping,
  FBConnectedInstagramAccount,
  RepeatDataUpdate,
} from '@marketing-milk/interfaces'

export class BusinessServiceImplementation implements BusinessService {
  getInstagramPages(businessID: string | number): Promise<FBConnectedInstagramAccount[]> {
    return NestAPI.get(`businesses/${businessID}/fb-accounts/instagram`)
  }
  async setRepeatCheck(businessID: string | number, repeatConfig: RepeatDataUpdate): Promise<void> {
    return NestAPI.post(`businesses/${businessID}/repeat/update`, repeatConfig)
  }
  async getEventMappings(businessID: string | number): Promise<EventMapping[]> {
    return NestAPI.get(`businesses/${businessID}/event-mappings`)
  }
  async preloadBusinessData(businessID: string | number): Promise<void> {
    return NestAPI.post(`businesses/${businessID}/preload`)
  }
  async checkAttribution(
    businessID: string | number,
    funnelStageGroupID: number,
    start: string,
    end: string,
    attributionWindows: AttrWindowsBySourceType
  ): Promise<DailyAttributionTotal[]> {
    return NestAPI.get(`businesses/${businessID}/attribution`, {
      params: {
        start,
        end,
        funnelStageGroupID,
        attributionWindows,
      },
    })
  }
}
