import React from 'react'
import Select from 'react-select'
import Interface from '../../interface/input'

export default ({
  column,
  className = '',
  disabled = false,
  onChange,
  options,
  value,
  selector = {
    clearable: true,
    getOptionLabel: x => x.label,
    getOptionValue: x => x.value,
    value: 'value',
    isMulti: false,
  },
}: Interface) => {
  const doChange = (e: any) => {
    const value = selector.isMulti ? e : selector.value ? (e ? e[selector.value] : '') : e
    onChange(column, value)
  }

  return (
    <div>
      <Select
        className={className.replace('form-control', '')}
        isClearable={selector.clearable}
        isDisabled={disabled}
        isMulti={selector.isMulti}
        onChange={doChange}
        options={options}
        value={value}
        getOptionLabel={selector.getOptionLabel}
        getOptionValue={selector.getOptionValue}
      />
    </div>
  )
}
