import React, { PureComponent } from 'react'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import Input from 'components/base/form/input'
import BusinessActions from 'app/redux/actions/pages/business/basic'

class Indicators extends PureComponent<Redux> {
  render() {
    const { main } = this.props.pages.business
    return (
      <div>
        <div>
          <h4>Indicators</h4>
          <p>This will change the way the indicator is presented on the client dashboard.</p>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <Input
              column="pixel_indicator"
              value={main.pixel_indicator}
              text="Pixel Indicator Label"
              type="regular"
              onChange={BusinessActions.update}
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              column="pixel_indicator_desc"
              value={main.pixel_indicator_desc}
              text="Pixel Indicator Description"
              type="regular"
              onChange={BusinessActions.update}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <Input
              column="data_indicator"
              value={main.data_indicator}
              text="Data Indicator Label"
              type="regular"
              onChange={BusinessActions.update}
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              column="data_indicator_desc"
              value={main.data_indicator_desc}
              text="Data Indicator Description"
              type="regular"
              onChange={BusinessActions.update}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <Input
              column="upload_indicator"
              value={main.upload_indicator}
              text="Upload Indicator Label"
              type="regular"
              onChange={BusinessActions.update}
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              column="upload_indicator_desc"
              value={main.upload_indicator_desc}
              text="Upload Indicator Description"
              type="regular"
              onChange={BusinessActions.update}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Connect(Indicators)
