import React from 'react'
import Interface from './interface/label'

export default ({ error, optional, text }: Interface) => (
  <div>
    <span>
      {text}
      {optional ? <small className="ml-2">- Optional</small> : null}
    </span>
    {error ? (
      <span className="text-danger ml-2">
        <i className="fa fa-exclamation-triangle mr-2" />
        {error}
      </span>
    ) : null}
  </div>
)
