import React from 'react'
import Portlet from 'components/reusable/portlet'
import { BadDataCreateSubmissionModal } from './create-submission/CreateSubmissionModal'
import { BadDataPreviousSubmissionsTable } from './previous-submissions/BadDataPreviousSubmissions'

export function BadData() {
  return (
    <Portlet>
      <div className="row">
        <div className="col-6">
          <h4>Bad Data</h4>
        </div>
        <div className="col-6 text-right">
          <BadDataCreateSubmissionModal />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <BadDataPreviousSubmissionsTable />
        </div>
      </div>
    </Portlet>
  )
}
