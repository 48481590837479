import Store from 'app/redux/store'
import Redux from 'app/interface/redux'
import { bindActions } from 'redux-zero/utils'
import Route from 'app/interface/redux/types/core/route'

const Session = bindActions(
  () => ({
    init: (state: Redux, route: Route) => ({
      core: {
        ...state.core,
        route: route,
      },
    }),
  }),
  Store
)

export default Session
