import Select from 'react-select'
import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import { DataSource } from '@marketing-milk/interfaces'
import { dataSourceService } from 'app/service/data-source'
import { clientUploadService } from '@marketing-milk/frontend'
import { ChooseDataTypeProps, Option } from './ChooseDataType.types'

export function ChooseDataType({ upload }: ChooseDataTypeProps) {
  const [isSaving, setSaving] = useState(false)
  const [dataSources, setDataSources] = useState<DataSource[]>()

  useEffect(() => {
    async function fetchDataSources(): Promise<void> {
      const dataSources: DataSource[] = await dataSourceService.getAll()
      setDataSources(dataSources)
    }
    fetchDataSources()
  }, [upload])

  async function onChange(dataSourceID: number): Promise<void> {
    try {
      await clientUploadService.updateDataFormat(upload.businessID, upload.id, dataSourceID)
    } catch (e) {
      toast.error('A problem occurred and your changes could not be saved')
      throw e
    } finally {
      setSaving(false)
    }
  }

  if (!dataSources) {
    return <i className="fas fa-5x fa-spinner rotate text-brand" />
  }

  const selectOptions: Option[] = dataSources.map(x => ({
    value: x.id,
    label: (
      <>
        <i className={`fa fa=${x.icon} mr-2`} />
        {x.name}
      </>
    ),
  }))

  return (
    <Select
      options={selectOptions}
      value={selectOptions.find(x => x.value === upload?.dataSource.id) as any}
      onChange={x => onChange(x?.value ?? 0)}
      isDisabled={upload.locked || isSaving}
    />
  )
}
