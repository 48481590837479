import React from 'react'
import { Auth0RolePermission } from '@marketing-milk/interfaces'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

export interface PermissionsTableProps {
  permissions?: Auth0RolePermission[]
}

export function PermissionsTable({ permissions }: PermissionsTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Value</TableCell>
            <TableCell align="left">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions?.map(permission => (
            <TableRow key={permission.value}>
              <TableCell component="th" scope="row">
                {permission.value}
              </TableCell>
              <TableCell align="left">{permission.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
