import Select from 'react-select'
import { toast } from 'react-toastify'
import { UploadFileFormat } from '@marketing-milk/interfaces'
import React, { useState, useEffect, useContext } from 'react'
import { clientUploadService } from '@marketing-milk/frontend'
import { ChooseFormatProps, formatToText } from './ChooseFormat.types'
import { previousUploadsContext } from '../../../context/PreviousUploadsContext'

export function ChooseFormat({ upload, businessID }: ChooseFormatProps) {
  const [loading, setLoading] = useState(false)
  const { updateUpload } = useContext(previousUploadsContext)
  const [selectedFormat, setFormat] = useState({
    label: '',
    value: '' as UploadFileFormat,
  })

  useEffect(() => {
    if (upload) {
      const format = Object.entries(UploadFileFormat).filter(
        entry => entry[1] === upload!.format
      )[0]
      const label = formatToText[format[1]]
      const value = format[1]
      setFormat({ label, value })
    }
  }, [upload])

  async function handleChange(value): Promise<void> {
    try {
      setLoading(true)
      await clientUploadService.updateFile(businessID, upload!.id, value.value)
      updateUpload(upload.id, {
        format: value.value,
      })
    } catch (e) {
      toast.error('There was an error updating the file')
      throw e
    } finally {
      setLoading(false)
    }
  }

  const options = Object.entries(UploadFileFormat).map(entry => {
    return { label: formatToText[entry[1]], value: entry[1] }
  })
  return (
    <>
      {loading ? (
        <i className="fas fa-5x fa-spinner rotate text-brand" />
      ) : (
        <Select
          isDisabled={upload?.locked}
          options={options}
          value={selectedFormat}
          onChange={value => handleChange(value)}
        />
      )}
    </>
  )
}
