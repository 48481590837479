import { toast } from 'react-toastify'
import React, { useContext, useState } from 'react'
import { Dropzone } from '@marketing-milk/frontend'
import { CreateVersionProps } from './CreateVersion.types'
import { clientUploadService } from '@marketing-milk/frontend'
import { UserUploadVersion } from '@marketing-milk/interfaces'
import { previousUploadsContext } from '../../context/PreviousUploadsContext'

export function CreateVersion({ businessID, disabled, uploadID }: CreateVersionProps) {
  const [loading, setLoading] = useState(false)
  const { uploads, updateUpload } = useContext(previousUploadsContext)

  async function receiveFile(file: File): Promise<void> {
    try {
      setLoading(true)
      const newVersion: UserUploadVersion = await clientUploadService.uploadFileVersion(
        businessID,
        uploadID,
        file!
      )
      const uploadVersions = uploads.find(_ => _.id === Number(uploadID))!.versions
      updateUpload(Number(uploadID), {
        versions: [newVersion, ...uploadVersions],
      })
    } catch (e) {
      toast.error('Your data set could not be created at this time')
      throw e
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <h5 className="mt-4">Upload New Version</h5>
      {loading ? (
        <i className="fas fa-5x fa-spinner rotate text-brand" />
      ) : (
        <Dropzone
          allowedExtensions={['.csv']}
          className="upload-tile"
          disabled={disabled}
          handleUpload={receiveFile}
          maxFileSize={20_000_000}
          onRejectFile={() =>
            toast.error(`File must be an allowed file type: .csv and under 20mb in size`)
          }
        />
      )}
    </>
  )
}
