import React, { useEffect, useState } from 'react'
import { RingCentralService } from 'app/service/integrations/ringcentral/RingCentralService'
import {
  Badge,
  Button,
  createStyles,
  Grid,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'

export function RingCentralAPIBridge({ id }: { id: number }) {
  const [status, setStatus] = useState<'checking' | 'good' | 'bad'>('checking')
  const [clientID, setClientID] = useState<string>()
  const [clientSecret, setClientSecret] = useState<string>()

  const StyledBadge = withStyles(() =>
    createStyles({
      badge: {
        right: -15,
        top: 13,
        padding: '0 4px',
        backgroundColor: status === 'checking' ? 'gold' : status === 'good' ? 'green' : 'red',
      },
    })
  )(Badge)

  async function onSubmit() {
    setStatus('checking')
    try {
      const data = await RingCentralService.doAuth(id!, clientID!, clientSecret!)
      window.location.replace(data.url)
    } catch {
      setStatus('bad')
    }
  }

  useEffect(() => {
    async function fetchStatus(): Promise<void> {
      const status = await RingCentralService.getStatus(id)
      setStatus(status ? 'good' : 'bad')
    }
    fetchStatus()
  }, [id])

  return (
    <div>
      <Typography variant="h4">
        <StyledBadge color="secondary" variant="dot">
          RingCentral
        </StyledBadge>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            id="clientID"
            label="Client ID"
            fullWidth
            onChange={e => setClientID(e.target.value)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            id="clientSecret"
            label="Client Secret"
            fullWidth
            onChange={e => setClientSecret(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ marginTop: '1em' }}
            onClick={onSubmit}
            disabled={
              clientSecret && clientSecret.length > 0 && clientID && clientID?.length > 0
                ? false
                : true
            }
          >
            Authorize MarketingMilk
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
