import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import Router from 'components/utility/router'
import { ThemeProvider } from '@material-ui/core'
import Redux, { Connected } from 'app/interface/redux'
import { useAppTheme } from '@marketing-milk/frontend'
import ErrorActions from 'app/redux/actions/core/errors'
import SessionActions from 'app/redux/actions/core/session'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Permissions } from '@marketing-milk/interfaces'

class Builder extends PureComponent<Connected> {
  componentDidMount() {
    SessionActions.init()
  }

  componentDidUpdate(props) {
    if (props.location.pathname !== this.props.location.pathname) {
      ErrorActions.clear()
    }
  }

  render() {
    // Theme needs to stay static 'light' theme because if user has dark mode
    // enabled from Client, only material components will have dark mode styles.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useAppTheme('light')
    const { ready, user } = this.props.core.session

    return (
      <ThemeProvider theme={theme}>
        {user && !user.permissions.includes(Permissions.AccessAdminApp) ? (
          <Unauthorized />
        ) : ready ? (
          <Router />
        ) : (
          <Building />
        )}
      </ThemeProvider>
    )
  }
}

const Unauthorized = () => {
  const { isAuthenticated, logout } = useAuth0()

  const triggerLogout = () => {
    const redirectURL = 'https://www.marketingmilk.com/'

    // Check if the user logged in via Auth0
    // If so, log them out of Auth0, in addition
    // to clearing out their Marketing Milk session
    if (isAuthenticated)
      logout({
        returnTo: redirectURL,
      })

    SessionActions.logout()
    return <Redirect to={redirectURL} />
  }

  return (
    <div className="loading m-grid__item m-grid__item--fluid m-grid  m-error-1">
      <div className="m-error_container">
        <span className="m-error_number">
          <h1 className="text-admin">Hi,</h1>
        </span>
        <div className="m-error_desc">
          You are not authorized to view this page. To login with another account, click{' '}
          <a onClick={triggerLogout}>here</a>
        </div>
      </div>
    </div>
  )
}

const Building = () => (
  <div className="loading m-grid__item m-grid__item--fluid m-grid  m-error-1">
    <div className="m-error_container">
      <span className="m-error_number">
        <h1 className="text-admin">Hi,</h1>
      </span>
      <div className="m-error_desc">
        Please wait as we prepare your experience.
        <div
          className="loader"
          style={{ fontSize: '1.4em', marginLeft: '10%', marginTop: '-50px' }}
        />
      </div>
    </div>
  </div>
)

const map = (state: Redux) => ({
  core: {
    session: state.core.session,
  },
})

export default Connect(Builder, map)
