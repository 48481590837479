import Pods from './pods'
import Users from './users'
import Branches from './branches'
import Businesses from './businesses'
import Generic from 'app/interface/redux/types/data/generic'

const Default: Generic = {
  pods: Pods,
  users: Users,
  branches: Branches,
  businesses: Businesses,
}

export default Default
