import React from 'react'
import { BadUploadList } from '../../components/bad-upload-table/BadUploadList'
import { SelectDateRange } from '../3-what-date-range/select-date-range/SelectDateRange'
import { InputReportName } from '../1-describe-the-issue/input-report-name/InputReportName'
import { InputReportDesc } from '../1-describe-the-issue/input-report-desc/InputReportDesc'
import { SelectOfflineEvent } from '../2-what-offline-event/select-offline-event/SelectOfflineEvent'

export function ReviewYourReport() {
  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <h4>Review Your Report</h4>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h5>Report Name</h5>
          <InputReportName />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h5>Report Desc</h5>
          <InputReportDesc />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h5>Offline Event</h5>
          <SelectOfflineEvent />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h5>Date Range</h5>
          <SelectDateRange defaultFocus={null} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <BadUploadList />
        </div>
      </div>
    </>
  )
}
