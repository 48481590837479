import Select from 'react-select'
import { toast } from 'react-toastify'
import { EventName } from '@marketing-milk/interfaces'
import { clientUploadService } from '@marketing-milk/frontend'
import React, { useContext, useEffect, useState } from 'react'
import { previousUploadsContext } from '../../../context/PreviousUploadsContext'
import {
  defaultChooseEventNameState,
  ChooseEventNameState,
  ChooseEventNameProps,
  Option,
} from './ChooseEventName.types'

export function ChooseEventName({ upload }: ChooseEventNameProps) {
  const { updateUpload } = useContext(previousUploadsContext)
  const [state, setState] = useState<ChooseEventNameState>(defaultChooseEventNameState)

  useEffect(() => {
    setState({
      disableSelect: !!upload?.locked,
    })
  }, [upload])

  function disableSelect(disableSelect: boolean): void {
    setState(prevState => ({
      ...prevState,
      disableSelect,
    }))
  }

  async function onChange(eventName: EventName): Promise<void> {
    if (upload === undefined) {
      return
    }

    try {
      disableSelect(true)
      await clientUploadService.updateEventName(upload.businessID, upload.id, eventName)
      disableSelect(false)
      updateUpload(upload.id!, { eventName })
    } catch {
      disableSelect(true)
      toast.error('A problem occurred and your changes could not be saved')
    }
  }

  const selectOptions: Option[] = Object.values(EventName).map(x => ({
    value: x,
    label: x,
  }))
  return (
    <Select
      options={selectOptions}
      value={selectOptions.find(x => x.value === upload?.eventName) as any}
      onChange={x => onChange(x?.value ?? ('' as EventName))}
      isDisabled={state.disableSelect}
    />
  )
}
