import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { UserUpload } from '@marketing-milk/interfaces'
import { clientUploadService } from '@marketing-milk/frontend'
import { previousUploadsContext } from './PreviousUploadsContext'
import { PreviousUploadsContextProps } from './PreviousUploadsContext.types'

export function PreviousUploadsContextProvider({ children }: PreviousUploadsContextProps) {
  const params: { business: string } = useParams()
  const [uploads, setUploads] = useState<UserUpload[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchBusiness()
  }, [params.business])

  async function fetchBusiness() {
    try {
      const uploads = params.business ? await clientUploadService.getFiles(params.business) : []
      setUploads(uploads)
    } catch (e) {
      toast.error('There was a problem fetching uploads for this business')
      throw e
    } finally {
      setLoading(false)
    }
  }

  function deleteUpload(uploadID: number) {
    setUploads(_ => {
      return _.filter(upload => upload.id !== uploadID)
    })
  }

  function updateUpload(uploadID: number, changes: Partial<UserUpload>) {
    setUploads(_ => {
      const newUploads = [..._]
      const uploadIndex = newUploads.findIndex(upload => upload.id === uploadID)!
      newUploads[uploadIndex] = {
        ...newUploads[uploadIndex],
        ...changes,
      }
      return newUploads
    })
  }

  return (
    <previousUploadsContext.Provider value={{ uploads, loading, deleteUpload, updateUpload }}>
      {children}
    </previousUploadsContext.Provider>
  )
}
