import React from 'react'
import Toggle from 'react-switch'
import Interface from '../../interface/input'

export default ({ column = '', className = '', onChange, value = false }: Interface) => (
  <Toggle
    className={className.replace('form-control', '')}
    checked={!!value}
    onChange={e => onChange(column, e)}
  />
)
