import React from 'react'
import { IWizardComponent } from '../'

export default ({ active, setActive, steps }: IWizardComponent) => {
  const current = steps[active]
  return (
    <div className="kt-wizard-v4">
      <div className="kt-wizard-v4__nav">
        <div className="kt-wizard-v4__nav-items">
          {steps.map((step, i: number) => (
            <button
              className="kt-wizard-v4__nav-item navigation-link"
              key={i}
              data-ktwizard-type="step"
              data-ktwizard-state={active === i ? 'current' : 'step'}
              onClick={() => setActive(i)}
            >
              <div className="kt-wizard-v4__nav-body">
                <div className="kt-wizard-v4__nav-label">
                  <div className="kt-wizard-v4__nav-label-title">{step.text}</div>
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
      <div
        className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper"
        style={{ padding: 5 }}
      >
        {current ? current.component : null}
      </div>
    </div>
  )
}
