import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'

const Funnel = bindActions(
  store => ({
    setValue: (state, key: string, value: string) => {
      const { active } = state.pages.business.funnel
      let stages: any = [].concat(state.pages.business.funnel.stages)
      stages[active][key] = value
      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            funnel: {
              ...state.pages.business.funnel,
              stages: stages,
            },
          },
        },
      })
    },

    addGroup: (state: any) => {
      const { active } = state.pages.business.funnel
      let stages: any = [].concat(state.pages.business.funnel.stages)

      stages[active].groups.push({
        name: 'Data Source',
        desc: 'I am a new data source',
        sources: [],
      })

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            funnel: {
              ...state.pages.business.funnel,
              stages: stages,
            },
          },
        },
      })
    },

    editGroup: (state: any, index: number, column: string, value: any) => {
      const { active } = state.pages.business.funnel
      let stages: any = [].concat(state.pages.business.funnel.stages)

      stages[active].groups[index][column] = value

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            funnel: {
              ...state.pages.business.funnel,
              stages: stages,
            },
          },
        },
      })
    },

    delGroup: (state: any, index: number) => {
      const { active } = state.pages.business.funnel
      let stages: any = [].concat(state.pages.business.funnel.stages)

      stages[active].groups.splice(index, 1)

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            funnel: {
              ...state.pages.business.funnel,
              stages: stages,
            },
          },
        },
      })
    },

    clearStage: state => {
      let { active, preset } = state.pages.business.funnel
      let stages = [].concat(state.pages.business.funnel.stages)

      stages.splice(active, 1)

      if (stages.length === 0) {
        preset = 0
      }

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            funnel: {
              ...state.pages.business.funnel,
              active: null,
              stages: stages,
              preset: preset,
            },
          },
        },
      })
    },
  }),
  Store
)

export default Funnel
