import Select from 'react-select'
import { toast } from 'react-toastify'
import React, { useState } from 'react'
import * as Moment from 'moment-timezone'
import { clientUploadService } from '@marketing-milk/frontend'
import { ChooseTimezoneProps, Option } from './ChooseTimezone.types'

export function ChooseTimezone({ upload }: ChooseTimezoneProps) {
  const [loading, setLoading] = useState(false)

  async function onChange(tz: string): Promise<void> {
    try {
      setLoading(true)
      await clientUploadService.updateTimezone(upload.businessID, upload.id, tz)
    } catch (e) {
      toast.error('A problem occurred and your changes could not be saved')
      throw e
    } finally {
      setLoading(false)
    }
  }

  const selectOptions: Option[] = Moment.tz.names().map(x => ({
    value: x,
    label: x,
  }))

  return (
    <Select
      options={selectOptions}
      value={selectOptions.find(x => x.value === upload?.timezone) as any}
      onChange={x => onChange(x?.value ?? '')}
      isDisabled={upload.locked || loading}
      isLoading={loading}
    />
  )
}
