import Logout from './logout'
import Dashboard from './dashboard'
import InvalidUser from './InvalidUser'
import NetworkError from './NetworkError'
import Login from './Login'

export default {
  Login,
  InvalidUser,
  NetworkError,
  Logout,
  Dashboard,
}
