import Connect from 'app/redux/connect'
import React, { useContext, useEffect } from 'react'
import SessionActions from 'app/redux/actions/core/session'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { AUTH0_AUDIENCE } from '../../const'
import { sessionContext, userService } from '@marketing-milk/frontend'
import { Paper } from '@material-ui/core'
import jwt_decode from 'jwt-decode'

const Login = (props: any) => {
  const { session } = props.core
  const mmSessionContext = useContext(sessionContext)
  const { loginWithRedirect, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const history = useHistory()
  const isLoggingIn = session.active || isAuthenticated

  useEffect(() => {
    if (!isLoading) {
      const startSession = async () => {
        const token = await getAccessTokenSilently({
          audience: AUTH0_AUDIENCE,
          scope: 'read:current_user',
        })
        try {
          await SessionActions.auth0LoginWithToken(token)
          mmSessionContext.loginWithToken(token)
          await userService.syncAuth0Access()

          const { permissions } = jwt_decode(token) as { permissions: string[] }

          // check if permissions have been set,
          // if not, this may be a new provider login
          // the backend will have updated the ROLES for this user at this point
          // and we will need to attempt to get the permissions again
          if (permissions.length === 0) {
            const token = await getAccessTokenSilently({
              audience: AUTH0_AUDIENCE,
              scope: 'read:current_user',
              ignoreCache: true,
            })

            SessionActions.reupToken(token)
          }
        } catch (e: any) {
          if (e.message === 'Network Error') {
            history.push('/admin/network_error')
          } else {
            history.push('/admin/invalid_user')
          }
        }
      }

      const auth0Login = async () => {
        loginWithRedirect({
          audience: AUTH0_AUDIENCE,
          scope: 'read:current_user',
          redirectUri: window.location.origin + '/admin/login',
        })
      }

      const e2eLogin = async () => {
        const e2eAuthToken = JSON.parse(localStorage.getItem('auth0Cypress')!)
        await SessionActions.loginWithToken(e2eAuthToken.body.access_token)
        await userService.syncAuth0Access()
      }

      if (session.active) {
        history.push('/admin')
        // @ts-expect-error - window.Cypress is inited when we run our Cypress tests
      } else if (window.Cypress) {
        e2eLogin()
      } else if (isAuthenticated) {
        startSession()
      } else {
        auth0Login()
      }
    }
  }, [isAuthenticated, isLoading, session])

  return (
    <div className="mm-login">
      <img alt="logo" src="/assets/admin/img/logo.png" />
      <Paper className="text-center pt-3 pb-5 m-4">
        <p className="font-weight-bold">
          {isLoggingIn ? 'Logging in' : 'Redirecting to Login'}&nbsp;&nbsp;
          <i className="fas fa-spinner fa-spin"></i>
        </p>
      </Paper>
    </div>
  )
}

export default Connect(Login)
