import { LeadWebhookFormat, Pod, User } from '@marketing-milk/interfaces'
import Currency from 'app/interface/common/currency'
import Redux from 'app/interface/redux'
import { currencyTypes, locales } from 'app/lib/local'
import Timezones from 'app/lib/timezones.json'
import Basic from 'app/redux/actions/pages/business/basic'
import Connect from 'app/redux/connect'
import Form from 'components/base/form'
import Input from 'components/base/form/interface/input'
import Loading from 'components/message/loading'
import Popover from 'components/reusable/popover'
import React from 'react'
import BusinessAuthorizer from './businessAuthorizer'
import { PlaceType1 } from '@googlemaps/google-maps-services-js'
import { capitalCase } from 'change-case'

export declare type BusinessTypeOption = {
  label: string
  value: PlaceType1
}

const LEAD_WEBHOOK_OPTIONS: Array<{
  value: LeadWebhookFormat
  label: string
}> = [
  {
    value: 'off',
    label: 'Disabled',
  },
  {
    value: 'otf',
    label: 'Orangebook Integrated',
  },
  {
    value: 'manduu',
    label: 'Manduu Integrated',
  },
  {
    value: 'hotworx',
    label: 'Hotworx Integrated',
  },
]

interface Props extends Redux {
  new: boolean
}

const Overview = (props: Props) => {
  const onChange = (key: string, value: any): void => Basic.update(key, value)
  const { main } = props.pages.business
  const { branches, pods } = props.data.generic
  const currencies: any[] = Object.entries(currencyTypes).map((type: [string, Currency]) => [
    type[1].name,
    type[1].code,
  ])
  if (main.start.length > 10) {
    main.start = main.start.slice(0, -9)
    Basic.update('start', main.start)
  }

  return branches.loading && pods.loading ? (
    <Loading />
  ) : (
    <>
      <Form
        save={false}
        onChange={Basic.update}
        onSubmit={() => {}}
        groups={[
          {
            header: 'Overview',
            rows: [
              {
                column: 6,
                fields: [
                  {
                    column: 'business_name',
                    text: 'Business Name',
                    type: 'regular',
                    value: main.business_name,
                  } as Input,
                  {
                    column: 'website',
                    text: 'Website URL',
                    type: 'regular',
                    value: main.website,
                  } as Input,
                ],
              },
              {
                column: 6,
                fields: [
                  {
                    column: 'address',
                    text: 'Address',
                    type: 'regular',
                    value: main.address,
                  } as Input,
                  {
                    column: 'google_type',
                    text: 'Type',
                    type: 'regular',
                    other: {
                      regular: true,
                      datalist: {
                        options: (Object.keys(PlaceType1) as Array<string>).map(place =>
                          capitalCase(place)
                        ),
                        id: 'google_type_options',
                      },
                    },
                    value: capitalCase(main.google_type || ''),
                  } as Input,
                ],
              },
              {
                column: 6,
                fields: [
                  {
                    column: 'branch_id',
                    text: 'Branch',
                    type: 'select',
                    options: branches.data.map(x => ({
                      ...x,
                      options: undefined,
                    })),
                    selector: {
                      getOptionLabel: x => x.branchName,
                      getOptionValue: x => x.id,
                      value: 'id',
                    },
                    value: branches.data.find(x => x.id === main.branch_id) || {},
                    onChange,
                  } as Input,
                  {
                    column: 'pod_id',
                    disabled: !main.branch_id,
                    text: 'Pod',
                    type: 'select',
                    options: pods.data.filter(x => x.branchID === main.branch_id),
                    selector: {
                      getOptionLabel: x => x.podName,
                      getOptionValue: x => x.id,
                      value: 'id',
                    },
                    value: pods.data.find(x => x.id === main.pod_id) || {},
                    onChange,
                  } as Input,
                ],
              },
              {
                column: 6,
                fields: [
                  {
                    column: 'timezone',
                    text: 'Timezone',
                    type: 'select_simple',
                    options: Timezones,
                    value: main.timezone,
                    onChange,
                  } as Input,
                  {
                    column: 'start',
                    text: 'Start Date',
                    type: 'date',
                    value: main.start,
                  } as Input,
                ],
              },
              {
                column: 6,
                fields: [
                  {
                    column: 'city',
                    text: 'City',
                    type: 'regular',
                    value: main.city,
                    onChange,
                  } as Input,
                  {
                    column: 'state',
                    text: 'State',
                    type: 'regular',
                    value: main.state,
                    onChange,
                  } as Input,
                ],
              },
              {
                column: 6,
                fields: [
                  {
                    column: 'currency_type',
                    text: (
                      <span>
                        Currency Type{' '}
                        <Popover classes={'text-danger'}>
                          Once set, this cannot be edited. This is done to avoid issues with
                          conflicting currencies from different data sources. All external accounts
                          associated with this business MUST be set to the same currency type.
                        </Popover>
                      </span>
                    ),
                    type: 'select_simple',
                    options: currencies.map(entry => entry[1]),
                    value: main.currency_type,
                    onChange,
                    disabled: !props.new,
                  } as Input,
                  {
                    column: 'locale',
                    text: 'Locale',
                    type: 'select_simple',
                    options: locales,
                    value: main.locale,
                    onChange,
                  } as Input,
                ],
              },
              {
                column: 6,
                fields: [
                  {
                    column: 'lead_webhook_format',
                    text: 'Lead Integration',
                    type: 'select',
                    options: LEAD_WEBHOOK_OPTIONS,
                    selector: {
                      getOptionLabel: x => x.label,
                      getOptionValue: x => x.value,
                      value: 'value',
                    },
                    value: LEAD_WEBHOOK_OPTIONS.find(x => x.value === main.lead_webhook_format),
                    onChange,
                  },
                ],
              },
            ],
          },
        ]}
      />

      <h4 className="px-4">
        <i className="fab fa-facebook"></i>&nbsp;Facebook Authorizers
      </h4>
      <div className="row px-3">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <BusinessAuthorizer
            title={'Ad Account Authorizer'}
            business={props.pages.business}
            authorizerID={main.fb_ad_authorizer_id}
            assignAuthorizer={(authorizer: User) => {
              onChange('fb_ad_authorizer_id', authorizer.id)
            }}
            popover={
              'Facebook Ad Account Authorizers must be added to the Facebook Ad Account as an Account Admin'
            }
          />
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <BusinessAuthorizer
            title={'Page Authorizer'}
            business={props.pages.business}
            authorizerID={main.fb_page_authorizer_id}
            assignAuthorizer={(authorizer: User) => {
              onChange('fb_page_authorizer_id', authorizer.id)
            }}
            popover={
              'Facebook Page Authorizers must be added to the Facebook Page as a Page Admin and have Default Leads Access'
            }
          />
        </div>
      </div>
    </>
  )
}

export const Standalone = Overview

export default Connect(Overview)
