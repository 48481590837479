import React from 'react'
import { OwnershipGroupDelete } from './OwnershipGroupDelete'
import { useFetchAllOwnershipGroups } from '../../hooks/ownershipGroupHooks'
import { useDebounce } from '../../hooks'
import { PageHeader } from '../user-management/PageHeader'
import { OwnershipGroupCreate } from './OwnershipGroupCreate'
import { useHistory } from 'react-router-dom'
import { GridCellParams, GridColDef, GridColumnTypesRecord } from '@material-ui/x-grid'
import { OwnershipGroup } from '@marketing-milk/interfaces'
import { MaterialDataTable } from '@marketing-milk/frontend'

enum OwnershipGroupTableTypes {
  Name = 'Name',
  Actions = 'Actions',
}

const ownershipGroupColTypes = (
  history: ReturnType<typeof useHistory>,
  resetTableState: () => void
): GridColumnTypesRecord => {
  return {
    [OwnershipGroupTableTypes.Name]: {
      type: 'string',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 4,
      renderCell: ({ row: data }: GridCellParams) => {
        const row = data as OwnershipGroup
        return (
          <div
            style={{
              display: 'contents',
              cursor: 'pointer',
            }}
            onClick={() => history.push(`${row.id}/edit`)}
          >
            {row.name}
          </div>
        )
      },
    },
    [OwnershipGroupTableTypes.Actions]: {
      type: 'button',
      headerName: '',
      disableColumnMenu: true,
      flex: 1,
      resizable: false,
      renderCell: ({ row }: GridCellParams) => {
        const ownershipGroup = row as OwnershipGroup
        return <OwnershipGroupDelete ownershipGroup={ownershipGroup} onDelete={resetTableState} />
      },
    },
  }
}

const ownershipGroupTableColumns: GridColDef[] = [
  {
    field: 'name',
    type: OwnershipGroupTableTypes.Name,
    headerName: 'Name',
  },
  {
    field: '',
    type: OwnershipGroupTableTypes.Actions,
    headerName: 'Actions',
  },
]

interface OwnershipGroupListProps {}
export function OwnershipGroupList(props: OwnershipGroupListProps) {
  const {} = props
  const routerHistory = useHistory()
  const fetchOwnershipGroups = useFetchAllOwnershipGroups()
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const { isDebouncing, debouncedValue } = useDebounce(searchTerm, 1000)

  React.useEffect(() => {
    fetchOwnershipGroups.setPaginationParams(prevParams => ({
      ...prevParams,
      searchTerm: debouncedValue.trim().toLowerCase(),
      page: 0,
    }))
  }, [debouncedValue])

  const resetTableState = () => {
    fetchOwnershipGroups.refetch()
    setSearchTerm('')
    fetchOwnershipGroups.setPaginationParams(prevParams => ({
      ...prevParams,
      searchTerm: '',
      page: 0,
    }))
  }

  return (
    <>
      <PageHeader
        title="Ownership Groups"
        filterValue={searchTerm}
        onChangeFilter={value => {
          setSearchTerm(value)
        }}
      >
        <div style={{ flex: 'none' }}>
          <OwnershipGroupCreate onSuccess={resetTableState} />
        </div>
      </PageHeader>

      <div>
        <MaterialDataTable
          columns={ownershipGroupTableColumns}
          columnTypes={ownershipGroupColTypes(routerHistory, resetTableState)}
          rows={fetchOwnershipGroups?.ownershipGroups ?? []}
          pagination
          pageSize={fetchOwnershipGroups.pageSize}
          page={fetchOwnershipGroups.page}
          paginationMode={'server'}
          onPageChange={change => {
            fetchOwnershipGroups.setPaginationParams(prevParams => ({
              ...prevParams,
              page: change.page + 1,
            }))
          }}
          rowCount={fetchOwnershipGroups.count}
          height={'calc(100vh - 15rem)'}
          density="comfortable"
        />
      </div>
    </>
  )
}
