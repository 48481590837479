import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { badDataService } from '@marketing-milk/frontend'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { CreateBadDataSubmission } from '@marketing-milk/interfaces'
import { BadDataSubmissionWizard } from './submission-wizard/SubmissionWizard'

export function BadDataCreateSubmissionModal() {
  const params: { business: string } = useParams()
  const [isOpen, setModal] = useState(false)

  function toggle() {
    setModal(_ => !_)
  }

  async function onPostSubmission(submissionDTO: CreateBadDataSubmission) {
    await badDataService.createSubmission(Number(params.business), submissionDTO)
    toast.info('Your bad data report has been submitted and is pending approval')
    setModal(false)
  }

  return (
    <>
      <Button color="primary" variant="contained" onClick={toggle}>
        Report Bad Data
      </Button>
      {isOpen && (
        <Modal isOpen size="xl" toggle={toggle}>
          <ModalHeader>Report Bad Data</ModalHeader>
          <ModalBody>
            <BadDataSubmissionWizard onSubmit={onPostSubmission} />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}
