import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import SessionActions from 'app/redux/actions/core/session'

const Logout = () => {
  const { logout, isAuthenticated } = useAuth0()
  const redirectURL = 'https://www.marketingmilk.com/'

  // Check if the user logged in via Auth0
  // If so, log them out of Auth0, in addition
  // to clearing out their Marketing Milk session
  if (isAuthenticated)
    logout({
      returnTo: redirectURL,
    })

  SessionActions.logout()
  return <Redirect to={redirectURL} />
}

export default Logout
