import { Divider, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { UserWithBusiness } from '@marketing-milk/interfaces'
import { AssignRoles } from '../modals/AssignRoles'
import { Delete } from '../modals/Delete'
import { useHistory } from 'react-router-dom'
import { userService } from '@marketing-milk/frontend'
import { toast } from 'react-toastify'
import { ResendWelcomeEmailModal } from '../modals/ResendWelcomeEmail'
import { NestAPI } from '../../../../app/nestapi'
import { add } from 'date-fns'

type TableMenuProps = {
  mmUser?: UserWithBusiness
  refetchUsers: () => void
  canManageRoles: boolean
}

export function TableMenu({ mmUser, refetchUsers, canManageRoles }: TableMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [assignView, setAssignView] = useState(false)
  const [showResend, setResendModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [shouldWarn, setShouldWarn] = useState(false)

  const history = useHistory()
  const open = Boolean(anchorEl)

  function toggleModal() {
    setResendModal(_ => !_)
  }
  const awaitingResendRequestHandler = async () => {
    const response = (await NestAPI.patch(`users/${mmUser?.id}/resend-invite`, undefined)) as any
    response.errors.forEach(error => {
      toast.error(error.description)
    })

    if (response.data.data) {
      toast.success(response.data.data)
      if (!shouldWarn) {
        setShouldWarn(true)
      }
    }
  }
  useEffect(() => {
    if (mmUser?.inviteSentTime && add(new Date(mmUser.inviteSentTime), { days: 1 }) > new Date()) {
      setShouldWarn(true)
    }
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAssignView(false)
    setDeleteModal(false)
  }

  const clickAssignRoles = () => {
    refetchUsers()
    handleClose()
  }

  const sendWelcomeEmailBehaviour = () => {
    if (shouldWarn) {
      setResendModal(_ => !_)
    } else {
      awaitingResendRequestHandler()
    }
  }

  const clickDelete = async (user: UserWithBusiness | undefined) => {
    if (user) {
      try {
        await userService.deleteByID(user?.id)

        toast.success('Successfully deleted user from Marketing Milk.')
        refetchUsers()
        handleClose()
      } catch (e) {
        toast.error(
          'There was a problem deleting this user. Please try again later. If the problem persists contact support.'
        )
      }
    }
  }

  return (
    <>
      <IconButton
        id="user-dropdown-button"
        data-testid="user-table-menu"
        aria-controls={open ? 'roles-menu' : undefined}
        aria-haspopup={true}
        aria-expanded={open ? true : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="roles-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'roles-dropdown-button',
        }}
      >
        <MenuItem onClick={() => history.push(`/admin/users/${mmUser?.id}/details`)}>
          View Details
        </MenuItem>
        {canManageRoles && (
          <MenuItem
            onClick={e => {
              e.preventDefault()
              setAnchorEl(null)
              setAssignView(true)
            }}
          >
            Assign Roles
          </MenuItem>
        )}
        {!mmUser?.acceptedInvite && (
          <MenuItem
            onClick={e => {
              e.preventDefault()
              setAnchorEl(null)
              sendWelcomeEmailBehaviour()
            }}
          >
            Resend Welcome Email
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          className="text-danger"
          data-testid="delete-user-"
          onClick={e => {
            e.preventDefault()
            setAnchorEl(null)
            setDeleteModal(true)
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      {/* Delete Modal for menu */}
      {deleteModal && (
        <Delete
          title={<Typography style={{ fontSize: 20 }}>Remove User Account?</Typography>}
          body={
            <>
              <Typography style={{ fontSize: 15, paddingBottom: 15 }}>
                You are about to <span className="text-danger font-weight-bold">delete</span> the
                user account for
                <span
                  style={{ fontWeight: 'bold' }}
                >{` ${mmUser?.firstName} ${mmUser?.lastName}.`}</span>
              </Typography>

              <Typography variant="subtitle1">
                Upon clicking "Delete", their account will be deleted and their access to Marketing
                Milk will be revoked.
              </Typography>
            </>
          }
          onClose={() => {
            setDeleteModal(false)
          }}
          withButton={false}
          onSubmitted={clickDelete}
          item={mmUser}
          openModal={deleteModal}
          maxWidth={'sm'}
        />
      )}

      {/* Assign Roles Modal for Menu */}
      {assignView && (
        <AssignRoles
          mmUser={mmUser}
          onSave={clickAssignRoles}
          withButton={false}
          openModal={assignView}
          onExit={() => setAssignView(false)}
          onClose={() => setAssignView(false)}
        />
      )}

      {shouldWarn ? (
        <ResendWelcomeEmailModal
          showModal={showResend}
          closeModal={toggleModal}
          onResendRequest={awaitingResendRequestHandler}
        />
      ) : (
        <></>
      )}
    </>
  )
}
