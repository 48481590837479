import React, { useState, useEffect } from 'react'
import { uploadSubmissionService } from '../const'
import Loading from 'components/message/loading'
import Portlet from 'components/reusable/portlet'
import './SubmissionList.scss'
import { UploadSubmission } from '@marketing-milk/interfaces'
import { SubmissionTable } from './table'
import { SubmissionsListHelp } from './help/SubmissionsListHelp'

export interface ListState {
  submissions: UploadSubmission[]
  pending: boolean
  count: number
  page: number
  sortDirection: 'ASC' | 'DESC'
}
export function SubmissionList({ service = uploadSubmissionService }) {
  const [data, setData] = useState<ListState>({
    submissions: [],
    pending: true,
    count: 0,
    page: 1,
    sortDirection: 'ASC',
  })
  const [loading, setLoading] = useState(false)
  const { submissions, pending, count, page, sortDirection } = data

  useEffect(() => {
    ;(async function getSubmissions(): Promise<void> {
      setLoading(true)
      const { submissions, count } = await service.getSubmissions({
        pending,
        page,
        pageSize: 10,
        sortDirection,
      })
      setData(prevState => ({ ...prevState, submissions, count }))
      setLoading(false)
    })()
  }, [service, page, pending, sortDirection])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Portlet title=" " headTools={<SubmissionsListHelp />}>
        <div className="row mb-3">
          <div
            className={`col-6 text-center cursor-pointer w-title w-${pending}`}
            onClick={() =>
              setData({ ...data, pending: true, page: 1, count: 0, sortDirection: 'ASC' })
            }
          >
            <h5 className={pending ? 'text-brand' : ''}>Pending</h5>
          </div>
          <div
            className={`col-6 text-center cursor-pointer w-title w-${!pending}`}
            onClick={() =>
              setData({ ...data, pending: false, page: 1, count: 0, sortDirection: 'DESC' })
            }
          >
            <h5 className={!pending ? 'text-brand' : ''}>Closed</h5>
          </div>
        </div>

        <SubmissionTable
          submissions={submissions}
          count={count}
          showPending={pending}
          page={page}
          pageSize={10}
          changePage={(page: number) => setData(prev => ({ ...prev, page }))}
        />
      </Portlet>
    </>
  )
}
