import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import BusinessActions from 'app/redux/actions/pages/business/basic'

class Phase extends PureComponent<Redux> {
  isPhase = phase => {
    if (this.props.pages.business.main.phase === phase) {
      return 'btn-phase-active'
    } else {
      return ''
    }
  }

  setPhase = event => {
    event.preventDefault()
    BusinessActions.update('phase', parseInt(event.target.name))
  }

  render() {
    return (
      <div>
        <h4 style={{ color: '#575962', fontSize: '1.3rem' }}>Phase</h4>
        <div className="row" style={{ padding: '.65rem 1rem' }}>
          <button
            className={`col-md-3 btn btn-phase ${this.isPhase(1)}`}
            name="1"
            onClick={this.setPhase}
          >
            Crawl
          </button>
          <button
            className={`col-md-3 btn btn-phase ${this.isPhase(2)}`}
            name="2"
            onClick={this.setPhase}
          >
            Walk
          </button>
          <button
            className={`col-md-3 btn btn-phase ${this.isPhase(3)}`}
            name="3"
            onClick={this.setPhase}
          >
            Run
          </button>
          <button
            className={`col-md-3 btn btn-phase ${this.isPhase(4)}`}
            name="4"
            onClick={this.setPhase}
          >
            Fly
          </button>
        </div>
      </div>
    )
  }
}

export default Connect(Phase)
