import * as Yup from 'yup'
import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { PodWizardProps } from './PodWizard.types'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Button,
  FormHelperText,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  Grid,
  FormControl,
  TextField,
  DialogActions,
  Select,
  NativeSelect,
  InputLabel,
  TextareaAutosize,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  Avatar,
} from '@material-ui/core'
import { GridCloseIcon } from '@material-ui/x-grid'
import { grey } from '@material-ui/core/colors'
import { PodConfigDTO } from '@marketing-milk/interfaces'
import { orderBy } from 'lodash'

export const FieldError = (props: { name: string; className?: string }) => (
  <ErrorMessage name={props.name}>
    {msg => (
      <FormHelperText className={props.className ?? ''} error aria-label="error text">
        {msg}
      </FormHelperText>
    )}
  </ErrorMessage>
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      minHeight: '80vh',
      maxHeight: '80vh',
    },
    formControl: {
      marginTop: theme.spacing(1),
      minWidth: 120,
    },
    section: {
      marginTop: theme.spacing(2),
    },
    subtitle2: {
      color: grey[500],
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      margin: 2,
    },
  })
)

export function PodWizard({
  children,
  pod,
  size,
  variant,
  color,
  header,
  branches,
  onSave,
  users,
  businesses,
}: PodWizardProps) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const clientFilteredUsers = users?.filter(
    user => user.assignedRoles.length && !user.assignedRoles.map(_ => _.name).includes('Client')
  )
  const sortedUsers = orderBy(clientFilteredUsers, [user => user.firstName.toLowerCase()], ['asc'])

  const handleDialogOpen = () => {
    setOpen(true)
  }
  const handleDialogClose = () => {
    setOpen(false)
  }

  const podValidationSchema = Yup.object().shape({
    podName: Yup.string().min(3, 'Too short!').max(50, 'Too long!').required('Required'),
    podNumber: Yup.number().required('Required'),
    branchID: Yup.number().required('Required'),
  })

  return (
    <>
      <Button
        size={size}
        variant={variant}
        color={color}
        onClick={handleDialogOpen}
        data-testid="pod-wizard-toggler"
      >
        {children}
      </Button>
      {open && (
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={open}
          onClose={handleDialogClose}
          scroll="paper"
          maxWidth="md"
          fullWidth={true}
        >
          <Formik
            initialValues={pod}
            validationSchema={podValidationSchema}
            onSubmit={async (values: PodConfigDTO) => {
              try {
                values.googleConfig =
                  typeof values.googleConfig === 'string'
                    ? JSON.parse(values.googleConfig)
                    : values.googleConfig
                await onSave(values)
                toast.success('Submission successful')
                handleDialogClose()
              } catch (e) {
                const error = e as any
                const message = error.response.data.message
                toast.error(
                  `There was a problem saving your pod: ${
                    typeof message === 'string' ? message : error
                  }`
                )
              } finally {
              }
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => {
              return (
                <>
                  <DialogTitle>
                    <Grid container>
                      <Grid item xs>
                        {header}
                      </Grid>
                      <Grid item xs className="text-right">
                        <Button onClick={handleDialogClose}>
                          <GridCloseIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Form id="create-pod-form" onKeyDown={e => e.stopPropagation()}>
                      <Typography variant="h5">Pod Info</Typography>
                      <FormControl fullWidth className={classes.formControl}>
                        <Grid container spacing={4}>
                          <Grid item xs={10}>
                            <TextField
                              name="podName"
                              label="Pod Name *"
                              fullWidth
                              inputProps={{ 'aria-label': 'podName' }}
                              onChange={handleChange}
                              value={values.podName ?? ''}
                              variant="standard"
                            />
                            <FieldError name="podName" />
                          </Grid>
                          <Grid item xs>
                            <TextField
                              name="podNumber"
                              label="Pod Number *"
                              fullWidth
                              inputProps={{ 'aria-label': 'podNumber' }}
                              onChange={handleChange}
                              value={values.podNumber ?? ''}
                              variant="standard"
                            />
                            <FieldError name="podNumber" />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="branchID-label">Branch *</InputLabel>
                        <NativeSelect
                          value={values.branchID ?? ''}
                          onChange={evt => {
                            setFieldValue('branchID', evt.target.value)
                          }}
                          inputProps={{ name: 'branchID', id: 'branchID-selector' }}
                        >
                          <option value="" />
                          {branches?.map((_, i) => (
                            <option key={`branchid-${i}`} value={_.id}>
                              {_.branchName}
                            </option>
                          ))}
                        </NativeSelect>
                        <FieldError name="branchID" />
                      </FormControl>
                      <div className={classes.section}>
                        <Typography variant="h5">Google Analytics Configuration</Typography>
                        <FormControl fullWidth className={classes.formControl}>
                          <TextareaAutosize
                            name="googleConfig"
                            itemType="object"
                            value={
                              typeof values.googleConfig === 'string'
                                ? values.googleConfig
                                : JSON.stringify(values.googleConfig ?? undefined, null, 2)
                            }
                            rowsMin={3}
                            placeholder="JSON Config Credentials"
                            onChange={handleChange}
                          />
                        </FormControl>
                      </div>
                      <div className={classes.section}>
                        <Typography variant="h5">Pod Members</Typography>
                        <Typography variant="subtitle2" className={classes.subtitle2}>
                          Add employees to have pod access
                        </Typography>
                        <FormControl fullWidth className={classes.formControl}>
                          <Select
                            value={values.podMembers ?? ''}
                            autoWidth
                            onChange={evt => {
                              setFieldValue(
                                'podMembers',
                                [].slice.call(evt.target.value).map(value => value)
                              )
                            }}
                            multiple={true}
                            renderValue={selected => (
                              <div className={classes.chips}>
                                {(selected as number[]).map(value => {
                                  const user = users?.filter(_ => _.id === value)[0]
                                  return (
                                    <Chip
                                      avatar={<Avatar alt="Natacha" src={user?.profilePicture} />}
                                      key={value}
                                      label={`${user?.firstName} ${user?.lastName}`}
                                      className={classes.chip}
                                    />
                                  )
                                })}
                              </div>
                            )}
                          >
                            {sortedUsers?.map((_, i) => (
                              <MenuItem key={`pod-members-${i}`} value={_.id}>
                                <Checkbox
                                  checked={
                                    values.podMembers
                                      ? values.podMembers!.indexOf(_.id) > -1
                                      : false
                                  }
                                />
                                <ListItemText primary={`${_.firstName} ${_.lastName}`} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={classes.section}>
                        <Typography variant="h5">Affiliate Pod Members</Typography>
                        <Typography variant="subtitle2" className={classes.subtitle2}>
                          Add affiliated employees to have pod access
                        </Typography>
                        <FormControl fullWidth className={classes.formControl}>
                          <Select
                            value={values.affiliateMembers ?? ''}
                            autoWidth
                            onChange={evt => {
                              setFieldValue(
                                'affiliateMembers',
                                [].slice.call(evt.target.value).map(value => value)
                              )
                            }}
                            multiple={true}
                            renderValue={selected => (
                              <div className={classes.chips}>
                                {(selected as number[]).map(value => {
                                  const user = users?.filter(_ => _.id === value)[0]
                                  return (
                                    <Chip
                                      avatar={<Avatar alt="Natacha" src={user?.profilePicture} />}
                                      key={value}
                                      label={`${user?.firstName} ${user?.lastName}`}
                                      className={classes.chip}
                                    />
                                  )
                                })}
                              </div>
                            )}
                          >
                            {sortedUsers?.map((_, i) => (
                              <MenuItem key={`affiliate-members-${i}`} value={_.id}>
                                <Checkbox
                                  checked={
                                    values.affiliateMembers
                                      ? values.affiliateMembers!.indexOf(_.id) > -1
                                      : false
                                  }
                                />
                                <ListItemText primary={`${_.firstName} ${_.lastName}`} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={classes.section}>
                        <Typography variant="h5">Pod Businesses</Typography>
                        <Typography variant="subtitle2" className={classes.subtitle2}>
                          These are the businesses this pod is responsible for
                        </Typography>
                        <FormControl fullWidth className={classes.formControl}>
                          <Select
                            value={values.podBusinesses ?? ''}
                            autoWidth
                            onChange={evt => {
                              setFieldValue(
                                'podBusinesses',
                                [].slice.call(evt.target.value).map(value => value)
                              )
                            }}
                            multiple={true}
                            renderValue={selected => (
                              <div className={classes.chips}>
                                {(selected as number[]).map(value => {
                                  const bus = businesses?.filter(_ => _.id === value)[0]
                                  return (
                                    <Chip
                                      avatar={<Avatar alt="Natacha" src={bus?.fbPageImgURL} />}
                                      key={value}
                                      label={bus?.businessName}
                                      className={classes.chip}
                                    />
                                  )
                                })}
                              </div>
                            )}
                          >
                            {businesses?.map((_, i) => (
                              <MenuItem key={`pod-business-${i}`} value={_.id}>
                                <Checkbox checked={values.podBusinesses!.indexOf(_.id) > -1} />
                                <ListItemText primary={_.businessName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Form>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={() => handleSubmit()} variant="contained" color="primary">
                      Save
                    </Button>
                  </DialogActions>
                </>
              )
            }}
          </Formik>
        </Dialog>
      )}
    </>
  )
}
