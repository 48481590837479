import React from 'react'
import Components from './components'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'

const Options = ({
  pages: {
    business: { main },
  },
}: Redux) => {
  return !!main.branch_id ? (
    <div>
      <Components.Options />
      <Components.Tags />
    </div>
  ) : (
    <div className="alert alert-danger">
      <i className="fa fa-exclamation-triangle mr-2" />
      <span>Please assign this business to a branch first</span>
    </div>
  )
}

export default Connect(Options)
