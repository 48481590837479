import { toast } from 'react-toastify'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import { Connected } from 'app/interface/redux'
import Portlet from 'components/reusable/portlet'
import { ReloadLeadsButton } from './reload-leads-button/ReloadLeadsButton'
import { PreloadBusinessButton } from './preload-business-button/PreloadBusinessButton'

interface Props extends Connected {
  onSubmit: () => void
  new: boolean
}

class PreloadDataComponent extends PureComponent<Props> {
  onSubmit = () => {
    const { onSubmit } = this.props
    const kpiStages = this.props.pages.business.funnel.stages.filter(x => x.is_kpi)
    const subKpiStages = this.props.pages.business.funnel.stages.filter(x => x.is_sub_kpi)

    if (kpiStages.length === 0) {
      toast.error('You must have a KPI stage')
    } else if (kpiStages.length > 1) {
      toast.error('You may only have one KPI stage')
    } else if (subKpiStages.find(x => x.name === kpiStages[0].name)) {
      toast.error('Your sub kpi stage cannot be the same as your kpi stage')
    } else {
      onSubmit()
    }
  }

  render() {
    return (
      <Portlet title="Preload Data">
        <ReloadLeadsButton businessID={this.props.pages.business.id!} />
        <hr />
        <PreloadBusinessButton businessID={this.props.pages.business.id!} />
      </Portlet>
    )
  }
}

export const PreloadData = Connect(PreloadDataComponent)
