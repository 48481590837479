import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { ReactNode, useEffect, useState } from 'react'

export interface TabPanelSection {
  title: string
  section: ReactNode
  onSelectSection?: () => void
}

export interface TabPanelProps {
  sections: Array<TabPanelSection>
  activeSection?: number
  elevation?: number
}

interface PanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function Panel(props: PanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))

export function TabPanel({ sections, activeSection, elevation }: TabPanelProps) {
  const classes = useStyles()
  const [section, setSection] = useState(0)

  useEffect(() => {
    // if the active section changes, set the internal state and rerender
    activeSection && setSection(activeSection)
    activeSection && activeSection === 0 && setSection(0)
  }, [activeSection])

  return (
    <div data-testid="tab-panel" className={classes.root}>
      <AppBar
        className="mb-4 border"
        position="static"
        color="default"
        elevation={elevation ?? 1}
        style={{ borderRadius: '4px', backgroundColor: 'white' }}
      >
        <Tabs
          value={section}
          onChange={(e, i) => setSection(i)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="lead card section panel"
        >
          {sections.map((section, i) => (
            <Tab
              key={`section_${i}`}
              data-testid={`section_${i}`}
              label={section.title}
              {...a11yProps(i)}
              onClick={section.onSelectSection}
            />
          ))}
        </Tabs>
      </AppBar>
      {sections.map((s, i) => (
        <Panel key={`section-panel-${i}`} value={section} index={i}>
          {sections[section].section}
        </Panel>
      ))}
    </div>
  )
}
