import Axios from 'axios'
export const NestAPI = Axios.create({
  baseURL: `${process.env.REACT_APP_NEST_API}/`,
})

export const setAdminNestToken = (token: string | null) => {
  if (token) {
    NestAPI.defaults.headers['Authorization'] = `Bearer ${token}`
  } else {
    NestAPI.defaults.headers['Authorization'] = null
  }
}
