import { Auth0Role } from '@marketing-milk/interfaces'
import { Button, Typography, TextField, Divider } from '@material-ui/core'
import React, { useState } from 'react'
import { Alert } from '@material-ui/lab'
import * as yup from 'yup'
import { useFormik } from 'formik'

const validationSchema = yup.object({
  name: yup.string().max(50).required('Role name is Required'),
  description: yup.string().max(50).required('Role description is Required'),
})

export interface DetailsSectionProps {
  role: Auth0Role
  onDelete: (role: Auth0Role) => void
  onUpdateRole?: (roleID: string, roleDTO: Auth0Role) => void
}

export function DetailsSection(props: DetailsSectionProps) {
  const initialValues: Auth0Role = {
    name: props.role.name ?? '',
    description: props.role.description ?? '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      props.onUpdateRole && props.onUpdateRole(props.role.id!, values)
    },
  })
  const canSubmit = !formik.isSubmitting && formik.isValid

  return (
    <div className="d-block w-100">
      <form className="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-5">
            <TextField
              autoFocus
              className="mb-3"
              fullWidth
              label="Name"
              variant="standard"
              placeholder="e.g. Administrator"
              InputLabelProps={{ shrink: true }}
              // formik props
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </div>
          <div className="col-12 col-md-5">
            <TextField
              label="Description"
              fullWidth
              variant="standard"
              placeholder="e.g. A user with all permissions."
              InputLabelProps={{ shrink: true }}
              // formik props
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </div>
          <div className="col-12 col-md-2">
            <Button
              disabled={!canSubmit}
              variant="contained"
              className={`text-white ${canSubmit && 'bg-success'} my-3 ml-0 ml-md-3`}
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      </form>

      <div className="mt-4">
        <Typography variant="h4" className="mb-3">
          Danger Zone
        </Typography>
        <Divider />
        <Alert
          variant="standard"
          severity="error"
          className="mt-3"
          action={
            <Button
              className="bg-white"
              disableElevation
              variant="contained"
              onClick={() => props.onDelete(props.role)}
            >
              Delete
            </Button>
          }
        >
          <Typography variant="h6" className="mt-n1">
            Delete Role
          </Typography>
          Warning: Once you delete this role you will not be able to undo it. You'll have to create
          a new one and reassign all permissions and users.
        </Alert>
      </div>
    </div>
  )
}
