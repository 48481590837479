import { exampleAttrWindowsBySourceType } from '@marketing-milk/interfaces'
import Business from 'app/interface/redux/types/pages/business'

const Default: Business = {
  main: {
    business_name: '',
    website: '',
    fb_account_id: '0',
    fb_page_id: null,
    mktg_retainer: 0,
    yearly_value: 0,
    start: '',
    branch_id: 0,
    pod_id: 0,
    fb_dataset_id: null,
    instagram_id: null,
    offline_event_set_id: null,
    monthly_spend: 0,
    ga_view_id: 0,
    client_customer_id: 0,
    goal_text: '',
    yvc_party_size: 0,
    yvc_visits: 0,
    yvc_spend: 0,
    ingest_access_token: '',
    close_percentage: 0,
    timezone: 'America/Chicago',
    attr_windows: exampleAttrWindowsBySourceType,
    show_roi: true,
    pixel_indicator: 'Facebook Pixel Activity',
    pixel_indicator_desc: 'This shows that your Facebook Pixel is active on your website',
    data_indicator: 'Data Activity',
    data_indicator_desc: 'This indicator shows that your data source is linked into our system',
    upload_indicator: 'Upload Activity',
    upload_indicator_desc:
      'This indicator shows that your data has been processed by Marketing Milk',
    first_initial_purchase: 0,
    phase: 1,
    city: '',
    state: '',
    lead_webhook_format: 'off',
    currency_type: 'USD',
    lookback_window: 90,
    locale: 'en-US',
    override_yvc: false,
    data_uploader_active: true,
    reports_page_active: true,
    received_product_demo: true,
    repeat_check_fullname_fallback: true,
    use_conversions_api: false,
    allow_lead_collection: true,
    fb_ad_authorizer: undefined,
    fb_ad_authorizer_id: null,
    fb_page_authorizer: undefined,
    fb_page_authorizer_id: null,
  },
  funnel: {
    active: null,
    preset: 0,
    stages: [],
  },
  users: [],
  options: [],
  tags: [],
  dataSources: [],
}

export default Default
