import Funnel from './funnel'
import Generic from './generic'
import Data from 'app/interface/redux/types/data'

const DefaultData: Data = {
  funnel: Funnel,
  generic: Generic,
}

export default DefaultData
