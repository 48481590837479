import React from 'react'
import { OwnershipGroupAddBusinesses } from './OwnershipGroupAddBusinesses'
import { OwnershipGroupRemoveBusinesses } from './OwnershipGroupRemoveBusinesses'
import { MaterialDataTable, ownershipGroupService } from '@marketing-milk/frontend'
import { Input, InputAdornment, makeStyles, Paper, Typography } from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { OwnershipGroup } from '@marketing-milk/interfaces'

export const useButtonStyles = makeStyles({
  button: {
    backgroundColor: 'transparent',
    color: '#676E64',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#545650',
    },
  },
})

interface OwnershipGroupEditProps {}
export function OwnershipGroupEdit(props: OwnershipGroupEditProps) {
  const {} = props
  const classes = useButtonStyles()
  const routerHistory = useHistory()
  const params: { id: string } = useParams()
  const [ownershipGroup, setOwnershipGroup] = React.useState<OwnershipGroup>()
  const [selectedBusinessIds, setSelectedBusinessIds] = React.useState<number[]>([])
  const [filter, setFilter] = React.useState<string>('')

  const fetchOne = async (id: number) => {
    const fetchedOwnershipGroup = await ownershipGroupService.getOne(id)
    setOwnershipGroup(fetchedOwnershipGroup)
  }

  React.useEffect(() => {
    fetchOne(Number(params.id))
  }, [params.id])

  const addSelectedBusinesses = async (businessIds: number[]) => {
    if (ownershipGroup) {
      await ownershipGroupService.addBusinesses(ownershipGroup.id, businessIds)
      setOwnershipGroup(await ownershipGroupService.getOne(ownershipGroup.id))
    } else {
      toast.error('Error loading ownership group.')
    }
  }

  const removeSelectedBusinesses = async () => {
    if (ownershipGroup) {
      await ownershipGroupService.removeBusinesses(ownershipGroup.id, selectedBusinessIds)
      setOwnershipGroup(await ownershipGroupService.getOne(ownershipGroup.id))
      setSelectedBusinessIds([])
    } else {
      toast.error('Error loading ownership group.')
    }
  }

  return (
    <Paper style={{ minHeight: 'calc(100vh - 100px)' }}>
      <div className="container-fluid p-4">
        <div style={{ display: 'inline-flex', alignItems: 'baseline', paddingBottom: '5px' }}>
          <Button
            disableRipple
            className={classes.button}
            onClick={e => {
              e.preventDefault()
              routerHistory.push('/admin/ownership-groups/list')
            }}
          >
            <ArrowBackIcon />
            &nbsp;&nbsp;
            <Typography>Back to Ownership Groups</Typography>
          </Button>
        </div>

        {!ownershipGroup ? (
          <h2>Loading...</h2>
        ) : (
          <div>
            <h2>{ownershipGroup.name}</h2>
            <div>
              <OwnershipGroupAddBusinesses
                ownershipGroup={ownershipGroup}
                onConfirm={addSelectedBusinesses}
              />
              <OwnershipGroupRemoveBusinesses
                ownershipGroup={ownershipGroup}
                onConfirm={removeSelectedBusinesses}
                isDisabled={!selectedBusinessIds.length}
              />
              <Input
                fullWidth
                style={{ marginTop: '2em', marginBottom: '1em' }}
                value={filter}
                onChange={e => setFilter(e.target.value)}
                placeholder="Business Name"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                }
              />
            </div>
            <MaterialDataTable
              checkboxSelection
              disableSelectionOnClick
              pagination
              paginationMode="client"
              columns={[{ field: 'businessName', headerName: 'Business Name', flex: 1 }]}
              rows={
                ownershipGroup.businesses?.filter(business =>
                  business.businessName.includes(filter)
                ) ?? []
              }
              onSelectionModelChange={e => {
                setSelectedBusinessIds(e.selectionModel.map(id => Number(id)))
              }}
            />
          </div>
        )}
      </div>
    </Paper>
  )
}
