import {
  Button,
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { DeletedUser, User } from '@marketing-milk/interfaces'
import { MaterialDataTable, useCreateFetchHook, userService } from '@marketing-milk/frontend'

export interface DeletedUsersModalProps {
  onRestoreDeletedUsers?: () => void
}

export function DeletedUsersModal({ onRestoreDeletedUsers }: DeletedUsersModalProps) {
  const [loading, setLoading] = useState<Number>()
  const [refresh, setRefresh] = useState(0)
  const [isOpen, setModal] = useState(false)
  const [callbackOnClose, setCallbackOnClose] = useState(false)
  const deletedUsers: DeletedUser[] | undefined = useCreateFetchHook(
    userService.getDeletedUsers,
    refresh
  )

  function toggle() {
    if (isOpen && callbackOnClose) {
      onRestoreDeletedUsers && onRestoreDeletedUsers()
      setCallbackOnClose(false)
    }
    setModal(_ => !_)
  }

  async function onDeleteUser(user: User) {
    try {
      if (loading) {
        return
      }
      setLoading(user.id)
      await userService.restoreByID(user.id)
      setCallbackOnClose(true)
      setRefresh(_ => _ + 1)
    } catch (e) {
      toast.error(`${user.email}'s account could not be restored at this time`)
      throw e
    } finally {
      setLoading(undefined)
    }
  }

  return (
    <>
      <Button variant="contained" color="secondary" onClick={toggle}>
        <i className="fa fa-undo mr-2" />
        Restore
      </Button>
      <Dialog maxWidth="md" fullWidth open={isOpen} onClose={toggle}>
        <DialogTitle>
          <div className="d-flex align-items-center">
            <div>
              <Typography variant="h5">Deleted Users</Typography>
            </div>
            <div className="ml-auto mr-0">
              <IconButton className="" onClick={toggle}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <MaterialDataTable
            pagination
            pageSize={50}
            height={'calc(100vh - 22rem)'}
            sortingOrder={['asc', 'desc']}
            rows={deletedUsers || []}
            rowHeight={38}
            headerHeight={50}
            columns={[
              {
                field: 'name',
                type: 'string',
                headerName: 'Name',
                width: 200,
                renderCell: (params: any) => (
                  <>
                    {params.row.firstName} {params.row.lastName}
                  </>
                ),
              },
              {
                field: 'email',
                type: 'string',
                headerName: 'Email',
                width: 200,
              },
              {
                field: 'actions',
                type: 'string',
                headerName: 'Actions',
                width: 200,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                  const [buttonColor, buttonText] =
                    loading === params.row.id
                      ? ['secondary', 'Restoring User...']
                      : ['primary', 'Restore User']
                  return (
                    <Button
                      variant="contained"
                      color={buttonColor as any}
                      onClick={() => onDeleteUser(params.row as any)}
                    >
                      {buttonText}
                    </Button>
                  )
                },
              },
            ]}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
