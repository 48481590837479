import React from 'react'
import { MaterialDataTable } from '@marketing-milk/frontend'
import { ValidationTableFormatErrorsProps } from './FormatErrors.types'
import { CreateVersion } from '../../../edit-upload-modal/create-version/CreateVersion'

export function ValidationTableFormatErrors({ report, upload }: ValidationTableFormatErrorsProps) {
  if (report.type !== 'format') {
    return null
  }

  return (
    <>
      <MaterialDataTable
        sortingOrder={['asc', 'desc']}
        rows={report.errors.map((_, index) => ({
          id: index,
          value: _,
        }))}
        columns={[
          {
            field: 'value',
            headerName: 'Problem',
            width: 150,
            type: 'string',
          },
        ]}
        rowHeight={38}
        headerHeight={50}
        density="comfortable"
        columnBuffer={10}
        checkboxSelection={false}
        pagination
        pageSize={10}
      />
      <div className="row mt-4">
        <div className="col-12">
          <CreateVersion
            businessID={upload.businessID.toString()}
            uploadID={upload.id.toString()}
            disabled={upload.locked}
          />
        </div>
      </div>
    </>
  )
}
