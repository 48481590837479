import { createContext } from 'react'
import { UserUpload } from '@marketing-milk/interfaces'
import { PreviousUploadsContext } from './PreviousUploadsContext.types'

export const previousUploadsContext = createContext<PreviousUploadsContext>({
  uploads: [],
  loading: true,
  deleteUpload(uploadID: number) {},
  updateUpload(index: number, changes: Partial<UserUpload>): void {},
})
