import React from 'react'
import { ModalProps } from './'
import { Modal as ModalBase, ModalBody } from 'reactstrap'

export function Modal({ children, isOpen, onToggle }: ModalProps) {
  return (
    <ModalBase isOpen={isOpen} toggle={onToggle}>
      <ModalBody children={children} />
    </ModalBase>
  )
}
