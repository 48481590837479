import Core from './core'
import Data from './data'
import Pages from './pages'
import Redux from 'app/interface/redux'

const DefaultData: Redux = {
  core: Core,
  data: Data,
  pages: Pages,
}

export default DefaultData
