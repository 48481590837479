import React from 'react'
import Tabs from './tabs'
import Wizard from 'components/base/wizard'

export default () => (
  <div>
    <h4>How will we calculate progress</h4>
    <Wizard
      steps={[
        {
          text: 'Goal',
          component: <Tabs.Goal />,
        },
        {
          text: 'Phase',
          component: <Tabs.Phase />,
        },
        {
          text: 'Expenses',
          component: <Tabs.Expenses />,
        },
        {
          text: 'Yearly Value / Close %',
          component: <Tabs.Calculator />,
        },
        {
          text: 'Data Attribution',
          component: <Tabs.Attribution />,
        },
        {
          text: 'Repeat Data',
          component: <Tabs.Repeat />,
        },
      ]}
      type="vertical"
      portlet={false}
      buttons={true}
    />
  </div>
)
