import React from 'react'

interface Props {
  header: string[]
  rows: Row[]
}

type Row = {
  [key: string]: any
}

export default ({ header, rows }: Props) => (
  <div className="dataTables_wrapper dt-bootstrap4 no-footer" id="kt_table_1_wrapper">
    <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
      <thead>
        <tr role="row">
          {header.map((column, i) => (
            <th key={i}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i}>
            {Object.keys(row).map(column => (
              <td className="td-right" key={column}>
                {row[column]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
