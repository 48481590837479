import React from 'react'
import { Link } from 'react-router-dom'
import Guard from 'components/utility/guard'
import { Link as ILink } from 'app/interface/config/sidebar'

export default ({ guard, text, icon, link }: ILink) => {
  return (
    <Guard guard={guard}>
      {({ status }) => {
        return !status ? null : (
          <li className="kt-menu__item">
            {link.indexOf('http') === -1 ? (
              <Link className="kt-menu__link" to={`/admin/${link}`}>
                <span className="kt-menu__link-icon">
                  <i className={`fa fa-${icon} mr-2`} />
                </span>
                <span className="kt-menu__link-text">{text}</span>
              </Link>
            ) : (
              <a className="kt-menu__link" href={link}>
                <span className="kt-menu__link-icon">
                  <i className={`fa fa-${icon} mr-2`} />
                </span>
                <span className="kt-menu__link-text">{text}</span>
              </a>
            )}
          </li>
        )
      }}
    </Guard>
  )
}
