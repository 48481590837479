import React from 'react'
import { ChooseFormat } from './choose-format/ChooseFormat'
import { VersionActionsProps } from './VersionActions.types'
import { ChooseTimezone } from './choose-timezone/ChooseTimezone'
import { ChooseDataType } from './choose-data-type/ChooseDataType'
import { ChooseEventName } from './choose-event-name/ChooseEventName'
import { SubmitVersionButton } from './submit-button/SubmitVersionButton'

export function VersionActions({
  businessID,
  upload,
  latestVersion,
  badDataEventNames,
}: VersionActionsProps) {
  return (
    <>
      <SubmitVersionButton
        businessID={businessID}
        upload={upload}
        latestVersion={latestVersion}
        badDataEventNames={badDataEventNames}
      />
      <div className="row mt-4">
        <div className="col-md-4 col-sm-12">
          <h5 className="mt-4">Format</h5>
          <ChooseFormat upload={upload} businessID={businessID} />
        </div>
        <div className="col-md-4 col-sm-12">
          <h5 className="mt-4">Event Name</h5>
          <ChooseEventName upload={upload} />
        </div>
        <div className="col-md-4 col-sm-12">
          <h5 className="mt-4">Timezone</h5>
          <ChooseTimezone upload={upload} />
        </div>
      </div>

      <h5 className="mt-4">Data Source</h5>
      <ChooseDataType upload={upload} />
    </>
  )
}
