import Label from './label'
import Redux from 'app/interface/redux'
import Interface from './interface/input'
import Validator from 'fastest-validator'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import Components from './components/input'
import ErrorActions from 'app/redux/actions/core/errors'

interface Props extends Interface, Omit<Redux, 'selector'> {}

class Input extends PureComponent<Props> {
  state = {
    error: null,
  }

  componentDidUpdate(props) {
    if (props.value !== this.props.value) {
      this.checkSchema()
    }
  }

  checkSchema = () => {
    const { column = '', schema = {}, onError, value = '' } = this.props
    if (Object.keys(schema).length > 0) {
      const validator = new Validator()
      const check = validator.compile(schema)
      const errors = check({ [column]: value })

      if (onError) {
        onError(column, typeof errors === 'object')
      }

      this.setState({
        error: typeof errors === 'object' ? errors[0].message : null,
      })
    }
  }

  render() {
    const { error } = this.state
    const {
      column,
      core: { errors },
      isValid,
      text,
      optional = false,
      type,
      onChange,
    } = this.props

    const regular = this.props.other ? !!this.props.other.regular : true

    const prepend = this.props.other ? this.props.other.prepend : null

    const datalist = this.props.other?.datalist ? (
      <datalist id={this.props.other.datalist.id}>
        {this.props.other.datalist.options.map(option => (
          <option value={option} />
        ))}
      </datalist>
    ) : (
      <></>
    )

    let message: string | null = error

    if (errors.length > 0) {
      const local = errors.find(x => x.column === column)
      if (local && local.message) {
        message = local.message
      }
    }

    let { className = 'form-control' } = this.props

    if (message) {
      className += ' react-invalid'
    }

    if (typeof isValid === 'function' && !isValid()) {
      className += ' react-invalid'
    }

    const onUpdate = (column, value) => {
      if (message && errors.find(x => x.column === column)) ErrorActions.delErrors(column)

      // TODO: Hack fixing lookback_window incorrect type because this form
      // builder is not good. We need to rip all this out badly.
      if (column === 'lookback_window') {
        onChange(column, Number(value))
      } else {
        onChange(column, value)
      }
    }

    const Component = Components[type]

    return regular ? (
      <div>
        <Label error={message} optional={optional} text={text} />

        <Component {...this.props} className={className} onChange={onUpdate} />
        {datalist}
      </div>
    ) : (
      <div>
        <Label error={message} optional={optional} text={text} />
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">{prepend}</span>
          </div>
          <Component {...this.props} className={className + 'disabled'} onChange={onUpdate} />
          {datalist}
        </div>
      </div>
    )
  }
}

export default Connect(Input)
