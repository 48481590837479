import { Auth0Role } from '@marketing-milk/interfaces'
import { Button, Paper, Typography } from '@material-ui/core'
import { TabPanel, TabPanelSection } from 'components/generic/tab-panel'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { UsersSection } from './UsersSection'
import { DetailsSection } from './DetailsSection'
import { PermissionsSection } from './PermissionsSection'
import { DeleteRoleModal } from './DeleteRoleModal'
import { useRoleDetails } from './useRoleDetails'

export function RoleDetails() {
  const history = useHistory()
  const [activeSection, setActiveSection] = useState(0)
  const [roleToDelete, setRoleToDelete] = useState<Auth0Role | undefined>()
  const {
    roleQuery,
    permissionsQuery,
    usersQuery,
    updateRole,
    deleteRole,
    deletePermissionFromRole,
    addPermissions,
    addUsersToRole,
    removeUserFromRole,
  } = useRoleDetails()

  if (!roleQuery.data) return <></>

  const roleSections: TabPanelSection[] = [
    {
      title: 'Settings',
      section: (
        <DetailsSection
          role={roleQuery.data}
          onDelete={role => setRoleToDelete(role)}
          onUpdateRole={updateRole}
        />
      ),
      onSelectSection: () => setActiveSection(0),
    },
    {
      title: 'Permissions',
      section: (
        <PermissionsSection
          permissions={permissionsQuery.data}
          onAddPermissions={permissions => addPermissions(permissions)}
          onDeletePermission={permission => deletePermissionFromRole(permission)}
        />
      ),
      onSelectSection: () => setActiveSection(1),
    },
    {
      title: 'Users',
      section: (
        <UsersSection
          users={usersQuery.data}
          handleAddUsers={userIds => addUsersToRole(userIds)}
          handleRemoveUser={userId => removeUserFromRole(userId)}
        />
      ),
      onSelectSection: () => setActiveSection(2),
    },
  ]

  return (
    <Paper style={{ height: 'calc(100vh - 100px)' }}>
      <div className="container-fluid p-4" style={{ width: '100%' }}>
        {/* Header w/ profile picture, selected user name, actions button and back to home button */}
        <div>
          <div style={{ display: 'inline-flex', alignItems: 'baseline', paddingBottom: '5px' }}>
            <Button
              disableRipple
              onClick={e => {
                e.preventDefault()
                history.push('/admin/roles/list')
              }}
            >
              <ArrowBackIcon />
              &nbsp;&nbsp;
              <span>Back to Roles</span>
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div style={{ alignSelf: 'center' }}>
              <Typography variant="h2" className="mb-3">
                {roleQuery.data.name}
              </Typography>
            </div>
          </div>
          <TabPanel elevation={0} sections={roleSections} activeSection={activeSection} />
        </div>
      </div>
      <DeleteRoleModal
        deleteRole={roleToDelete}
        onDelete={roleID => deleteRole(roleID)}
        onCancel={() => setRoleToDelete(undefined)}
      />
    </Paper>
  )
}
