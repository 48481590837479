import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { OwnershipGroup } from '@marketing-milk/interfaces'
import { ownershipGroupService } from '@marketing-milk/frontend'

interface OwnershipGroupDeleteProps {
  ownershipGroup: OwnershipGroup
  onDelete: () => void
}
export function OwnershipGroupDelete(props: OwnershipGroupDeleteProps) {
  const { ownershipGroup, onDelete } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const handleDelete = async () => {
    setIsOpen(false)
    try {
      await ownershipGroupService.remove(ownershipGroup.id)
      onDelete()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(`Failed to delete ${ownershipGroup.name}: ${err.message}`)
      } else {
        toast.error('An unexpected error occurred. Please try again.')
      }
    }
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setIsOpen(true)}
        endIcon={<DeleteIcon />}
        style={{ backgroundColor: '#ff5555', color: 'white' }}
      >
        Delete
      </Button>

      <Dialog open={isOpen} keepMounted>
        <DialogContent style={{ width: '400px' }}>
          Are you sure you want to delete{' '}
          <span className="font-weight-bold text-danger">{ownershipGroup.name}</span>? The
          businesses within this ownership group will <strong>not</strong> be deleted.
        </DialogContent>
        <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
          <Button onClick={() => setIsOpen(false)} variant="contained">
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleDelete}
            variant="contained"
            style={{ backgroundColor: '#ff5555', color: 'white' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
