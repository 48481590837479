import { Link } from 'react-router-dom'
import React, { ReactNode, useContext } from 'react'
import Loading from 'components/notices/loading'
import { Notification } from '@marketing-milk/frontend'
import { UploadStatusProps } from './UploadStatus.types'
import { DataCheckerNotes } from './data-checker-notes/DataCheckerNotes'
import {
  ReviewStatus,
  ValidationStatus,
  UploadStatus as UploadStatusI,
} from '@marketing-milk/interfaces'
import { VerifyUploadModal } from '../../../verify-upload-modal/VerifyUploadModal'
import { previousUploadsContext } from '../../../context/PreviousUploadsContext'

export function UploadStatus({ businessID, uploadID, version }: UploadStatusProps) {
  const { uploads } = useContext(previousUploadsContext)
  const upload = uploads.find(_ => _.id === Number(uploadID))!

  const versionStatusToMessage: Record<ValidationStatus, ReactNode> = {
    pending: (
      <Notification header="Data Check In Progress" icon="fa fa-file text-info">
        Your upload is being checked for common data problems. You won't be able to submit your
        upload for approval until this is finished.
      </Notification>
    ),
    invalid: (
      <>
        <Notification header="Data Check Failed" icon="fa fa-file text-danger">
          <div className="d-flex">
            We found some problems with your upload that need resolved before submitting. You can
            view them{' '}
            <VerifyUploadModal upload={upload} version={version}>
              <b className="text-cursor ml-2">here</b>
            </VerifyUploadModal>
          </div>
        </Notification>
        <DataCheckerNotes />
      </>
    ),
    valid: (
      <Notification header="Data Check Passed" icon="fa fa-file text-success">
        Your upload is cleared for common data problems and is now ready to be submitted for
        approval.
        <br />
        <small>
          <span className="text-warning">* Note,</span> It is possible for this file to contain
          errors that our AI was unable to detect.
        </small>
      </Notification>
    ),
  }

  const reviewStatusToMessage: Record<ReviewStatus, ReactNode> = {
    not_submitted: null,
    submitted: (
      <Notification header="Review In Progress" icon="fa fa-user-tie text-info">
        Your upload is being checked by our Data Analysts for any problems that could affect match
        rate.
      </Notification>
    ),
    rejected: (
      <Notification header="Review Rejected" icon="fa fa-user-tie text-danger">
        Our Data Analyst has found problems with your upload and has rejected it. You can find these
        details{' '}
        <Link
          to={`/admin/business/${businessID}/uploads/${uploadID}/details/version/${version?.id}`}
        >
          here
        </Link>
        .
      </Notification>
    ),
    approved: (
      <Notification header="Review Finished" icon="fa fa-user-tie text-success">
        Your upload has been approved by our Data Analyst and is now ready to be processed!
      </Notification>
    ),
  }

  const uploadStatusToMessage: Record<UploadStatusI, ReactNode> = {
    not_submitted: null,
    pending: (
      <Notification header="Upload In Progress" icon="fa fa-database text-info">
        Your upload is being sent to sent to our servers.
      </Notification>
    ),
    upload: (
      <Notification header="Upload Finished" icon="fa fa-database text-success">
        Your upload has been processed successfully.
      </Notification>
    ),
    error: (
      <>
        <Notification header="Upload Failed" icon="fa fa-database text-danger">
          A problem occurred when processing your data.
        </Notification>
        <DataCheckerNotes />
      </>
    ),
  }

  return (
    <div className="version-status-list mb-4">
      <b>Upload Status</b>
      {version === undefined && <Loading />}
      {version !== undefined && uploadStatusToMessage[version?.uploadStatus]}
      {version !== undefined && reviewStatusToMessage[version?.reviewStatus]}
      {version !== undefined && versionStatusToMessage[version?.validationStatus]}
    </div>
  )
}
