export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Helper for {@link Intl.NumberFormat} with predefined options, plus a "times" type.
 * @param {Number|String} number If this is a percentage, remember: 100% = 1.00
 * @param {String}        type   One of the {@link numeric.TYPE} constants
 * @returns {String}
 */
export const numeric = (
  number,
  type = 'decimal',
  k = false,
  currencyType = 'USD',
  locale = 'USD'
) => {
  let shortened = false
  k = false

  if (type !== 'time') {
    const isTimes = type === 'times'
    // number = shortenNumber(number)
    if (number / 1000 > 1 && type !== 'currency' && k) {
      number = number / 1000
      shortened = true
    }
    let formatted = Intl.NumberFormat(locale, {
      style: isTimes ? 'decimal' : type,
      currency: currencyType,
      currencyDisplay: 'symbol',
      minimumFractionDigits: shortened || type === 'decimal' ? 0 : 2,
      maximumFractionDigits: shortened ? 1 : 2,
    }).format(parseFloat(number))

    if (shortened) formatted = formatted + 'K'
    return isTimes ? formatted + 'x' : formatted
  } else {
    let date = new Date(null)
    date.setSeconds(number)
    return date.toISOString().substr(11, 8)
  }
}

/**
 * Splits a camelCase or PascalCase word into individual words separated by spaces.
 * @param {Object} word
 * @returns {String}
 */
export function splitCamelOrPascalCase(word) {
  var output,
    i,
    l,
    capRe = /[A-Z]/
  if (typeof word !== 'string') {
    throw new Error('The "word" parameter must be a string.')
  }
  output = []
  for (i = 0, l = word.length; i < l; i += 1) {
    if (i === 0) {
      output.push(word[i].toUpperCase())
    } else {
      if (i > 0 && capRe.test(word[i])) {
        output.push(' ')
      }
      output.push(word[i])
    }
  }
  return output.join('')
}
