import Add from './add'
import List from './list'
import React from 'react'
import { Component } from '..'

export default ({ groups }: Component) => (
  <div className={'mt-3'}>
    <div className="row">
      <div className="col-6">
        <h3>Data Sources</h3>
      </div>
      <div className={'col-6 text-right'}>
        <Add />
      </div>
    </div>
    <List groups={groups} />
  </div>
)
