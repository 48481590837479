import React from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { OwnershipGroup } from '@marketing-milk/interfaces'

interface OwnershipGroupRemoveBusinessesProps {
  ownershipGroup: OwnershipGroup
  onConfirm: () => void
  isDisabled: boolean
}
export function OwnershipGroupRemoveBusinesses(props: OwnershipGroupRemoveBusinessesProps) {
  const { ownershipGroup, onConfirm, isDisabled } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const handleRemove = () => {
    onConfirm()
    setIsOpen(false)
  }

  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={() => setIsOpen(true)}
        variant="contained"
        color="secondary"
        style={{
          marginLeft: '1em',
        }}
      >
        Remove Selected
      </Button>

      {isOpen && (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <DialogContent style={{ width: '400px' }}>
            Are you sure you want to remove the selected businesses from{' '}
            <span className="font-weight-bold text-danger">{ownershipGroup.name}</span>? The
            businesses being removed will <strong>not</strong> be deleted.
          </DialogContent>
          <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
            <Button onClick={() => setIsOpen(false)} variant="contained">
              Cancel
            </Button>
            <Button type="submit" onClick={handleRemove} color="primary" variant="contained">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
