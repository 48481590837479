import { toast } from 'react-toastify'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import Connected from 'app/interface/redux'
import { BusinessManagerHeader } from './header/BusinessManagerHeader'

interface Props extends Connected {
  onSubmit: () => void
  match: {
    params: {
      view?: string
    }
  }
  new: boolean
}

class Builder extends PureComponent<Props> {
  onSubmit = () => {
    const kpiStages = this.props.pages.business.funnel.stages.filter(x => x.is_kpi)
    const subKpiStages = this.props.pages.business.funnel.stages.filter(x => x.is_sub_kpi)

    if (kpiStages.length === 0) {
      toast.error('You must have a KPI stage')
    } else if (kpiStages.length > 1) {
      toast.error('You may only have one KPI stage')
    } else if (subKpiStages.find(x => x.name === kpiStages[0].name)) {
      toast.error('Your sub kpi stage cannot be the same as your kpi stage')
    } else {
      this.props.onSubmit()
    }
  }

  render() {
    return (
      <>
        <BusinessManagerHeader
          onSubmit={this.onSubmit}
          isNew={this.props.new}
          business={this.props.pages.business}
        />
      </>
    )
  }
}

export default Connect(Builder)
