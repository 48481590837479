import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { useFormik } from 'formik'
import { CreateOwnershipGroupDTO } from '@marketing-milk/interfaces'
import { ownershipGroupService } from '@marketing-milk/frontend'

type FormData = {
  name: string
}

interface OwnershipGroupCreateProps {
  onSuccess: () => void
}
export function OwnershipGroupCreate(props: OwnershipGroupCreateProps) {
  const { onSuccess } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const initValues: FormData = { name: '' }
  const formik = useFormik<FormData>({
    initialValues: initValues,
    onSubmit: async values => {
      const dto: CreateOwnershipGroupDTO = {
        name: values.name,
      }
      setIsLoading(true)
      try {
        await ownershipGroupService.create(dto)
        handleClose()
        onSuccess()
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          toast.error(`Ownership Group with name ${dto.name} already exists.`)
        } else {
          toast.error('Unexpected error occurred while attempting to create the ownership group.')
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setIsOpen(false)
  }

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        data-testid="create-user-btn"
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        Create
      </Button>

      <Dialog
        open={isOpen}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Create Ownership Group</DialogTitle>

        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogContent style={{ paddingBottom: '2em' }}>
            <div className="container">
              <div className="row">
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  style={{ width: '400px' }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
