import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { userService } from 'app/service/user'
import { PodWizard } from './pod-wizard/PodWizard'
import { DeletePodButton } from './delete/DeletePod'
import { podsTableColumns } from './table/PodsColumns'
import { PodsColumnType } from './table/PodsTable.types'
import { emptyPodConfig } from './pod-wizard/PodWizard.types'
import { useFetchAllBranches } from 'hooks/branchHooks'
import { Branch, PodConfigDTO } from '@marketing-milk/interfaces'
import {
  GridCellParams,
  GridColumnTypesRecord,
  GridRowData,
  GridSortDirection,
} from '@material-ui/x-grid'
import {
  branchService,
  businessService,
  useCreateFetchHook,
  MaterialDataTable,
  podService,
} from '@marketing-milk/frontend'
import { NestAPI } from '../../../app/nestapi'

export function PodsList() {
  const users = useCreateFetchHook(userService.getAll)
  const { branches } = useFetchAllBranches()
  const businesses = useCreateFetchHook(businessService.getAll)
  const [tableRows, setTableRows] = useState<any[]>([])

  const [refresh, setRefresh] = useState(0)
  const pods = useCreateFetchHook(podService.getAllWithConfig, refresh)

  useEffect(() => {
    setTableRows([])
    const temp: GridRowData[] = []
    if (pods && pods.length && branches) {
      pods?.map(x =>
        temp.push({
          id: x.id,
          podNumber: x.podNumber,
          podName: x.podName,
          branch: branches?.filter(b => b.id === x.branchID)[0],
          actions: x,
        })
      )
    }
    setTableRows(temp)
  }, [pods, branches])

  async function handleSubmit(pod: PodConfigDTO) {
    await NestAPI.post('pods/config', pod)
    setRefresh(_ => _ + 1)
  }

  const podsColTypes: GridColumnTypesRecord = {
    [PodsColumnType.PodNumber]: {
      headerName: 'Pod Number',
      type: 'string',
      renderCell: (params: GridCellParams) => {
        const podNumber = params.value
        return (
          <div className="d-block" style={{ lineHeight: '20px' }}>
            <p className="mb-0" style={{ fontSize: 12 }}>
              {podNumber}
            </p>
          </div>
        )
      },
    },
    [PodsColumnType.PodName]: {
      headerName: 'Pod Name',
      renderCell: (params: GridCellParams) => {
        const podName = params.value
        return (
          <div className="d-block" style={{ lineHeight: '20px' }}>
            <p className="mb-0" style={{ fontSize: 12 }}>
              {podName}
            </p>
          </div>
        )
      },
    },
    [PodsColumnType.Branch]: {
      headerName: 'Branch',
      renderCell: (params: GridCellParams) => {
        const branch: Branch = params.value as Branch
        return (
          <div className="d-block" style={{ lineHeight: '20px' }}>
            <p className="mb-0" style={{ fontSize: 12 }}>
              {branch.branchName}
            </p>
          </div>
        )
      },
    },
    [PodsColumnType.Actions]: {
      headerName: ' ',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const pod: PodConfigDTO = params.value as PodConfigDTO
        return (
          <div className="d-block" style={{ lineHeight: '20px' }}>
            <PodWizard
              branches={branches!}
              size="small"
              color="primary"
              variant="contained"
              pod={pod}
              header="Edit Pod"
              onSave={handleSubmit}
              users={users!}
              businesses={businesses!}
            >
              <Edit />
            </PodWizard>
            <DeletePodButton podID={Number(params.row.id)} podName={params.row.podName} />
          </div>
        )
      },
    },
  }

  return (
    <div>
      <Grid container>
        <Grid item xs>
          <h3 className="scale-in-center">Pods</h3>
        </Grid>
        <Grid item xs className="text-right">
          <PodWizard
            branches={branches!}
            size="small"
            color="primary"
            variant="contained"
            pod={emptyPodConfig}
            header="Create Pod"
            onSave={handleSubmit}
            users={users!}
            businesses={businesses!}
          >
            Create Pod
          </PodWizard>
        </Grid>
      </Grid>
      <hr className="mt-0" />
      <div>
        <MaterialDataTable
          sortModel={[{ field: 'podNumber', sort: 'asc' as GridSortDirection }]}
          columnTypes={podsColTypes}
          columns={podsTableColumns}
          rows={tableRows}
        />
      </div>
    </div>
  )
}
