import Moment from 'moment'

let format = {
  currency: data => {
    return `$ ${Number(data)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  },

  date: (date, format) => {
    return Moment.utc(date).local().format(format)
  },

  percent: data => {
    return `${Number(data)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')} %`
  },

  seconds: seconds => {
    seconds = parseInt(String(seconds / 60))
    if (seconds > 1) {
      return `${parseInt(seconds)} minutes`
    } else {
      return '1 minute'
    }
  },

  fromNow: date => {
    return Moment.utc(date).local().fromNow()
  },

  bool: value => {
    if (value) {
      return 'Yes'
    } else {
      return 'No'
    }
  },

  ucfirst: value => {
    return value.charAt(0).toUpperCase() + value.slice(1)
  },

  support_status: status => {
    switch (status) {
      case 1:
        return 'Just Opened'
      case 2:
        return 'Pending Staff Action'
      case 3:
        return 'Pending User Reply'
      default:
        return 'Closed'
    }
  },

  support_focus: focus => {
    switch (focus) {
      case 1:
        return 'Leads'
      case 2:
        return 'Distribution'
      case 3:
        return 'Revenue'
      case 4:
        return 'Fitness'
      default:
        return 'Revenue'
    }
  },

  support_urgency: urgency => {
    switch (urgency) {
      case 3:
        return 'HIGH'
      case 2:
        return 'MODERATE'
      default:
        return 'LOW'
    }
  },

  tag_type: type => {
    if (type === 1) {
      return 'Phone Provider'
    } else {
      return 'Population Density'
    }
  },
}

export default format
