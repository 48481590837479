import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import { EGuard, EGuardValues } from '@marketing-milk/frontend'

interface Interface extends Redux {
  children: any
  guard: EGuardValues
}

export const isAuthenticated = (session): boolean => {
  return session.active && session.user
}

const Guard = ({ core: { session }, children, guard }: Interface) => {
  let status: boolean = false

  if (guard === EGuard.Everyone) {
    status = true
  } else {
    status = isAuthenticated(session) && session.user!.permissions.includes(guard)
  }

  return children({
    status,
  })
}

export default Connect(Guard)
