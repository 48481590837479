import { PureComponent } from 'react'
import * as SentrySDK from '@sentry/browser'
import { NestAPI } from 'app/nestapi'

interface State {
  config: {
    environment: string
    sentry: string
  }
  loading: boolean
}

class Sentry extends PureComponent {
  state: State = {
    config: {
      environment: '',
      sentry: '',
    },
    loading: true,
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const result = await NestAPI.get('config')
    this.setState({
      config: result,
      loading: false,
    })
  }

  render() {
    const { config, loading } = this.state

    if (!loading && config.environment === 'production') {
      SentrySDK.init({
        dsn: config.sentry,
        environment: 'admin',
      })
    }

    return null
  }
}

export default Sentry
