import React from 'react'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import Input from 'components/base/form/input'
import BusinessActions from 'app/redux/actions/pages/business/basic'

const Other = ({
  pages: {
    business: { main },
  },
}: Redux) => (
  <div>
    <div>
      <h4>Other</h4>
    </div>
    <div className="row">
      <div className="col" style={{ maxWidth: 200 }}>
        <Input
          column="allow_lead_collection"
          value={main.allow_lead_collection}
          text="Allow Lead Collection"
          type="toggle"
          onChange={BusinessActions.update}
        />
      </div>
      <div className="col">
        <b className="text-info">Ensure lead access is working: </b> <br />
        Please use this{' '}
        <a href="https://developers.facebook.com/tools/lead-ads-testing" target="_blank">
          tool
        </a>{' '}
        to check your lead webhook
      </div>
    </div>
    <div>
      <Input
        column="show_roi"
        value={main.show_roi}
        text="Show ROI"
        type="toggle"
        onChange={BusinessActions.update}
      />
    </div>
    <div>
      <Input
        column="data_uploader_active"
        value={main.data_uploader_active}
        text="Activate Data Uploader"
        type="toggle"
        onChange={BusinessActions.update}
      />
    </div>
    <div>
      <Input
        column="reports_page_active"
        value={main.reports_page_active}
        text="Activate Reports Page"
        type="toggle"
        onChange={BusinessActions.update}
      />
    </div>
    <div>
      <Input
        column="received_product_demo"
        value={main.received_product_demo}
        text="Received Product Demo"
        type="toggle"
        onChange={BusinessActions.update}
      />
    </div>
  </div>
)

export default Connect(Other)
