import { useAuth0 } from '@auth0/auth0-react'
import { Paper } from '@material-ui/core'
import React from 'react'

const InvalidUser = () => {
  const { logout } = useAuth0()

  return (
    <div className="mm-login">
      <img alt="logo" src="/assets/admin/img/logo.png" />
      <Paper className="text-center m-4 pb-5 pt-3">
        <form>
          <p className="font-weight-bold">
            This email is not associated with a Marketing Milk account
          </p>
          <p style={{ color: 'darkred' }}>
            Note: If you are logged in with your Facebook account you must log out on
            <a href="https://facebook.com" target="_blank">
              {' '}
              facebook.com
            </a>
          </p>
          <button
            className="btn btn-primary btn-block"
            onClick={e => {
              e.preventDefault()
              logout()
            }}
          >
            Try Another Account
          </button>
        </form>
      </Paper>
    </div>
  )
}

export default InvalidUser
