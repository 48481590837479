import { Modal } from 'components/reusable/modal'
import { uploadSubmissionService } from '../../const'
import React, { ReactElement, useState } from 'react'
import { clientUploadService } from '@marketing-milk/frontend'
import { ActionsProps, ActionsState, defaultActionsState, UploadModalStatus } from './'

export function Actions({ submission }: ActionsProps) {
  const [state, setState] = useState<ActionsState>(defaultActionsState)

  function cancelReview(): void {
    window.location.reload()
  }

  function updateReview(approved: boolean): void {
    setState(_ => ({
      ..._,
      approved,
      modal: approved ? 'approve' : 'reject',
    }))
  }

  function updateStatus(status: UploadModalStatus): void {
    setState(_ => ({
      ..._,
      status,
      modal: status === 'success' ? 'done' : _.modal,
    }))
  }

  function toggleModal(): void {
    setState(_ => ({
      ..._,
      modal: undefined,
    }))
  }

  async function submitReview(): Promise<void> {
    updateStatus('loading')
    try {
      await uploadSubmissionService.updateSubmission(
        submission.id,
        state.approved,
        state.comments || ''
      )

      if (state.approved) {
        await clientUploadService.sendToIngest(
          submission.businessID,
          submission.uploadID,
          submission.versionID
        )
        updateStatus('success')
      } else {
        updateStatus('success')
      }
    } catch {
      updateStatus('error')
    }
  }

  function handleTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setState({ ...state, comments: e.target.value })
  }

  function getModalFooter(): ReactElement {
    const statusToSubmitButton: Record<UploadModalStatus, any> = {
      default: (
        <button className="btn btn-primary" onClick={submitReview} type="button">
          {state.modal === 'approve' ? 'Approve Upload' : 'Reject Upload'}
        </button>
      ),
      error: (
        <button className="btn btn-danger" onClick={submitReview} type="button">
          An Error Occurred
          <i className="fa fa-exclamation-triangle ml-2" />
        </button>
      ),
      loading: (
        <button className="btn btn-primary" disabled type="button">
          Saving Changes
          <i className="fa fa-spin fa-spinner ml-2" />
        </button>
      ),
      bad_data: (
        <button className="btn btn-danger" disabled type="button">
          Invalid Upload
          <i className="fa fa-exclamation-triangle ml-2" />
        </button>
      ),
      success: null,
    }

    return (
      <div className="row mt-4">
        <div className="col-6">
          <button className="btn btn-info cancel-reject" onClick={cancelReview}>
            Cancel
          </button>
        </div>
        <div className="col-6 text-right">{statusToSubmitButton[state.status]}</div>
      </div>
    )
  }

  return (
    <>
      <i
        className="approve fa fa-check fa-2x text-success cursor-pointer hover-brand"
        onClick={() => updateReview(true)}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <i
        className="reject fa fa-times fa-2x text-danger cursor-pointer hover-brand"
        onClick={() => updateReview(false)}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <i
        className="download fa fa-cloud-download-alt fa-2x text-info cursor-pointer hover-brand"
        onClick={() =>
          uploadSubmissionService.downloadSubmission(submission.id, submission.displayName)
        }
      />
      <Modal isOpen={state.modal === 'done'}>
        <div className="row text-center">
          <h5 className="w-100">Your changes have been saved</h5>
          {state.approved ? (
            <p>
              The upload has been approved and submitted to the Ingestor. You should expect to see
              the attribution from Facebook within 3 days.
            </p>
          ) : (
            <p>The upload has been rejected and will not be submitted to the Ingestor.</p>
          )}
        </div>
        <div className="row">
          <div className="col-6">&nbsp;</div>
          <div className="col-6 text-right">
            <button className="btn btn-primary" onClick={cancelReview}>
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={state.modal === 'approve'} onToggle={toggleModal}>
        <div className="row text-center">
          <h5 className="w-100">Are you sure?</h5>
          <p>
            Once the upload is approved, it will be sent to the Ingestor for processing immediately.
          </p>
        </div>
        {getModalFooter()}
      </Modal>
      <Modal isOpen={state.modal === 'reject'}>
        <div className="row">
          <h5>Reviewer Comments:</h5>
          <br />
          <textarea
            className="form-control comments"
            rows={8}
            value={state.comments}
            onChange={handleTextChange}
          />
        </div>
        {getModalFooter()}
      </Modal>
    </>
  )
}
