import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Typography } from '@material-ui/core'
import { PreloadBusinessButtonProps } from './PreloadBusinessButton.types'
import { businessService } from '../../../../../../../../app/service/business'

export function PreloadBusinessButton({ businessID }: PreloadBusinessButtonProps) {
  const [isLoading, setLoading] = useState(false)

  const [buttonIcon, buttonText] = isLoading
    ? ['spinner fa-spin', 'Reloading All data...']
    : ['sync', 'Reload All Data']

  async function onReload() {
    try {
      setLoading(true)
      await businessService.preloadBusinessData(businessID)
      setLoading(false)
      toast.success('Data has been set to reload over the next 24 hours.')
    } catch (e) {
      toast.error('Data could not be preloaded at this time due to an internal server error.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Typography variant="h5">Preload Business</Typography>
      <Typography variant="body1">
        If your totals don't seem to be adding up, feel free to backload all of your insights /
        pixels / custom conversion / lead data from Facebook.
        <br />
        <span className="text-warning">
          Please NOTE: This is an expensive operation, so only load if you feel confident some
          information is missing.
        </span>
      </Typography>
      <Button color="primary" variant="contained" onClick={onReload}>
        <i className={`fa fa-${buttonIcon} mr-2`} />
        {buttonText}
      </Button>
    </>
  )
}
