import './SubmissionWizard.scss'
import { toast } from 'react-toastify'
import React, { useContext } from 'react'
import { Wizard } from '@marketing-milk/frontend'
import { SubmissionWizardProps } from './SubmissionWizard.types'
import { WhatDateRange } from './steps/3-what-date-range/WhatDateRange'
import { DescribeTheIssue } from './steps/1-describe-the-issue/DescribeTheIssue'
import { WhatOfflineEvent } from './steps/2-what-offline-event/WhatOfflineEvent'
import { ReviewYourReport } from './steps/4-review-your-report/ReviewYourReport'
import { badDataSubmissionContext } from './submission-context/BadDataSubmissionContext'
import { BadDataSubmissionContextProvider } from './submission-context/BadDataSubmissionContextProvider'

export function BadDataSubmissionWizardComponent({ onSubmit }: SubmissionWizardProps) {
  const { submission } = useContext(badDataSubmissionContext)

  async function onSubmitChanges() {
    try {
      await onSubmit(submission)
    } catch (e) {
      toast.error('There was a problem submitting your bad data report')
      throw e
    }
  }

  return (
    <Wizard
      steps={[
        {
          name: 'Describe The Issue',
          desc: '',
          icon: '',
          isComplete: !!submission.desc,
          component: <DescribeTheIssue />,
        },
        {
          name: 'What Offline Event',
          desc: '',
          icon: '',
          isComplete: !!submission.eventName,
          component: <WhatOfflineEvent />,
        },
        {
          name: 'What Date Range',
          desc: '',
          icon: '',
          isComplete: !!submission.startDate && !!submission.endDate,
          component: <WhatDateRange />,
        },
        {
          name: 'Review Your Report',
          desc: '',
          icon: '',
          isComplete: true,
          component: <ReviewYourReport />,
        },
      ]}
      onSubmit={onSubmitChanges}
    />
  )
}

export function BadDataSubmissionWizard(props: SubmissionWizardProps) {
  return (
    <BadDataSubmissionContextProvider>
      <BadDataSubmissionWizardComponent {...props} />
    </BadDataSubmissionContextProvider>
  )
}
