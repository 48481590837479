import React from 'react'
import Builder from './builder'
import Store from 'app/redux/store'
import { Provider } from 'redux-zero/react'
import { ToastContainer } from 'react-toastify'
import Sentry from 'components/utility/sentry'
import '@marketing-milk/frontend/dist/frontend.css'
import { BrowserRouter } from 'react-router-dom'
import Interceptor from 'components/utility/interceptor'
import { QueryClientProvider, QueryClient } from 'react-query'
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory'
import { SessionContextProvider } from '@marketing-milk/frontend'

const reactQueryClient = new QueryClient()

export const App = () => (
  <QueryClientProvider client={reactQueryClient}>
    <SessionContextProvider>
      <Provider store={Store}>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <div style={{ height: '100%', width: '100%' }}>
              <ToastContainer style={{ zIndex: 5000 }} />
              <Interceptor />
              <Sentry />
              <Builder />
            </div>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </Provider>
    </SessionContextProvider>
  </QueryClientProvider>
)
