import React from 'react'
import Moment from 'moment'
import Quill from 'react-quill'
import Select from 'react-select'
import Switch from 'react-switch'
import Validator from 'Validator'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import ColorPicker from 'react-color'
import Compare from 'react-fast-compare'
import Connect from 'app/redux/connect'
import { SingleDatePicker } from 'react-dates'

interface IFormProps {
  form: {
    config: {
      cancel?: string
      count?: string
      save?: string
    }
    groups: Array<IFormGroups>
    lifecycle: {
      mount: undefined | Function
      update: undefined | Function
    }
  }
  save: Function
  timestamp?: any
}

interface IFormGroups {
  label: string
  rows: any
}

interface IFormState {
  data: any
  errors: {
    [key: string]: undefined | string
  }
  fields: Array<IFormStateFields>
  focused: any
  validation: any
}

interface IFormStateFields {
  column: undefined | string
  optional?: undefined | boolean
  rules: object
  disabled?: Function
}

class Form extends React.PureComponent<IFormProps, IFormState> {
  state: IFormState = {
    data: {},
    errors: {},
    fields: [],
    focused: {},
    validation: {},
  }

  componentWillMount() {
    const { groups, lifecycle = { mount: undefined, update: undefined } } = this.props.form
    let data = {}
    let fields: IFormStateFields[] = []

    if (lifecycle.mount) {
      data = lifecycle.mount()
    }

    for (let i = 0; i < groups.length; i++) {
      let group = groups[i].rows
      for (let n = 0; n < group.length; n++) {
        let row = group[n]
        for (let k = 0; k < row.length; k++) {
          let form = row[k]
          fields.push({
            column: form.column,
            rules: form.rules,
            optional: form.optional,
          })
          if (!data[form.column]) data[form.column] = ''
        }
      }
    }

    this.setState({
      data: data,
      errors: {},
      fields: fields,
    })
  }

  componentDidUpdate(oldProps: IFormProps, old: IFormState) {
    const { lifecycle } = this.props.form
    if (!Compare(old, this.state) && lifecycle && !!lifecycle.update) {
      lifecycle.update(this.state.data, this.state.errors)
    }
    if (this.props.timestamp && this.props.form.lifecycle && this.props.form.lifecycle.mount) {
      if (this.props.timestamp !== oldProps.timestamp) {
        const data = this.props.form.lifecycle.mount()
        this.setState({
          data: data,
        })
      }
    }
  }

  error = (name, status, type) => {
    let { errors } = this.state

    if (status) {
      errors[name] = type
    }

    if (errors[name] === type && !status) {
      delete errors[name]
    }

    if (errors[name] === 'empty') {
      delete errors[name]
    }

    this.setState({
      errors: errors,
    })
  }

  validation = (column, messages) => {
    const message = messages[Object.keys(messages)[0]]
      ? messages[Object.keys(messages)[0]][0]
      : null
    this.setState(_ => ({
      ..._,
      validation: {
        ..._.validation,
        [column]: message,
      },
    }))
  }

  change = (data, field) => {
    let errors = 0

    this.error(field.column, false, 'empty')
    this.error(field.column, false, 'on_save')

    // Pre Check
    if (field.preChange && !field.preChange(data)) {
      this.error(field.column, true, 'pre_lc')
      errors++
    } else {
      this.error(field.column, false, 'pre_lc')
    }

    // Post Check
    if (field.postChange && !field.postChange(data)) {
      this.error(field.column, true, 'post_lc')
      errors++
    } else {
      this.error(field.column, false, 'post_lc')
    }

    // Validation Check
    if (field.rules) {
      const validate = {
        data: {
          [field.column]: data,
        },
        rules: {
          [field.column]: field.rules,
        },
      }

      const validator = Validator.make(validate.data, validate.rules)

      if (validator.fails()) {
        this.error(field.column, true, 'validation')
        this.validation(field.column, validator.getErrors())
        errors++
      } else {
        this.error(field.column, false, 'validation')
        this.validation(field.column, [])
      }
    }

    // Update State
    this.setState(_ => ({
      ..._,
      data: {
        ..._.data,
        [field.column]: data,
      },
    }))

    if (errors === 0) {
      // After Change
      if (field.afterChange) field.afterChange(data)
    }
  }

  save = async event => {
    event.preventDefault()
    let { data, errors, fields } = this.state
    let issues = 0

    if (Object.values(errors).length > 0) {
      toast.error('Please fix the validation errors before continuing')
      issues++
    }

    for (let i = 0; i < fields.length; i++) {
      let temp = fields[i]
      if (temp.column && data[temp.column] === '' && !temp.optional) {
        errors[temp.column] = 'empty'
        issues++
      }
    }

    this.setState({
      errors: errors,
    })

    if (issues === 0) {
      this.props.save(data)
    } else {
      toast.error('Please fix the validation errors before continuing')
    }
  }

  field = (field, data) => {
    try {
      const { errors, focused } = this.state

      // Parse className
      let className = errors[field.column] ? 'form-control react-invalid' : 'form-control'

      if (className.indexOf('react-invalid') === -1 && field.invalid) {
        if (field.invalid()) {
          className = 'form-control react-invalid'
        }
      }

      // Parse input
      switch (field.type) {
        case 'select':
          // Parse filter
          const filter = field.filter || (x => x)

          // Parse options
          let options = field.options ? field.options() : []

          options = options.filter(filter)

          // Parse value
          const value = field.find ? options.find(field.find) : data[field.column]

          // Return
          return (
            <Select
              className={className.replace('form-control', '')}
              isDisabled={field.disabled ? field.disabled() : false}
              options={options}
              value={value}
              getOptionValue={field.getOptionValue}
              getOptionLabel={field.getOptionLabel}
              onChange={e => {
                field.onChange ? field.onChange(e, field, this.change) : this.change(e.id, field)
              }}
            />
          )

        case 'color_picker':
          return (
            <ColorPicker
              className={className.replace('form-control', '')}
              disabled={field.disabled ? field.disabled() : false}
              color={data[field.column] || ''}
              onChangeComplete={e => this.change(e.hex, field)}
            />
          )

        case 'quill':
          return (
            <Quill
              defaultValue={data[field.column]}
              //disabled={field.disabled ? field.disabled() : false}
              onChange={e => {
                this.setState(_ => ({
                  ..._,
                  data: {
                    ..._.data,
                    [field.column]: e,
                  },
                }))
              }}
            />
          )

        case 'file':
          return (
            <div className={`custom-file ${className}`}>
              <input
                accept={field.accept}
                className="custom-file-input"
                disabled={field.disabled ? field.disabled() : false}
                type="file"
                onBlur={field.onBlur}
                onChange={(e: any): void => {
                  let form = new FormData()
                  form.append('file', e.target.files[0])
                  field.onChange
                    ? field.onChange(form, field, this.change)
                    : this.change(form, field)
                }}
              />
              {data[field.column] !== '' ? (
                <label className="custom-file-label">
                  {data[field.column].getAll('file')[0].name}
                </label>
              ) : (
                <label className="custom-file-label">Choose file</label>
              )}
            </div>
          )

        case 'component':
          return field.render

        case 'number':
          return (
            <input
              className={className}
              disabled={field.disabled ? field.disabled() : false}
              type={field.type}
              value={data[field.column] !== '' ? data[field.column] : 0}
              onBlur={field.onBlur}
              onChange={e => {
                this.change(e.target.value, field)
              }}
            />
          )

        case 'switch':
          return (
            <div className="m-block">
              <Switch
                onChange={e => {
                  this.change(e, field)
                }}
                checked={!!data[field.column]}
              />
            </div>
          )

        default:
          return (
            <input
              className={className}
              disabled={field.disabled ? field.disabled() : false}
              type={field.type}
              value={data[field.column] || ''}
              onBlur={field.onBlur}
              onChange={e => this.change(e.target.value, field)}
            />
          )
      }
    } catch (e) {
      return (
        <div className="alert alert-danger">
          <i className="fa fa-exclamation-triangle mr-2" />
          <span>An error has occurred</span>
        </div>
      )
    }
  }

  render() {
    const { validation } = this.state
    const { form } = this.props
    const data = this.state.data
    return (
      <form className="m-form m-form--fit" onSubmit={this.save}>
        {form.groups.map((group, i) => {
          return (
            <div key={i}>
              <div className="m-form__section m-form__section--first">
                {group.label ? (
                  <div className="m-form__heading">
                    {form.config && form.config.count === 'hide' ? (
                      <h3 className="m-form__heading-title">{group.label}:</h3>
                    ) : (
                      <h3 className="m-form__heading-title">
                        {i + 1}. {group.label}:
                      </h3>
                    )}
                  </div>
                ) : null}
                {group.rows.map((row, r) => {
                  return (
                    <div className="row mt-3" key={r}>
                      {row.map((field, f) => {
                        return (
                          <div className={field.size === 'half' ? 'col-md-6' : 'col-md-12'} key={f}>
                            <div className="form-group m-form__group">
                              {!field.show || field.show() ? (
                                <div>
                                  <Label field={field} validation={validation} />
                                  {this.field(field, data)}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
              <div className="m-form__seperator m-form__seperator--dashed mb-3" />
            </div>
          )
        })}
        {form.config.save !== 'hide' ? (
          <div className="m-portlet__foot m-portlet__no-border m-portlet__foot--fit mt-4">
            <div className="row">
              <div className="col-md-12 text-right">
                {form.config.cancel ? (
                  <Link to={form.config.cancel} className="btn btn-secondary cancel-button">
                    Cancel
                  </Link>
                ) : null}
                <button className="btn btn-success ml-2" type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </form>
    )
  }
}

const Label = props => {
  const { field, validation } = props
  return (
    <div style={{ height: 30 }}>
      <label>
        {field.label}

        {field.optional ? null : <span className={'text-brand'}>*</span>}

        {validation[field.column] ? (
          <div className="text-danger">
            <i className="fa fa-exclamation-triangle ml-r-2" />
            <span>{validation[field.column]}</span>
          </div>
        ) : null}
      </label>
    </div>
  )
}

export default Connect(Form)
