import React from 'react'
import Selector from './selector'

export default () => (
  <div className="kt-header-menu-wrapper">
    <div
      id="kt_header_menu"
      className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default"
    >
      <Selector />
    </div>
  </div>
)
