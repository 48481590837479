import Steps from './steps'
import Stages from '../stages'
import Alert from 'sweetalert2'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import FunnelActions from 'app/redux/actions/pages/business/funnel'
import StageActions from 'app/redux/actions/pages/business/funnel/stage'

class Editor extends PureComponent<Redux> {
  render() {
    const { active, stages } = this.props.pages.business.funnel
    if (active === null) {
      throw new Error('No business stage was selected')
    }

    const current = stages[active]

    const onReset = async () => {
      // @ts-ignore - The types are confusing the compiler
      const result = await Alert.fire({
        title: 'Are you sure?',
        text: `This will reset your funnel to a blank state`,
        type: 'question',
        showCancelButton: true,
        cancelButtonColor: '#36a3f7',
        confirmButtonText: 'Yes, choose another preset',
        confirmButtonColor: '#f4516c',
        focusCancel: true,
      })

      if (result.value) {
        FunnelActions.reset()
      }
    }

    const onDelete = async (): Promise<void> => {
      // @ts-ignore - The types are confusing the compiler
      const result = await Alert.fire({
        title: 'Are you sure?',
        text: `This will delete the stage and its sources`,
        type: 'question',
        showCancelButton: true,
        cancelButtonColor: '#36a3f7',
        confirmButtonText: 'Yes, delete this stage',
        confirmButtonColor: '#f4516c',
        focusCancel: true,
      })

      if (result.value) {
        StageActions.clearStage()
      }
    }

    return (
      <div>
        <div className="row">
          <div className="col-2">
            <button className="btn btn-warning" onClick={onReset}>
              Reset Funnel
            </button>
          </div>
          <div className="col-6">
            <h1>{current.name}</h1>
          </div>
          <div className="col-4 text-right">
            <button className="btn btn-danger" onClick={onDelete}>
              Remove Stage
            </button>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-2">
            <Stages />
          </div>
          <div className="col-10">
            <Steps.Info {...current} />
            <Steps.Features {...current} />
            <Steps.Groups {...current} />
          </div>
        </div>
      </div>
    )
  }
}

export default Connect(Editor)
