import { getInitials, Loading, ProfilePicture, MaterialDataTable } from '@marketing-milk/frontend'
import React from 'react'
import { GridColumnTypesRecord, GridCellParams, GridColDef } from '@material-ui/x-grid'
import { UserWithBusiness } from '@marketing-milk/interfaces'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { TableMenu } from './TableMenu'
import { useFetchAllUsers } from '../../../../hooks'
import { formatDistanceToNow } from 'date-fns'

export enum UserTableTypes {
  UserName = 'UserName',
  Roles = 'Roles',
  Actions = 'Actions',
  AcceptedInvite = 'AcceptedInvite',
  Created_At = 'Created_At',
}

export const userTableColumns: Array<GridColDef> = [
  {
    field: 'profile',
    type: UserTableTypes.UserName,
    headerName: 'Name',
  },
  {
    field: 'createdAt',
    type: UserTableTypes.Created_At,
    headerName: 'Created',
  },
  {
    field: 'acceptedInvite',
    type: UserTableTypes.AcceptedInvite,
    headerName: 'Accepted Invite',
  },
  {
    field: '',
    type: UserTableTypes.Actions,
    headerName: '',
  },
]
const userColTypes = (
  history: ReturnType<typeof useHistory>,
  refetchUsers: () => void,
  canManageRoles: boolean
): GridColumnTypesRecord => {
  return {
    [UserTableTypes.UserName]: {
      type: 'string',
      headerName: 'Profile',
      disableColumnMenu: true,
      flex: 4,
      renderCell: ({ row: data }: GridCellParams) => {
        const row = data as UserWithBusiness
        return (
          <div
            style={{
              display: 'contents',
              cursor: 'pointer',
            }}
            onClick={() => history.push(`${row.id}/details`)}
          >
            <ProfilePicture
              initials={getInitials(row.firstName, row.lastName)}
              image={row.profilePicture}
            />
            &nbsp;&nbsp;&nbsp;
            <div>
              <Typography style={{ height: 15 }}>{`${row.firstName} ${row.lastName}`}</Typography>
              <Typography>{`${row.email}`}</Typography>
            </div>
          </div>
        )
      },
    },
    [UserTableTypes.Created_At]: {
      type: 'string',
      headerName: 'Created',
      disableColumnMenu: true,
      flex: 2,
      renderCell: ({ row }: GridCellParams) => {
        return (
          <>
            {!!row.createdAt
              ? formatDistanceToNow(new Date(row.createdAt), { addSuffix: true })
              : 'N/A'}
          </>
        )
      },
    },
    [UserTableTypes.AcceptedInvite]: {
      type: 'string',
      headerName: 'Accepted Invite',
      disableColumnMenu: true,
      flex: 2,
      renderCell: ({ row }: GridCellParams) => {
        return <>{row.acceptedInvite ? <VerifiedUserIcon /> : <ScheduleIcon />}</>
      },
    },

    [UserTableTypes.Actions]: {
      type: 'button',
      headerName: '',
      disableColumnMenu: true,
      flex: 1,
      resizable: false,
      renderCell: ({ row }: GridCellParams) => {
        const mmUser = row as UserWithBusiness

        return (
          <TableMenu canManageRoles={canManageRoles} mmUser={mmUser} refetchUsers={refetchUsers} />
        )
      },
    },
  }
}

export type UsersTableProps = {
  users: UserWithBusiness[] | undefined
  userHook: ReturnType<typeof useFetchAllUsers>
  canManageRoles: boolean
}

export function UsersTable({
  users: data,
  userHook: { count, pageSize, refetch, isLoading, setPaginationParams },
  canManageRoles,
}: UsersTableProps) {
  const history = useHistory()

  if (isLoading) return <Loading />

  return (
    <div>
      <MaterialDataTable
        height={'calc(100vh - 15rem)'}
        sortingOrder={['asc', 'desc']}
        rows={data ? data : []}
        rowHeight={45}
        headerHeight={50}
        density={'comfortable'}
        columnBuffer={7}
        columns={userTableColumns}
        columnTypes={userColTypes(history, refetch, canManageRoles)}
        pagination
        rowCount={count}
        paginationMode={'server'}
        onPageChange={change => {
          setPaginationParams(prevParams => ({ ...prevParams, page: change.page + 1 }))
        }}
        pageSize={pageSize}
        scrollEndThreshold={10}
        isCellEditable={undefined}
      />
    </div>
  )
}
