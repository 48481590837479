import { MaterialDataTable } from '@marketing-milk/frontend'
import { Franchise } from '@marketing-milk/interfaces'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
} from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import { Connected } from 'app/interface/redux'
import { BusinessRaw } from 'app/interface/redux/types/data/generic/businesses'
import Connect from 'app/redux/connect'
import React, { useEffect, useState } from 'react'

interface FranchiseAssignBusinessesProps {
  franchise: Franchise
  onConfirm: (businessIds: number[]) => void
}

export const FranchiseAssignBusinessesComponent = (
  props: FranchiseAssignBusinessesProps & Connected
) => {
  const [open, setOpen] = useState<boolean>(false)
  const reduxBusinesses = props.data.generic.businesses.data
  const [filter, setFilter] = useState<string>('')
  const [businesses, setBusinesses] = useState<BusinessRaw[]>(reduxBusinesses)
  const [selectedBusinessIds, setSelectedBusinessIds] = useState<number[]>([])

  useEffect(() => {
    setBusinesses(reduxBusinesses.filter(business => business.businessName.includes(filter)))
  }, [reduxBusinesses, filter])

  const handleAdd = async () => {
    handleClose()
    props.onConfirm(selectedBusinessIds)
  }

  const handleClose = () => {
    setFilter('')
    setOpen(false)
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Add Businesses
      </Button>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle style={{ minWidth: '600px' }}>
            Add Businesses To {props.franchise.name}
          </DialogTitle>
          <DialogContent>
            <Input
              fullWidth
              style={{ marginBottom: '1em' }}
              value={filter}
              onChange={e => setFilter(e.target.value)}
              placeholder="Business Name"
              startAdornment={
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              }
            />
            <MaterialDataTable
              height={400}
              checkboxSelection
              pagination
              paginationMode="client"
              pageSize={5}
              columns={[{ field: 'businessName', headerName: 'Business Name', flex: 1 }]}
              rows={businesses}
              onSelectionModelChange={e => {
                setSelectedBusinessIds(e.selectionModel.map(id => Number(id)))
              }}
            />
          </DialogContent>
          <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button type="submit" onClick={handleAdd} color="primary" variant="contained">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export const FranchiseAssignBusinesses = Connect(FranchiseAssignBusinessesComponent)
