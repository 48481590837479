import { NestAPI } from 'app/nestapi'
import { DownloadExampleFile } from './download-example-file'
import React, { useEffect, useState } from 'react'
import { Loading } from 'components/reusable/loading'
import { ExampleFile } from '@marketing-milk/interfaces'
import { defaultExampleFilesState, ExampleFilesState } from './ExampleFiles.type'

export function ExampleFiles() {
  const [{ files, showSpinner }, setState] = useState<ExampleFilesState>(defaultExampleFilesState)

  async function fetchExamples(): Promise<void> {
    const exampleFiles: ExampleFile[] = await NestAPI.get('/uploads/examples')
    setState({
      files: exampleFiles,
      showSpinner: false,
    })
  }

  useEffect(() => {
    fetchExamples()
  }, [])

  return (
    <div>
      <div className="row-bottom text-center">
        <h2>Example Files</h2>
      </div>
      {showSpinner && (
        <div className="mt-3 text-center">
          <Loading />
        </div>
      )}
      {!showSpinner && (
        <div className="mt-3 row">
          {files.map(exampleFile => (
            <DownloadExampleFile file={exampleFile} key={exampleFile.key} />
          ))}
        </div>
      )}
    </div>
  )
}
