import {
  AttrDuration,
  AttrWindowLevel,
  AttrWindowsBySourceType,
  InsightAttributionSourceType,
} from '@marketing-milk/interfaces'
import React from 'react'
import Select from 'react-select'
import Portlet from 'components/reusable/portlet'
import { AttributionType } from '../AttributionCheckerTypes'
import { capitalize } from 'lodash'
import { Loading } from '@marketing-milk/frontend'

export interface AttributionWindowProps {
  window: AttrWindowsBySourceType
  onChangeWindow: (
    idx: number,
    value: AttrDuration,
    sourceType: InsightAttributionSourceType,
    type: AttributionType
  ) => void
  onDeleteWindow: (idx: number) => void
  idx: number
  loading: boolean
  total1?: number
  total2?: number
}

export const attrDurations: AttrDuration[] = ['1d', '7d', '28d']
export const attrTypes: Array<keyof AttrWindowLevel> = ['click', 'view']
export const attrReportTypes: InsightAttributionSourceType[] = ['offline', 'online']

export const AttributionWindow = ({
  window,
  idx,
  loading,
  total1,
  total2,
  onChangeWindow,
  onDeleteWindow,
}: AttributionWindowProps) => {
  const options = attrDurations.map(_ => ({
    label: _,
    value: _,
  }))

  return (
    <Portlet
      title={`Attribution ${idx + 1} ${idx === 0 ? '(Default)' : ''}`}
      headTools={
        idx > 0 ? (
          <i
            data-testid="delete-icon"
            className="fa fa-trash-alt text-danger cursor-pointer"
            onClick={() => onDeleteWindow(idx)}
          />
        ) : undefined
      }
    >
      <div className="row">
        {attrReportTypes.map((insightReportType: InsightAttributionSourceType) => (
          <div className="col-sm-6 col-xs-12">
            <h5>{capitalize(insightReportType)}</h5>
            <label htmlFor={`${insightReportType}-view-${idx}`}>{`${capitalize(
              insightReportType
            )} View`}</label>
            <Select
              key={`attr-select-${insightReportType}-view-${idx}`}
              name={`${insightReportType}-view-${idx}`}
              inputId={`${insightReportType}-view-${idx}`}
              options={options}
              isDisabled={idx === 0}
              value={options.find(_ => _.value === window[insightReportType].view)}
              onChange={(opt: any) => onChangeWindow(idx, opt.value, insightReportType, 'view')}
            />
            <br />
            <label htmlFor={`${insightReportType}-click-${idx}`}>{`${capitalize(
              insightReportType
            )} Click`}</label>
            <Select
              key={`attr-select-${insightReportType}-click-${idx}`}
              name={`${insightReportType}-click-${idx}`}
              inputId={`${insightReportType}-click-${idx}`}
              options={options}
              isDisabled={idx === 0}
              value={options.find(_ => _.value === window[insightReportType].click)}
              onChange={(opt: any) => onChangeWindow(idx, opt.value, insightReportType, 'click')}
            />
          </div>
        ))}
      </div>
      <br />
      {!loading ? (
        <>
          <h5>Total Attributions</h5>
          <div className="row">
            <span className="col-5 attribution-window-total-1">
              {total1 !== undefined ? total1 : '-'}
            </span>
            <span className="col-2 text-center">vs.</span>
            <span className="col-5 text-right attribution-window-total-2">
              {total2 !== undefined ? total2 : '-'}
            </span>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </Portlet>
  )
}
