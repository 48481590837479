import { franchiseService, MaterialDataTable, useGetAllBusinesses } from '@marketing-milk/frontend'
import { Franchise } from '@marketing-milk/interfaces'
import { Button, Input, InputAdornment, makeStyles, Paper, Typography } from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Select, { components } from 'react-select'
import { toast } from 'react-toastify'
import { FranchiseAssignBusinesses } from './FranchiseAssignBusinesses'
import { FranchiseRemoveBusinesses } from './FranchiseRemoveBusinesses'

export const useButtonStyles = makeStyles({
  button: {
    backgroundColor: 'transparent',
    color: '#676E64',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#545650',
    },
  },
})

export const FranchiseEdit = () => {
  const classes = useButtonStyles()
  const history = useHistory()
  const params: { id?: string } = useParams()
  const [franchise, setFranchise] = useState<Franchise>()
  const [selectedBusinessIds, setSelectedBusinessIds] = useState<number[]>([])
  const [filter, setFilter] = useState<string>('')

  useEffect(() => {
    franchiseService.getOne(Number(params.id)).then(franchise => setFranchise(franchise))
  }, [params.id])

  const addSelectedBusinesses = async businessIds => {
    if (franchise) {
      await franchiseService.addBusinesses(franchise.id, businessIds)
      setFranchise(await franchiseService.getOne(franchise.id))
    } else {
      toast.error('There was an error loading this franchise.')
    }
  }

  const removeSelectedBusinesses = async () => {
    if (franchise) {
      await franchiseService.removeBusinesses(franchise.id, selectedBusinessIds)
      setFranchise(await franchiseService.getOne(franchise.id))
      setSelectedBusinessIds([])
    } else {
      toast.error('There was an error loading this franchise.')
    }
  }

  return (
    <Paper style={{ minHeight: 'calc(100vh - 100px)' }}>
      <div className="container-fluid p-4">
        <div style={{ display: 'inline-flex', alignItems: 'baseline', paddingBottom: '5px' }}>
          <Button
            disableRipple
            className={classes.button}
            onClick={e => {
              e.preventDefault()
              history.push('/admin/franchises/list')
            }}
          >
            <ArrowBackIcon />
            &nbsp;&nbsp;
            <Typography>Back to Franchises</Typography>
          </Button>
        </div>

        {!franchise ? (
          <h2>Loading...</h2>
        ) : (
          <div>
            <h2>{franchise.name}</h2>
            <div>
              <FranchiseAssignBusinesses franchise={franchise} onConfirm={addSelectedBusinesses} />
              <FranchiseRemoveBusinesses
                disabled={!selectedBusinessIds.length}
                franchise={franchise}
                onConfirm={removeSelectedBusinesses}
              />
              <Input
                fullWidth
                style={{ marginTop: '2em', marginBottom: '1em' }}
                value={filter}
                onChange={e => setFilter(e.target.value)}
                placeholder="Business Name"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                }
              />
            </div>
            <MaterialDataTable
              checkboxSelection
              disableSelectionOnClick
              pagination
              paginationMode="client"
              columns={[{ field: 'businessName', headerName: 'Business Name', flex: 1 }]}
              rows={
                franchise.businesses?.filter(business => business.businessName.includes(filter)) ??
                []
              }
              onSelectionModelChange={e => {
                setSelectedBusinessIds(e.selectionModel.map(id => Number(id)))
              }}
            />
          </div>
        )}
      </div>
    </Paper>
  )
}
