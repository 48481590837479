import { branchService } from '@marketing-milk/frontend'
import { Branch, PaginatedBranchResponse, PaginationQueryParams } from '@marketing-milk/interfaces'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export function useFetchAllBranches() {
  const pageSize = 15
  const [branches, setBranches] = useState<Branch[]>([])
  const [paginationParams, setPaginationParams] = useState<PaginationQueryParams>({
    searchTerm: '',
    searchProp: 'name',
    sortProp: 'createdAt',
    sortDirection: 'DESC',
    page: 1,
    take: pageSize,
  })

  const query = useQuery<PaginatedBranchResponse, string>(
    ['branches-paginated', paginationParams],
    () => branchService.getPaginated(paginationParams),
    {
      keepPreviousData: true,
      staleTime: 5000,
      retry: false,
    }
  )
  const { data } = query

  useEffect(() => {
    if (!data) return
    setBranches(data.branches)
  }, [data])

  return {
    branches,
    count: data?.count || 0,
    current: data?.branches?.length,
    pageSize,
    ...paginationParams,
    setPaginationParams,
    ...query,
  }
}
