import React, { useState } from 'react'
import { Auth0RolePermission } from '@marketing-milk/interfaces'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { AddPermissionsModal } from './AddPermissionsModal'
import { DeletePermissionModal } from './DeletePermissionModal'
import { Alert } from '@material-ui/lab'

export interface PermissionsSectionProps {
  permissions?: Auth0RolePermission[]
  onDeletePermission: (permission: Auth0RolePermission) => void
  onAddPermissions: (permissions: Auth0RolePermission[]) => void
}

export function PermissionsSection(props: PermissionsSectionProps) {
  const [addPermsOpen, setAddPermsOpen] = useState(false)
  const [permissionToDelete, setPermissionToDelete] = useState<Auth0RolePermission | undefined>()
  return (
    <div>
      <div className="d-flex mb-4">
        <Typography variant="body1">
          View permissions assigned to your role, as well as remove them or assign more.
        </Typography>
        <div className="mr-0 ml-auto d-flex">
          <div className="center-vertical" style={{ flex: 'none' }}>
            <Button variant="contained" color="primary" onClick={() => setAddPermsOpen(true)}>
              Add Permissions
            </Button>
          </div>
        </div>
      </div>
      {props.permissions && props.permissions.length > 0 ? (
        <TableContainer className="mb-4" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.permissions?.map(permission => (
                <TableRow key={permission.value}>
                  <TableCell component="th" scope="row">
                    {permission.value}
                  </TableCell>
                  <TableCell align="left">{permission.description}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => setPermissionToDelete(permission)}>
                      <DeleteIcon className="text-danger" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert
          variant="standard"
          severity="info"
          className="mt-3"
          action={
            <Button
              className="bg-white"
              disableElevation
              variant="contained"
              onClick={() => setAddPermsOpen(true)}
            >
              Add Permissions
            </Button>
          }
        >
          <Typography variant="h6" className="mt-n1">
            No Permissions Assigned
          </Typography>
          No permissions have been added to this role. Once permissions have been assigned they will
          show up here.
        </Alert>
      )}
      <AddPermissionsModal
        isOpen={addPermsOpen}
        setIsOpen={isOpen => setAddPermsOpen(isOpen)}
        permissions={props.permissions}
        onSubmit={permissions => props.onAddPermissions(permissions)}
      />
      <DeletePermissionModal
        deletePermission={permissionToDelete}
        onDelete={permission => {
          props.onDeletePermission(permission)
          setPermissionToDelete(undefined)
        }}
        onCancel={() => setPermissionToDelete(undefined)}
      />
    </div>
  )
}
