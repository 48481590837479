import { getInitials, ProfilePicture, userService } from '@marketing-milk/frontend'
import { User } from '@marketing-milk/interfaces'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Business from '../../../../../../../../app/interface/data/business'
import Popover from 'components/reusable/popover'

interface BusinessAuthorizerProps {
  assignAuthorizer: (authorizer: User) => void
  business: Business
  title: string
  authorizerID?: number | null
  popover?: string
}

const BusinessAuthorizer = (props: BusinessAuthorizerProps) => {
  const [users, setUsers] = useState<User[]>()
  const [currentAuthorizer, setCurrentAuthorizer] = useState<{
    value: number
    label: any
    searchables: string[]
  }>()
  const [waitingForUsers, setWaitingForUsers] = useState(false)
  const [error, setError] = useState(false)

  const getBusinessUsers = async () => {
    try {
      setWaitingForUsers(true)
      const response = await userService.getAll()
      const fbConnectedBizUsers = response.filter(u => u.fbConnected)
      setUsers(fbConnectedBizUsers)
    } catch (e) {
      console.log(`err? ${e}`)
      setError(true)
    } finally {
      setWaitingForUsers(false)
    }
  }
  const formatUser = (user: User) => ({
    value: user.id,
    label: (
      <div className="col-12 d-flex">
        <ProfilePicture
          initials={getInitials(user.firstName, user.lastName)}
          image={user.profilePicture}
          size={50}
        />
        <div className="ml-4">
          <h5 className="font-weight-bold">
            {user.firstName} {user.lastName}
          </h5>
          <div>{`${user.email}`}</div>
        </div>
      </div>
    ),
    searchables: [user.firstName, user.lastName, user.email],
  })

  const handleAuthorizerSelection = selected => {
    const selectedUser = users?.find(({ id }) => id === selected.value)

    if (selectedUser) {
      setCurrentAuthorizer(selected)
      props.assignAuthorizer(selectedUser)
    }
  }

  useEffect(() => {
    getBusinessUsers()
  }, [])

  useEffect(() => {
    const authorizer = users?.find(u => u.id === props.authorizerID)

    if (authorizer) {
      setCurrentAuthorizer(formatUser(authorizer))
    }
  }, [users])

  const styles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '75px',
      height: '75px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '75px',
      padding: '0 6px',
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '75px',
    }),
  }

  return (
    <>
      <div className="d-flex flex-column align-items-start px-2 py-2">
        <span className="pb-2">
          {props.title}
          {!!props.popover && (
            <>
              &nbsp;<Popover>{props.popover}</Popover>
            </>
          )}
        </span>
        <Select
          className="col-12 pl-0"
          options={users?.map(formatUser)}
          onChange={handleAuthorizerSelection}
          value={currentAuthorizer}
          isDisabled={error}
          isLoading={waitingForUsers}
          filterOption={(option, input) => {
            const { searchables } = option.data
            for (const search of searchables) {
              if (search.toLowerCase().includes(input.toLowerCase())) {
                return true
              }
            }
            return false
          }}
          styles={styles}
        />
        {error && <p className="text-danger">Failed to get Business Users</p>}
      </div>
    </>
  )
}

export default BusinessAuthorizer
