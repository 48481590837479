import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { FullscreenDialog } from '@marketing-milk/frontend'
import { VerifyUploadModalProps } from './VerifyUploadModal.types'
import { ValidationTable } from './improved-validation-table/ValidationTable'

export function VerifyUploadModal({
  upload,
  children,
  version,
  defaultOpen,
}: VerifyUploadModalProps) {
  const [isOpen, setModal] = useState(defaultOpen ?? false)
  const latestVersion = version ?? upload.versions[0]

  function toggle() {
    setModal(_ => !_)
  }

  if (!latestVersion) {
    return null
  }

  return (
    <>
      <div className="cursor-pointer" onClick={toggle}>
        {children ?? (
          <Button color="primary" variant="contained">
            <i className="fa fa-file-spreadsheet" />
          </Button>
        )}
      </div>
      {isOpen && (
        <FullscreenDialog
          title="Upload Preview"
          isOpen
          onToggle={toggle}
          content={<ValidationTable upload={upload} version={latestVersion} />}
        />
      )}
    </>
  )
}
