import React from 'react'
import { toast } from 'react-toastify'
import Connect from 'app/redux/connect'
import Redux, { Connected } from 'app/interface/redux'

const Denied = ({
  core: {
    session: { active, user },
  },
  location: { pathname },
  history,
}: Connected) => {
  const allowed = ['', '/', '/login', '/admin/login']

  if (active && allowed.indexOf(pathname) === -1) {
    toast.error(`You're not allowed to do this`)
  }

  history.push(active ? '/admin/dashboard' : '/admin/login')

  return null
}

const map = (state: Redux) => ({
  core: {
    session: state.core.session,
  },
})

export default Connect(Denied, map)
