import React from 'react'
import './DataCheckerNotes.scss'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'

export function DataCheckerNotes() {
  return (
    <>
      <Accordion>
        <AccordionSummary
          aria-controls="why-did-my-data-check-fail"
          id="why-did-my-data-check-fail"
        >
          Why did my data check fail?
        </AccordionSummary>
        <AccordionDetails>
          <img
            alt="data uploader help sheet"
            src="/assets/admin/img/Data_Uploader_Info.png"
            height={800}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
