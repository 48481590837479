import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
} from '@material-ui/core'
import React from 'react'

type EmailDetailsProps = {
  title: JSX.Element | string
  body: JSX.Element | string
  dialogActions: React.ReactNode
}

export function EmailDetails({
  title,
  body,
  dialogActions,
  ...props
}: EmailDetailsProps & DialogProps) {
  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  )
}
