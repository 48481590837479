import { SidebarLinks } from 'app/config/sidebar'
import React from 'react'
import Types from './types'

export const Navigation = () => {
  let Temp

  return (
    <ul className="kt-menu__nav">
      {SidebarLinks.filter(x => !x.hidden || (!!x.hidden && x.hidden !== true))?.map(
        (element, i) => {
          Temp = Types[element.type]
          return (
            <Temp
              className={`${element.text}-${element.hidden ? 'hidden' : 'in-view'}`}
              key={i}
              {...element}
            />
          )
        }
      )}
    </ul>
  )
}
