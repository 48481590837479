import React from 'react'

interface IEmptyProps {
  body?: string
}

class Empty extends React.Component<IEmptyProps> {
  render() {
    const { body } = this.props
    return (
      <div>
        <i className="fa fa-warning fa-2x" />
        <h4>Nothing To Display</h4>
        {body ? <p>{body}</p> : <p>There is no data to show.</p>}
      </div>
    )
  }
}

export default Empty
