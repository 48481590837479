import Error from './error'
import Session from './session'
import Business from './business'
import Employee from './employee'
import UserManagement from './user-management'
import { Master } from './master'
import { BranchList } from './branch/BranchList'
import { FranchiseList } from './franchise/FranchiseList'
import { FranchiseEdit } from './franchise/FranchiseEdit'
import { OwnershipGroupList } from './ownership-group/OwnershipGroupList'
import { OwnershipGroupEdit } from './ownership-group/OwnershipGroupEdit'

export default {
  Error,
  Master,
  Session,
  Business,
  Employee,
  UserManagement,
  BranchList,
  FranchiseList,
  FranchiseEdit,
  OwnershipGroupList,
  OwnershipGroupEdit,
}
