import Pods from './pods'
import Users from './users'
import Branches from './branches'
import Businesses from './businesses'

export default {
  fetch: async () => {
    await Pods.init()
    await Users.init()
    await Branches.init()
    await Businesses.init()
  },
}
