import Presets from './presets'
import Sources from './sources'
import Funnel from 'app/interface/redux/types/data/funnel'

const Default: Funnel = {
  presets: Presets,
  sources: Sources,
}

export default Default
