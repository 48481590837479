import React from 'react'
import Tabs from './tabs'
import Wizard from 'components/base/wizard'

export default () => {
  return (
    <div>
      <h4>Additional Settings</h4>
      <Wizard
        steps={[
          {
            text: 'Data Indicators',
            component: <Tabs.Indicators />,
          },
          {
            text: 'Leads Polling',
            component: <Tabs.LeadPolling />,
          },
          {
            text: 'Other',
            component: <Tabs.Other />,
          },
        ]}
        type="vertical"
        portlet={false}
        buttons={true}
      />
    </div>
  )
}
