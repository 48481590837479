import React from 'react'
import Business from 'app/interface/data/business'
import { Grid, TextField, Typography } from '@material-ui/core'

export function IngestAPIBridge({ business }: { business: Business }) {
  return (
    <div>
      <Typography variant="h4">Ingest</Typography>
      <Typography variant="body1">
        The ingest access token is used to authenticate your API Bridges when uploading directly to
        the Ingestor.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField label="Ingest Token" fullWidth value={business.main.ingest_access_token} />
        </Grid>
      </Grid>
    </div>
  )
}
