import React from 'react'
import StageActions from 'app/redux/actions/pages/business/funnel/stage'

export default () => (
  <div className="text-right">
    <button className="btn btn-info" onClick={StageActions.addGroup}>
      <i className="fa fa-plus" />
    </button>
  </div>
)
