import CardComponent from './card'
import React, { PureComponent } from 'react'

export interface Props {
  cards: Card[]
}

interface State {
  active: number | null
}

export interface Card {
  component: any
  icon: string
  text: string
}

export default class extends PureComponent<Props, State> {
  state: State = {
    active: null,
  }

  setFocus = (index: number): void => {
    const { active } = this.state
    this.setState({
      active: active === index ? null : index,
    })
  }

  render() {
    const { cards } = this.props
    const { active } = this.state
    return (
      <div className="accordion accordion-light accordion-toggle-arrow">
        {cards.map((card: Card, i: number) => (
          <CardComponent active={i === active} key={i} onClick={() => this.setFocus(i)} {...card} />
        ))}
      </div>
    )
  }
}
