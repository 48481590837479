import React, { useEffect, useState } from 'react'
import { capitalize } from '@material-ui/core'
import { ValidationTableProps } from './ValidationTable.types'
import { ValidationTableDataErrors } from './data-errors/DataErrors'
import { ValidationTableFormatErrors } from './format-errors/FormatErrors'
import { clientUploadService, Loading, useCreateFetchHook } from '@marketing-milk/frontend'

export function ValidationTable({ upload, version }: ValidationTableProps) {
  const [refresh, setRefresh] = useState(0)
  const displayedVersion = version ?? upload.versions[0]!

  const displayedVersionReport = useCreateFetchHook(
    () => clientUploadService.getValidatedFile(upload.businessID, upload.id, displayedVersion.id),
    refresh
  )

  useEffect(() => {
    setRefresh(_ => _ + 1)
  }, [upload, version])

  if (!displayedVersion) {
    return null
  }

  if (!displayedVersionReport) {
    return <Loading />
  }

  // this is an edge case for clients using the event name Purchase and having had bad data.
  // I verify they dont have the event name header, cus if they do have the header these errors will simply display as normal
  // messy logic needed to ensure that if an event name is not yet chosen we remind the user to choose one, else the validation will fail and not display an error
  const hasEventNameError =
    displayedVersionReport.type === 'data' &&
    !displayedVersionReport.headers.find(_ => _ === 'event_name') &&
    displayedVersionReport.errors.filter(_ => _.errors.filter(_ => _.field === 'event_name').length)
      .length > 0

  return (
    <>
      <div className="row p-2">
        <div className="col-6">
          <h4>
            {displayedVersion.fileName}
            <sup className="ml-1">v{displayedVersion.version}.0</sup>
          </h4>
        </div>
        <div className="col-6 text-right">
          {displayedVersionReport?.errorCount > 0 && (
            <h4 className="text-danger">
              {displayedVersionReport?.errorCount} {capitalize(displayedVersionReport?.type)} Issues
              Found
            </h4>
          )}
          {displayedVersionReport?.errorCount === 0 && (
            <h4 className="text-success">No Issues Found</h4>
          )}
        </div>
      </div>
      {hasEventNameError && (
        <div className=" p-2 alert-danger">
          This Upload has errors with the provided event name. Be sure to choose an event name to
          see the errors.
        </div>
      )}
      {displayedVersionReport.type === 'data' ? (
        <ValidationTableDataErrors report={displayedVersionReport} upload={upload} />
      ) : (
        <ValidationTableFormatErrors
          data-testid="format-table"
          report={displayedVersionReport}
          upload={upload}
        />
      )}
    </>
  )
}
