import { ReactNode } from 'react'

export interface TableProps<Rows> {
  columns: Column<Rows>[]
  rows: Rows[]
  count: number
  page: number
  pageSize: number
  changePage: (page: number) => void
}

export interface Column<Params> {
  text: ReactNode
  value?: (props: Params) => ReactNode
  width?: number
  sortBy?: (props: Params) => ReactNode
}

export interface TableState {
  sortedHeader?: string
  sortDirection: 'up' | 'down'
}

export const defaultState: TableState = {
  sortDirection: 'down',
}
