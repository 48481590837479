import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import Portlet from 'components/base/portlet'
import Loading from 'components/message/loading'
import { businessService } from '../../../../../../app/service/business'
import { EventMapping, EventMappingDay } from '@marketing-milk/interfaces'
import { head, last } from 'lodash'
import Popover from '../../../../../../components/reusable/popover'
import Emoji from 'react-emoji-render'
import { CollapsibleTableProps, Column, MaterialTable } from '@marketing-milk/frontend'

interface State {
  events: EventMapping[]
  loaded: boolean
}

class EventMappings extends PureComponent<Redux, State> {
  state: State = {
    events: [],
    loaded: false,
  }

  componentDidMount() {
    const { id } = this.props.pages.business
    if (id) {
      return this.fetch()
    }
  }

  fetch = async (): Promise<void> => {
    const { id } = this.props.pages.business
    const result = await businessService.getEventMappings(id!)
    this.setState({
      events: result,
      loaded: true,
    })
  }

  columns: Array<Column<EventMapping>> = [
    {
      text: 'Incoming',
      value: _ => _.incoming,
    },
    {
      text: 'New',
      value: _ => _.new,
    },
    {
      text: 'Repeat',
      value: _ => _.repeating,
    },
    {
      text: 'Version',
      value: _ => _.version,
    },
    {
      text: (
        <>
          <span className="mr-2">Dates</span>
          <Popover>
            The below dates are the minimum and max ranges for the events. Expands to see all of the
            dates for this version
          </Popover>
        </>
      ),
      value: _ => {
        const allDays = _.days.map(_ => _.day)
        return (
          <>
            <i className="fa fa-calendar"></i>
            <span className="ml-2">
              {allDays.length > 0 &&
                `${head(allDays)} ${allDays.length > 1 ? ' -> ' + last(allDays) : ''}`}
            </span>
          </>
        )
      },
    },
  ]

  collapsibleTable: CollapsibleTableProps<EventMapping, EventMappingDay> = {
    rows: _ => _.days,
    columns: [
      {
        text: 'Day',
        value: _ => _.day,
      },
      {
        text: 'Bad Data',
        value: _ => _.isBad && <Emoji className="emoji" text=":frowning:" />,
      },
      {
        text: 'Frozen',
        value: _ => _.isFrozen && <Emoji className="emoji" text=":cold_face:" />,
      },
    ],
    pagination: {
      perPage: {
        default: 10,
        options: [10, 25, 50, 100, 250],
      },
    },
  }

  render() {
    const { events, loaded } = this.state
    return (
      <Portlet title="Event Mappings">
        <p>This is how offline events will be mapped when uploaded to Facebook</p>
        <div className="mt-5">
          {loaded ? (
            <MaterialTable
              columns={this.columns}
              rows={events}
              collapse={this.collapsibleTable}
              pagination={{
                perPage: {
                  default: 25,
                  options: [25, 50],
                },
              }}
            />
          ) : (
            <Loading />
          )}
        </div>
      </Portlet>
    )
  }
}

export default Connect(EventMappings)
