import React from 'react'
import { InputReportDesc } from './input-report-desc/InputReportDesc'
import { InputReportName } from './input-report-name/InputReportName'

export function DescribeTheIssue() {
  return (
    <>
      <h4>What's going on with your data?</h4>
      <InputReportName />
      <InputReportDesc />
    </>
  )
}
