import React, { useMemo } from 'react'
import Select from 'react-select'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import Input from 'components/base/form/input'
import StageActions from 'app/redux/actions/pages/business/funnel/stage'
import useGetHistoricalMatchRates from './useGetHistoricalMatchRate'
import { MatchRateWithId } from './useGetHistoricalMatchRate'
import { BusinessManagerGroup, ExtrapolationType } from '@marketing-milk/interfaces'

interface Props extends Redux {
  group: BusinessManagerGroup
  index: number
}

const Info = ({
  group,
  index,
  data: {
    funnel: { sources },
  },
  pages,
}: Props) => {
  const businessID = pages.business.id
  const offlineEventIDs = useMemo(() => {
    const sources = group.sources.filter(source => source.includes('offline_event'))
    return sources.length ? sources.map(source => source.split('/')[1]) : []
  }, [group.sources])

  const shouldGetHistoricalMatchRates = offlineEventIDs.length > 0
  const { data, isLoading, error } = useGetHistoricalMatchRates(
    businessID!,
    offlineEventIDs || [],
    shouldGetHistoricalMatchRates
  )

  const onExtrapolatedChange = (column: string, value: ExtrapolationType) => {
    StageActions.editGroup(index, 'extrapolation_type', value)
  }

  const onChange = (column: string, value: any) => {
    StageActions.editGroup(index, column, value)
  }

  const onSources = (value: any): void => {
    StageActions.editGroup(index, 'sources', value ? value.map(x => x.value) : [])
  }

  const onDelete = () => {
    StageActions.delGroup(index)
  }

  const options: any[] = sources.data
    .filter(source => !group.sources.includes(source.meta.key))
    .filter(source => source.meta.support)
    .map(source => ({
      label: source.meta.name,
      value: source.meta.key,
    }))

  const value = group.sources.map(source => ({
    label: source,
    value: source,
  }))

  const extrapolationTypeOptions = ['Disabled', 'Legacy', 'Classic', 'Modern'].map(val => ({
    label: val,
    value: val,
  }))

  if (isLoading) {
    return <div>Loading...</div>
  }

  const shouldRenderMatchRates =
    data && data.some((matchRate: MatchRateWithId) => matchRate.matchRate > 0)

  return (
    <div>
      <div className="form-group">
        {error && <div className="alert alert-danger">{error.message ? error.message : error}</div>}
        {data && data.length > 0 && shouldGetHistoricalMatchRates && shouldRenderMatchRates && (
          <div
            className="alert alert-outline-primary"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div className="" style={{ paddingBottom: '1rem' }}>
              Historical match rates for offline event sources. These will be used for the "Classic"
              extrapolation type
            </div>
            {data.map((matchRate: MatchRateWithId) => {
              if (matchRate.matchRate > 0) {
                return (
                  <div
                    key={matchRate.id}
                    style={{ display: 'flex', justifyContent: 'space-between', width: '25%' }}
                  >
                    <span>offline_event/{matchRate.id}</span>
                    <span>{matchRate.matchRate.toFixed(2)}</span>
                  </div>
                )
              }
            })}
          </div>
        )}
      </div>
      <div className="form-group">
        <Input column="name" text="Name" type="regular" value={group.name} onChange={onChange} />
      </div>
      <div className="form-group">
        <Input
          column="desc"
          text="Info Bubble"
          type="regular"
          value={group.desc}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>Data Sources</label>
        <Select
          options={options}
          value={value}
          onChange={onSources}
          isMulti
          isClearable={true}
          menuPlacement="auto"
        />
      </div>
      <div className="form-group">
        <label>Extrapolation Type</label>
        <Input
          column="desc"
          options={extrapolationTypeOptions}
          type="select"
          value={extrapolationTypeOptions.find(val => val.value === group.extrapolation_type)}
          onChange={onExtrapolatedChange}
        />
      </div>

      <div className="text-right">
        <button className="btn btn-danger" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  )
}

export default Connect(Info)
