import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import Portlet from 'components/reusable/portlet'
import Connect from 'app/redux/connect'
import Redux from '../../../../../../../app/interface/redux'
import { cloneDeep, Dictionary, flatMap, head, intersection, keyBy, orderBy, sumBy } from 'lodash'
import { DateRangePicker } from 'react-dates'
import { SelectFunnelStageGroup } from './select-funnel-stage-group/SelectFunnelStageGroup'
import {
  AdLevelFields,
  AttrDuration,
  AttrWindowsBySourceType,
  DailyAttributionTotal,
  InsightAttributionSourceType,
} from '@marketing-milk/interfaces'
import { AttributionWindow } from './attribution-window/AttributionWindow'
import { Button } from '@material-ui/core'
import { AttributionType } from './AttributionCheckerTypes'
import { businessService } from '../../../../../../../app/service/business'
import { Loading, MaterialDataTable } from '@marketing-milk/frontend'
import { GridColDef, GridRowData } from '@material-ui/x-grid'
import { toast } from 'react-toastify'

export const windowKey = (window: AttrWindowsBySourceType) =>
  `${window.offline.click}_click,${window.offline.view}_view,${window.online.click}_click,${window.online.view}_view`

const AttributionChecker = (props: Redux) => {
  const funnelStageGroups = flatMap(
    orderBy(props.pages.business.funnel.stages, _ => _.is_kpi, 'desc'),
    _ => _.groups
  ).filter(
    // we don't want ad-level fields here, as we care only about attribution
    _ => intersection(_.sources, Object.values(AdLevelFields)).length < 1
  )
  const businessID = props.pages.business.id!

  const midnight = Moment('0', 'H')
  const defaultWindow: AttrWindowsBySourceType = props.pages.business.main.attr_windows
  const [range1, changeDateRange1] = useState<{
    startDate: Moment.Moment
    endDate: Moment.Moment
  }>({
    startDate: Moment().subtract(2, 'months').startOf('month'),
    endDate: Moment().subtract(2, 'months').endOf('month'),
  })
  const [range2, changeDateRange2] = useState<{
    startDate: Moment.Moment
    endDate: Moment.Moment
  }>({
    startDate: Moment().subtract(1, 'months').startOf('month'),
    endDate: Moment().subtract(1, 'months').endOf('month'),
  })
  const [dateFocus1, setDateFocus1] = useState<string | null>(null)
  const [dateFocus2, setDateFocus2] = useState<string | null>(null)
  const [groupID, setGroupID] = useState<number | undefined>(head(funnelStageGroups)?.id)
  const [windows, setWindows] = useState<AttrWindowsBySourceType[]>([{ ...defaultWindow }])
  const [rowSet1, setAttributionRowSet1] = useState<GridRowData[]>([])
  const [rowSet2, setAttributionRowSet2] = useState<GridRowData[]>([])
  const [attributionTotalSet1, setAttributionTotalSet1] = useState<Dictionary<number>>({})
  const [attributionTotalSet2, setAttributionTotalSet2] = useState<Dictionary<number>>({})
  const [loading, toggleLoading] = useState<boolean>(false)

  useEffect(() => {
    async function fetchAttributions(
      setRows: React.Dispatch<React.SetStateAction<GridRowData[]>>,
      setTotals: React.Dispatch<React.SetStateAction<Dictionary<number>>>,
      start?: Moment.Moment,
      end?: Moment.Moment
    ): Promise<void> {
      toggleLoading(true)
      let attributionTotals: Dictionary<number> = {}
      let attributionsByDay: Dictionary<GridRowData> = {}
      for (const idx in windows) {
        const window = windows[idx]
        const key = windowKey(window)
        let attributions: DailyAttributionTotal[] = []

        try {
          attributions = await businessService.checkAttribution(
            businessID,
            groupID!,
            start ? start.format('YYYY-MM-DD') : '',
            end ? end.format('YYYY-MM-DD') : '',
            window
          )
        } catch (e) {
          toast.warning(`Unable to retrieve data, please try a different data source.`)
        }
        // on first request initialize the dictionary and key by day
        if (Number(idx) === 0) {
          attributionsByDay = keyBy(attributions, _ => _.day)
        }
        attributionTotals[key] = sumBy(attributions, _ => _.conversions)

        // build rows here
        for (const attr of attributions) {
          // here we take the key by the window to the day for direct assigning of the number.
          // This way, other windows can also be additive
          // note: the format is needed
          attributionsByDay[attr.day][key] = attr.conversions
        }
      }
      setTotals(attributionTotals)
      // set rows here, material requires unique ID, which is guaranteed by keying by the date
      setRows(
        Object.values(attributionsByDay)
          .map(_ => ({ ..._, id: _.day }))
          .filter(_ => {
            // grab all numerical values of each object, filter only ones greater than 0
            const values = Object.values(_).filter(_ => typeof _ === 'number')
            return values.filter(_ => _ > 0).length > 0
          })
      )
      toggleLoading(false)
    }
    if (groupID) {
      fetchAttributions(
        setAttributionRowSet1,
        setAttributionTotalSet1,
        range1.startDate,
        range1.endDate
      )
      fetchAttributions(
        setAttributionRowSet2,
        setAttributionTotalSet2,
        range2.startDate,
        range2.endDate
      )
    }
  }, [businessID, windows, groupID, range1, range2])

  function onFocus(input: string, setFocus: React.Dispatch<React.SetStateAction<string | null>>) {
    setFocus(input)
  }

  function onAddWindow() {
    setWindows(prevState => [...prevState, { ...defaultWindow }])
  }

  function onChangeWindow(
    idx: number,
    attribution: AttrDuration,
    sourceType: InsightAttributionSourceType,
    attrType: AttributionType
  ) {
    setWindows(prevState => {
      const clone = cloneDeep(prevState)
      clone[idx][sourceType] = {
        ...clone[idx][sourceType],
        [attrType]: attribution,
      }
      return clone
    })
  }

  function onDeleteWindow(idx: number) {
    setWindows(prevState => {
      const clone = cloneDeep(prevState)
      clone.splice(idx, 1)
      return clone
    })
  }

  // build columns
  const windowColumns: GridColDef[] = [
    {
      field: 'day',
      headerName: 'Day',
      description: 'Date the conversion occurred',
      type: 'string',
    },
    ...windows.map((_, idx) => ({
      field: windowKey(_),
      headerName: `Attribution ${idx + 1} (offline: ${_.offline.click}_click, ${
        _.offline.view
      }_view, online: ${_.online.click}_click, ${_.online.view}_view)`,
      description: `Conversions for the window (offline: ${_.offline.click}_click, ${_.offline.view}_view, online: ${_.online.click}_click, ${_.online.view}_view)`,
      type: 'number',
      width: 250,
    })),
  ]

  return (
    <Portlet title="Atribution Window Comparison">
      <span>
        This tool is used to compare Attribution windows for various date ranges. Select a Funnel
        Stage Group you would like to report on and direct attribution from Facebook will be
        provied. Please note: this accounts for new and repeat conversions, and does not extrapolate
        values.
      </span>
      <hr />
      <div className="row">
        <div className="col-sm-8 col-xs-12">
          <h5>Date Range</h5>
          <div className="row">
            <div className="col-sm-5 col-xs-12">
              <DateRangePicker
                startDate={range1.startDate}
                startDateId="start1"
                endDate={range1.endDate}
                endDateId="end1"
                onDatesChange={changeDateRange1}
                focusedInput={dateFocus1}
                onFocusChange={date => onFocus(date, setDateFocus1)}
                isOutsideRange={date => date.isSameOrAfter(midnight)}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <h4 className="mt-2">vs.</h4>
            </div>
            <div className="col-sm-5 col-xs-12">
              <DateRangePicker
                startDate={range2.startDate}
                startDateId="start2"
                endDate={range2.endDate}
                endDateId="end2"
                onDatesChange={changeDateRange2}
                focusedInput={dateFocus2}
                onFocusChange={date => onFocus(date, setDateFocus2)}
                isOutsideRange={date => date.isSameOrAfter(midnight)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-xs-12">
          <h5>Funnel Stage Group</h5>
          <SelectFunnelStageGroup
            funnelStageGroups={funnelStageGroups}
            selected={groupID ?? head(funnelStageGroups)?.id}
            onChooseGroup={setGroupID}
          />
        </div>
      </div>
      <div className="row mt-5">
        {windows.map((_, idx) => (
          <div className="col-sm-4">
            <AttributionWindow
              key={idx}
              window={_}
              idx={idx}
              loading={loading}
              total1={attributionTotalSet1[windowKey(_)]}
              total2={attributionTotalSet2[windowKey(_)]}
              onChangeWindow={onChangeWindow}
              onDeleteWindow={onDeleteWindow}
            />
          </div>
        ))}
      </div>
      <div className="row mt-2">
        <Button color="primary" variant="contained" onClick={onAddWindow}>
          <i className="fa fa-plus"></i>&nbsp;&nbsp;Add Window
        </Button>
      </div>
      <div className="row mt-5">
        {!loading ? (
          [rowSet1, rowSet2].map(_ => (
            <div className="col-sm-6 col-xs-12">
              <MaterialDataTable
                sortingOrder={['asc', 'desc']}
                rows={_}
                rowHeight={38}
                headerHeight={50}
                density={'comfortable'}
                columns={windowColumns}
                checkboxSelection={false}
                pagination
                pageSize={15}
              />
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>
    </Portlet>
  )
}

export default Connect(AttributionChecker)
