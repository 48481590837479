import { DataSource } from '@marketing-milk/interfaces'

export interface DataSourceState {
  dataSources: DataSource[]
  isLoading: boolean
}

export const defaultDataSourceState: DataSourceState = {
  dataSources: [],
  isLoading: true,
}
