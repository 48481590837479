import { UploadSubmission } from '@marketing-milk/interfaces'

export interface ActionsProps {
  submission: UploadSubmission
}

export type UploadModalState = 'approve' | 'reject' | 'done'

export type UploadModalStatus = 'success' | 'error' | 'bad_data' | 'loading' | 'default'

export interface ActionsState {
  approved: boolean
  comments: string
  modal?: UploadModalState
  status: UploadModalStatus
}

export const defaultActionsState: ActionsState = {
  approved: false,
  comments: '',
  modal: undefined,
  status: 'default',
}
