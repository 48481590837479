import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { UserUpload } from '@marketing-milk/interfaces'
import { CreateVersion } from './create-version/CreateVersion'
import { VersionActions } from './version-actions/VersionActions'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { UploadVersionHistory } from './version-history/VersionHistory'
import { UploadStatus } from './upload-details/upload-status/UploadStatus'
import { DeleteUploadButton } from './delete-upload-button/DeleteUploadButton'
import { badDataService, useCreateFetchHook } from '@marketing-milk/frontend'
import { uniq } from 'lodash'

export interface EditUploadModalProps {
  upload: UserUpload
}

export function EditUploadModal({ upload }: EditUploadModalProps) {
  const params: { business: string } = useParams()
  const [isOpen, setModal] = useState(false)
  const latestVersion = upload.versions[0]
  const badDataSubmissions = useCreateFetchHook(
    () => badDataService.getSubmissionsForBusiness(Number(upload.businessID)),
    Number(upload.businessID)
  )

  const activeBadDataEventNames = badDataSubmissions
    ? uniq(
        badDataSubmissions.flatMap(({ eventName, approvedBy }) => (!approvedBy ? eventName : []))
      )
    : []
  function toggle() {
    setModal(_ => !_)
  }

  return (
    <>
      <Button color="primary" onClick={toggle} variant="contained">
        <i className="fa fa-pencil" />
      </Button>
      {isOpen && (
        <Modal isOpen size="xl" toggle={toggle}>
          <ModalHeader>Edit Upload</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <h5>Upload Details</h5>
                <UploadStatus
                  businessID={params.business}
                  uploadID={upload.id.toString()}
                  version={latestVersion}
                />
                <VersionActions
                  businessID={params.business}
                  upload={upload}
                  latestVersion={upload.versions[0]}
                  badDataEventNames={activeBadDataEventNames}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <CreateVersion
                  businessID={params.business}
                  disabled={upload?.locked ?? true}
                  uploadID={upload.id.toString()}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <h5>File Versions</h5>
                <UploadVersionHistory upload={upload} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="float-left">
            <DeleteUploadButton upload={upload} />
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
