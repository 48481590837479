import { BusinessError } from '@marketing-milk/interfaces'
import { Button, Grid } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import React from 'react'

export interface ErrorCenterProps {
  errors: BusinessError[]
  onResolve: (error: BusinessError) => {}
}

const businessErrorRecord: Record<BusinessError, string> = {
  [BusinessError.ad_account_access_error]:
    "The Ad Account Authorizer is missing permissions / has an expired token. Ensure that this user is added to the Facebook Ad Account as an Admin on Facebook's Business Manager, then update the Authorizer on Marketing Milk. If that does not work, be sure to have the authorizer re-log in to Facebook. After these steps, you should be able to clear the error.",
  [BusinessError.offline_upload_error]:
    'Uploads to Facebook have failed due to improper configuration. Please make sure you have an Offline Event Set ID configured with the Conversions API turned off, or a Dataset ID configured with the Conversions API turned on.',
}

export const ErrorCenter = ({ errors, onResolve }: ErrorCenterProps) => {
  return (
    <div className="alert alert-danger" role="alert">
      <div className="alert-text">
        <h4 className="alert-heading">Business Error Center</h4>
        <p>
          We have detected some issues with this business. It is likely due to your Facebook
          configuration. Please review the following issues and follow the instructions. You can
          clean the errors after following the suggested steps.
        </p>
        <p>
          <strong>Note: </strong>you must make changes to the business before you can clear the
          errors
        </p>
        <hr />
        <ul>
          {errors.map((error, i) => (
            <div className="py-2">
              <Grid container spacing={4}>
                <Grid item xs={10}>
                  <span>{businessErrorRecord[error]}</span>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    key={`resolve-btn-${i}`}
                    variant="contained"
                    color="default"
                    onClick={() => onResolve(error)}
                  >
                    <BuildIcon className="mr-2" /> Clear
                  </Button>
                </Grid>
              </Grid>
            </div>
          ))}
        </ul>
      </div>
    </div>
  )
}
