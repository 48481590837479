import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { CreateFranchiseDTO } from '@marketing-milk/interfaces'
import { franchiseService } from '@marketing-milk/frontend'
import axios from 'axios'
import { toast } from 'react-toastify'

interface FranchiseCreateProps {
  onSuccess: () => void
}

export const FranchiseCreate = (props: FranchiseCreateProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const initialValues: { name: string } = {
    name: '',
  }
  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      const dto: CreateFranchiseDTO = {
        name: values.name,
      }

      setLoading(true)
      try {
        await franchiseService.create(dto)
        handleClose()
        props.onSuccess()
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 400) {
          toast.error('That franchise name is already taken.')
        } else {
          toast.error('Something unexpected went wrong creating the franchise.')
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        data-testid="create-user-btn"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Create
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Create Franchise</DialogTitle>

        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogContent style={{ paddingBottom: '2em' }}>
            <div className="container">
              <div className="row">
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  style={{ width: '400px' }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
