import { Loading, userService } from '@marketing-milk/frontend'
import { Auth0UserWithRoles, UserWithBusiness } from '@marketing-milk/interfaces'
import { Button, Divider, Typography } from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/Schedule'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import { Alert } from '@material-ui/lab'
import { format, formatDistanceToNow } from 'date-fns'
import { capitalize } from 'lodash'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Delete } from '../../modals/Delete'

//update this type
export interface DetailsTabType {
  auth0User?: Auth0UserWithRoles
  mmUser: UserWithBusiness
  isLoading?: boolean
}

export function DetailsTab({ auth0User, mmUser, isLoading }: DetailsTabType) {
  const { name, email, created_at, identities, last_login } = auth0User ?? {}
  const history = useHistory()
  const [open, setOpen] = useState(false)

  const clickDelete = async (user: UserWithBusiness | undefined) => {
    if (user) {
      try {
        await userService.deleteByID(user?.id)
        history.push('/admin/users/list')
        toast.success('Successfully deleted user from Marketing Milk.')
      } catch (e) {
        toast.error(
          'There was a problem deleting this user. Please try again later. If the problem persists contact support.'
        )
      }
    }
  }

  if (isLoading) return <Loading />

  return (
    <div className="container-fluid p-0">
      <div
        className="container-fluid"
        style={{
          padding: '10px',
          border: '1px solid lightgrey',
          borderRadius: '5px',
        }}
      >
        <div className="row px-4 pt-4">
          <div className="col-sm-4 col-xs-6 pb-4">
            <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bold' }} gutterBottom>
                Name
              </Typography>
              <Typography>{name ? name : `${mmUser?.firstName} ${mmUser?.lastName}`}</Typography>
            </div>
          </div>
          <div className="col-sm-4 col-xs-6 pb-4">
            <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bold' }} gutterBottom>
                Email
              </Typography>
              <Typography>{email ? email : `${mmUser?.email}`}</Typography>
            </div>
          </div>
          <div className="col-sm-4 col-xs-6 pb-4">
            <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bold' }} gutterBottom>
                Signed Up
              </Typography>
              <Typography>
                {created_at
                  ? `${format(new Date(created_at), 'PP')} (${formatDistanceToNow(
                      new Date(created_at),
                      { addSuffix: true }
                    )})`
                  : 'Pending'}
              </Typography>
            </div>
          </div>
          <div className="col-sm-4 col-xs-6 pb-4">
            <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bold' }} gutterBottom>
                Connection
              </Typography>
              <Typography>
                {identities
                  ? identities[0].provider === 'auth0'
                    ? 'Email/Password'
                    : capitalize(identities[0].provider)
                  : 'N/A'}
              </Typography>
            </div>
          </div>
          <div className="col-sm-4 col-xs-6 pb-4">
            <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bold' }} gutterBottom>
                Latest Login
              </Typography>
              <Typography>
                {last_login
                  ? `${format(new Date(last_login), 'PP')} (${formatDistanceToNow(
                      new Date(last_login),
                      { addSuffix: true }
                    )})`
                  : 'N/A'}
              </Typography>
            </div>
          </div>
          <div className="col-sm-4 col-xs-6 pb-4">
            <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bold' }} gutterBottom>
                Accepted Invite
              </Typography>
              <Typography>
                {mmUser?.acceptedInvite ? <VerifiedUserIcon /> : <ScheduleIcon />}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="row px-4 pt-5">
        <Typography variant="h4" className="mb-3">
          Danger Zone
        </Typography>

        <Divider />
      </div>

      <div className="row px-4">
        <Alert
          variant="standard"
          severity="error"
          className="mt-3 w-100"
          action={
            <Button
              className="bg-white"
              disableElevation
              data-testid="delete-user-btn"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Delete
            </Button>
          }
        >
          <Typography variant="h6" className="mt-n1">
            Delete User
          </Typography>
          Warning: Deleting this user willl remove the user from our system and their access to
          Marketing Milk will be revoked.
        </Alert>
      </div>

      {open && (
        <Delete
          title={<Typography style={{ fontSize: 20 }}>Remove User Account?</Typography>}
          body={
            <>
              <Typography style={{ fontSize: 15, paddingBottom: 15 }}>
                You are about to <span className="text-danger font-weight-bold">delete</span> the
                user account for
                <span
                  style={{ fontWeight: 'bold' }}
                >{` ${mmUser?.firstName} ${mmUser?.lastName}.`}</span>
              </Typography>

              <Typography variant="subtitle1">
                Upon clicking "Delete", their account will be deleted and their access to Marketing
                Milk will be revoked.
              </Typography>
            </>
          }
          onClose={() => {
            setOpen(false)
          }}
          withButton={false}
          onSubmitted={clickDelete}
          item={mmUser}
          openModal={open}
          maxWidth={'sm'}
        />
      )}
    </div>
  )
}
