import React, { useState } from 'react'
import Input from 'components/base/form/interface/input'
import Basic from 'app/redux/actions/pages/business/basic'
import Form from 'components/base/form'
import { Connected } from '../../../../../../../../app/interface/redux'
import Connect from 'app/redux/connect'
import Alert from 'sweetalert2'
import { toast } from 'react-toastify'
import { businessService } from '../../../../../../../../app/service/business'

interface RepeatState {
  lookback_window: number
  repeat_check_fullname_fallback: boolean
}

const Repeat = ({ pages }: Connected) => {
  const { repeat_check_fullname_fallback, lookback_window } = pages.business.main
  const businessID = pages.business.id
  const [changeLookback, setChangeLookback] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [repeat, setRepeat] = useState<RepeatState>({
    lookback_window,
    repeat_check_fullname_fallback,
  })

  function changeRepeat(key: string, value: any) {
    setRepeat(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function allowChange() {
    setChangeLookback(_ => !_)
  }

  async function submitChange() {
    let choice = await Alert.fire({
      title: 'Are you sure?',
      text: `Please note that this process will only affect benchmarked and future events, not those already uploaded to Facebook.`,
      showCancelButton: true,
      cancelButtonColor: '#36a3f7',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#f4516c',
    })

    if (choice.value) {
      // do api call
      await businessService.setRepeatCheck(businessID!, {
        lookbackWindow:
          typeof repeat.lookback_window === 'string'
            ? Number(repeat.lookback_window)
            : repeat.lookback_window,
        fullNameFallback: repeat.repeat_check_fullname_fallback,
      })
      Basic.update('lookback_window', repeat.lookback_window)
      Basic.update('repeat_check_fullname_fallback', repeat.repeat_check_fullname_fallback)
      setSubmitted(true)
      toast.success(
        'Your repeat check options have been updated. Expect benchmarked repeat events to change over the next few hours.'
      )
    }
  }

  return (
    // if no business created yet, simply allow usage of form
    !businessID ? (
      <Form
        save={false}
        onChange={Basic.update}
        onSubmit={() => null}
        groups={[
          {
            header: '',
            rows: [
              {
                column: 6,
                fields: [
                  {
                    column: 'lookback_window',
                    text: 'Lookback Window',
                    type: 'regular',
                    subType: 'number',
                    value: lookback_window,
                  } as Input,
                  {
                    column: 'repeat_check_fullname_fallback',
                    text: 'Use Full Name as fallback for Repeat Check?',
                    type: 'toggle',
                    value: repeat_check_fullname_fallback,
                  } as Input,
                ],
              },
            ],
          },
        ]}
      />
    ) : (
      <div className="row">
        <div className="col-sm-12">
          <h4 style={{ color: '#575962', fontSize: '1.3rem' }}>Repeat Check</h4>
          <p style={{ fontSize: '1.2rem' }}>
            You lookback window is currently set at <strong>{lookback_window} day(s)</strong>
          </p>
          <p style={{ fontSize: '1.2rem' }}>
            The repeat check <strong>{repeat_check_fullname_fallback ? 'WILL' : 'WILL NOT'}</strong>{' '}
            use the full name as a fallback
          </p>
          <p style={{ fontSize: '1.1rem' }} className="text-danger">
            Changing these values will only affect benchmarked and future events, not those already
            uploaded to Facebook. Changes should be reflected within 24 hours.
          </p>
          <div className="btn btn-primary" onClick={allowChange}>
            Modify
          </div>
        </div>
        <div className="col-sm-12">
          {changeLookback && !submitted ? (
            <Form
              onChange={changeRepeat}
              onSubmit={submitChange}
              groups={[
                {
                  header: '',
                  rows: [
                    {
                      column: 6,
                      fields: [
                        {
                          column: 'lookback_window',
                          text: 'Lookback Window',
                          type: 'regular',
                          subType: 'number',
                          value: repeat.lookback_window,
                        } as Input,
                        {
                          column: 'repeat_check_fullname_fallback',
                          text: 'Use Full Name as fallback for Repeat Check?',
                          type: 'toggle',
                          value: repeat.repeat_check_fullname_fallback,
                        } as Input,
                      ],
                    },
                  ],
                },
              ]}
            />
          ) : null}
        </div>
      </div>
    )
  )
}

export default Connect(Repeat)
