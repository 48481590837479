import React, { useEffect, useState } from 'react'
import { useDebounce, useFetchAllRoles, useFetchAllUsers } from '../../../hooks'
import { PageHeader } from '../PageHeader'
import { UsersTable } from './user-table/UsersTable'
import Connect from 'app/redux/connect'
import { Connected } from 'app/interface/redux'
import UserCreate from './modals/UserCreate'
import { DeletedUsersModal } from './modals/DeletedUsersModal'
import { Permissions } from '@marketing-milk/interfaces'

const Users = ({ ...props }: Connected) => {
  const [filter, setFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const { debouncedValue } = useDebounce(searchTerm, 1000)
  const userHook = useFetchAllUsers()
  const { data: auth0Roles, isLoading: loadingRoles } = useFetchAllRoles()

  const canManageRoles = props.core.session.user!.permissions.includes(Permissions.ManageRoles)

  const refetchUsers = () => {
    userHook.refetch()
  }

  useEffect(() => {
    userHook.setPaginationParams(prevParams => ({
      ...prevParams,
      searchTerm: debouncedValue.trim().toLowerCase(),
      page: 1,
    }))
  }, [debouncedValue])

  return (
    <div>
      {/* Header */}
      <PageHeader
        title="Users"
        filterValue={filter}
        onChangeFilter={value => {
          setSearchTerm(value)

          setFilter(value)
        }}
      >
        <div style={{ flex: 'none' }}>
          <UserCreate
            canManageRoles={canManageRoles}
            auth0Roles={auth0Roles}
            isLoading={loadingRoles}
            onSave={refetchUsers}
            businesses={props.data.generic.businesses.data}
            pods={props.data.generic.pods.data}
          />
        </div>
        <div className="ml-2">
          <DeletedUsersModal onRestoreDeletedUsers={refetchUsers} />
        </div>
      </PageHeader>

      {/* User Table */}
      <UsersTable canManageRoles={canManageRoles} users={userHook?.users} userHook={userHook} />
    </div>
  )
}

export default Connect(Users)
