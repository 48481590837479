import './DataSources.scss'
import Connect from 'app/redux/connect'
import { Connected } from 'app/interface/redux'
import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { DataSource } from '@marketing-milk/interfaces'
import { dataSourceService } from 'app/service/data-source'
import { defaultDataSourceState, DataSourceState } from '.'
import { DataSourceActions } from 'app/redux/actions/pages/business/data-source'

function DataSourcesComponent(props: Connected) {
  const { dataSources } = props.pages.business
  const [state, setState] = useState<DataSourceState>(defaultDataSourceState)

  async function fetchDataSources() {
    const dataSources: DataSource[] = await dataSourceService.getAll()
    setState({
      dataSources,
      isLoading: false,
    })
  }

  useEffect(() => {
    setState(defaultDataSourceState)
    fetchDataSources()
  }, [])

  function setDataSource(dataSource: DataSource): void {
    DataSourceActions.toggle(dataSource)
  }

  return (
    <div className="container">
      <h4 style={{ color: '#575962', fontSize: '1.3rem' }}>Data Types</h4>
      <span>
        Please choose which data type you would like to recommend to your clients. This will show up
        at the top of the Data Uploader Step 1.
      </span>
      <LoadingOverlay active={state.isLoading} className="row mt-5">
        {state.dataSources.map(option => (
          <div className="col-12 col-lg-4" key={option.id}>
            <div
              className={`data-option box-hover ${dataSources.includes(option.id) ? 'active' : ''}`}
              onClick={() => setDataSource(option)}
            >
              <div>
                <i className={option.icon} />
              </div>
              <div>
                <h5>{option.name}</h5>
              </div>
              <p>{option.desc}</p>
            </div>
          </div>
        ))}
      </LoadingOverlay>
    </div>
  )
}

export const DataSources = Connect(DataSourcesComponent)
