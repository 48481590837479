import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Typography,
  withStyles,
} from '@material-ui/core'
import { podService } from '@marketing-milk/frontend'
import { Theme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import { DeleteForever } from '@material-ui/icons'
import { toast } from 'react-toastify'

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button)

export function DeletePodButton({ podID, podName }: { podID: number; podName: string }) {
  const [open, setOpen] = useState(false)

  function handleDeleteClick() {
    setOpen(true)
  }

  async function deletePod() {
    try {
      await podService.deleteByID(podID)
      toast.success('Pod deleted successfully')
    } catch (error) {
      if (error instanceof Error) {
        toast.error('Error deleting pod: ' + error.message)
      }
    } finally {
      setOpen(false)
    }
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        className="text-danger"
        onClick={handleDeleteClick}
        data-testid="delete-pod-dialog-button"
      >
        <DeleteForever />
      </Button>
      <Dialog open={open} onClose={handleDialogClose} maxWidth="xs" fullWidth={true}>
        <DialogContent dividers>
          <Typography variant="h4" gutterBottom>
            Are you sure?
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            You are about to delete {podName} permanently
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <ColorButton onClick={deletePod} color="primary">
            Delete
          </ColorButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
