import Guard from './guard'
import Routes from 'app/config/routes'
import Missing from 'pages/error/missing'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import RouteActions from 'app/redux/actions/core/route'
import { IState, IParent, IRoute } from 'app/interface/config/router'

Object.defineProperty(Array.prototype, 'flat', {
  value: function (depth = 1) {
    return this.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) && depth > 1 ? toFlatten.flat(depth - 1) : toFlatten
      )
    }, [])
  },
})

class Router extends PureComponent<any> {
  state: IState = {
    ready: false,
    routes: [],
  }

  componentWillMount() {
    this.prepare(Routes)
  }

  prepare = (routes: Array<IParent>): void => {
    const final: Array<IRoute> = routes
      .map(parent => parent.routes.map(child => this.formatRoute(child, parent)))
      .flat()

    this.setState({
      ready: true,
      routes: final,
    })
  }

  formatRoute = (route: IRoute, parent: IParent): IRoute => {
    return {
      component: route.component,
      guard: parent.guard || route.guard,
      path: parent.prefix ? `${parent.prefix}/${route.path}` : route.path,
      parent: parent.component,
      meta: route.meta || parent.meta,
      title: route.title,
    }
  }

  render() {
    const { ready, routes } = this.state
    return !ready ? null : (
      <Switch>
        {routes.map(route => (
          <Route
            component={props => {
              RouteActions.init(route)
              const Component = route.parent ? route.parent : route.component
              return (
                <Guard
                  component={<Component route={route} {...props} />}
                  guard={route.guard!}
                  page={route.title}
                />
              )
            }}
            exact={true}
            key={route.path}
            path={`/admin/${route.path}`}
          />
        ))}
        <Route component={Missing} path="" />
      </Switch>
    )
  }
}

export default withRouter(Router)
