import { UserUpload, UploadFileFormat } from '@marketing-milk/interfaces'

export const formatToText: Record<UploadFileFormat, string> = {
  [UploadFileFormat.FacebookOfflineEvent]: 'Events',
  [UploadFileFormat.MonthlyAggregate]: 'Historical Results',
}

export interface ChooseFormatProps {
  businessID: string
  upload: UserUpload
}
