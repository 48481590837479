import React, { useEffect, useState } from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Auth0RolePermission } from '@marketing-milk/interfaces'
import { useFetchAllPermissions } from 'hooks'
import { CustomSelect } from '../users/shared/CustomSelect'

export interface AddPermissionsModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  permissions?: Auth0RolePermission[]
  onSubmit?: (permissions: Auth0RolePermission[]) => void
}

export function AddPermissionsModal(props: AddPermissionsModalProps) {
  const globalPermissions = useFetchAllPermissions()
  const [selectedPermissions, setSelectedPermissions] = useState<Auth0RolePermission[]>([])
  const hasPermissionsSelected = selectedPermissions?.length > 0
  // filter out all auth 0 users by the IDs of the users already added to this role
  const filteredPermissions = globalPermissions.data?.filter(
    permission =>
      !props.permissions?.map(addedPermission => addedPermission.value)?.includes(permission.value!)
  )

  const handleClose = () => {
    props.setIsOpen(false)
    setSelectedPermissions([])
  }

  const onSubmit = () => {
    props.onSubmit && hasPermissionsSelected && props.onSubmit(selectedPermissions)
    handleClose()
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={props.isOpen} onClose={handleClose}>
      <DialogTitle>Add Permissions</DialogTitle>
      <DialogContent>
        <DialogContentText>Assign more permissions to this role.</DialogContentText>
        <div className="w-100">
          <CustomSelect
            options={filteredPermissions?.map(permission => ({
              label: `${permission.description} (${permission.value})`,
              value: permission.value,
              item: permission,
            }))}
            onChange={value => setSelectedPermissions(value?.map(v => v.item))}
            clearSelection={!props.isOpen}
            placeHolderText="Select permission(s)..."
            fieldName="permissions"
            isMulti={true}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={!hasPermissionsSelected} onClick={onSubmit}>
          Add Permissions
        </Button>
      </DialogActions>
    </Dialog>
  )
}
