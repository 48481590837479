import React from 'react'
import Interface from '../../interface/input'

export default ({ column, className, onBlur, onChange, value }: Interface) => (
  <input
    className={className}
    checked={!!value}
    onBlur={onBlur}
    onChange={event => onChange(column, event.target.value)}
    type="checkbox"
  />
)
