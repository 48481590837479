import { Component } from '.'
import React, { useEffect, useState } from 'react'
import Input from 'components/base/form/interface/input'
import { dataSourceService } from 'app/service/data-source'
import { CampaignGoalType, CampaignGoalName, DataSource } from '@marketing-milk/interfaces'
import Row from 'components/base/form/row'
import StageActions from 'app/redux/actions/pages/business/funnel/stage'

export default ({ data_sources_id, campaign_goal }: Component) => {
  const [dataSources, setDataSources] = useState<DataSource[]>([])

  const matchedDataSource: DataSource | undefined = dataSources.find(x => x.id === data_sources_id)
  const dataSource = matchedDataSource
    ? {
        label: matchedDataSource.name,
        value: matchedDataSource.id,
      }
    : ''

  // I dislike enums, very much so
  const matchedGoal: CampaignGoalType | undefined = Object.values(CampaignGoalType).find(
    goal => goal === campaign_goal
  )
  const campaignGoal = matchedGoal
    ? Object.entries(CampaignGoalType)
        .filter(([key, goalType]) => goalType === matchedGoal)
        ?.map(([key, goalType]) => ({
          label: CampaignGoalName[key],
          value: goalType,
        }))[0]
    : ''

  useEffect(() => {
    async function fetchDataSources(): Promise<void> {
      const dataSources: DataSource[] = await dataSourceService.getAll()
      setDataSources(dataSources)
    }

    fetchDataSources()
  }, [])

  return (
    <div className="mt-3">
      <div className="row">
        <div className="alert alert-outline-warning alert-dismissible fade show" role="alert">
          <strong>Please Note</strong>&nbsp; Benchmark Reports are only compatible with Offline
          Events and/or Historical Results uploads via the Uploader Tool, or Facebook leads
          (fbleads).
        </div>
        <div className="col-sm-6">
          <h3>Benchmark Report Settings</h3>
          <p>
            Select a Data Type to Benchmark that corresponds with the data uploaded via the Uploader
            Tool. (This is not required)
          </p>
          <Row
            fields={[
              {
                column: 'data_sources_id',
                text: 'Data Type',
                type: 'select',
                options: dataSources.map(dataSource => ({
                  label: dataSource.name,
                  value: dataSource.id,
                })),
                selector: {
                  clearable: true,
                },
                value: dataSource,
              } as Input,
            ]}
            onChange={(key, value) => StageActions.setValue(key, value ? value.value : null)}
          />
        </div>

        <div className="col-sm-6">
          <h3>Ad Builder Settings</h3>
          <p>
            Select a Campaign Goal this stage optimizes for. At least one stage must have a campaign
            goal in order to use the Ad Builder.
          </p>
          <Row
            fields={[
              {
                column: 'campaign_goal',
                text: 'Campaign Goal',
                type: 'select',
                options: Object.entries(CampaignGoalType).map(([key, goalType]) => ({
                  label: CampaignGoalName[key],
                  value: goalType,
                })),
                selector: {
                  clearable: true,
                },
                value: campaignGoal,
              } as Input,
            ]}
            onChange={(key, value) => StageActions.setValue(key, value ? value.value : null)}
          />
        </div>
      </div>
    </div>
  )
}
