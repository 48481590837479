import Store from 'app/redux/store'
import { bindActions } from 'redux-zero/utils'

const Tags = bindActions(
  store => ({
    toggle: (state: any, tag: number) => {
      let tags: Array<number> = [].concat(state.pages.business.tags)

      // If tag is applied, filter it out
      if (tags.includes(tag)) {
        tags = tags.filter(x => x !== tag)
      }
      // Otherwise, add it
      else {
        tags.push(tag)
      }

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            tags: tags,
          },
        },
      })
    },
  }),
  Store
)

export default Tags
