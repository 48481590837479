import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE, AUTH0_MAX_AGE } from './const'

const Auth0ProviderWithHistory = ({ children }: any) => {
  const redirectUri = window.location.origin + '/admin/login'

  const onRedirectCallback = () => {
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      maxAge={AUTH0_MAX_AGE} // if not defined, does not verify max age
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
