import './TableBody.scss'
import React from 'react'
import { TableBodyProps } from './'
import { TableRow } from '../table-row'

export function TableBody({ columns, rows }: TableBodyProps<any>) {
  return (
    <tbody className="kt-datatable__body">
      {rows?.map((row, rowIndex) => (
        <TableRow columns={columns} key={rowIndex} row={row} />
      ))}
    </tbody>
  )
}
