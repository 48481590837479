import React from 'react'
import { Popover as BSPopover, PopoverBody, PopoverHeader } from 'reactstrap'

export default class Popover extends React.Component {
  static count = 1

  state = {
    open: false,
  }

  componentWillMount() {
    this.id = 'popover-' + Popover.count++

    this.toggle = () => this.setState(state => ({ open: !state.open }))
    this.show = () => this.setState({ open: true })
    this.hide = () => this.setState({ open: false })
  }

  render() {
    let { title, ...props } = this.props

    let classes = this.props.classes
      ? 'fal fa-info-circle ' + this.props.classes
      : 'fal fa-info-circle'

    return (
      <span className="popover-i">
        <i
          className={classes}
          id={this.id}
          onClick={this.toggle}
          onMouseEnter={this.show}
          onMouseLeave={this.hide}
          onTouchEnd={this.toggle}
          style={{ marginLeft: '-.5%' }}
        />
        <BSPopover {...props} target={this.id} toggle={this.toggle} isOpen={this.state.open}>
          {title ? <PopoverHeader>{title}</PopoverHeader> : null}
          <PopoverBody>{this.props.children || this.props.text}</PopoverBody>
        </BSPopover>
      </span>
    )
  }
}
