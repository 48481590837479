import { getInitials, ProfilePicture } from '@marketing-milk/frontend'
import { UserWithBusiness } from '@marketing-milk/interfaces'
import { Button, Paper, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { RolesTab } from './tabs/RolesTab'
import { DetailsTab } from './tabs/DetailsTab'
import { BusinessTab } from './tabs/BusinessTab'
import { EmailHistoryTab } from './tabs/EmailHistoryTab'
import { useFetchEmailActivity, useGetAuth0User, useGetMMUser } from '../../../../hooks'
import { TabPanel } from 'components/generic/tab-panel'

export const useButtonStyles = makeStyles({
  button: {
    backgroundColor: 'transparent',
    color: '#676E64',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#545650',
    },
  },
})

export const UserDetails = () => {
  const classes = useButtonStyles()
  const [activeSection, setActiveSection] = useState(0)

  const history = useHistory()
  const params = useParams<{ id: string }>()

  // First try and get marketing milk user from the state
  const [mmUser, setUser] = useState<UserWithBusiness>()

  // If accessing this route without first having clicked on the user from the table, then call the endpoint to fetch the mm user.
  // NOTE: this won't be called if there is already a user found in the router state.
  const { loadingMMUser, fetchMMUser, user, fetchingMMUser } = useGetMMUser(Number(params.id), true)
  const {
    data: auth0User,
    isLoading: loadingAuth0User,
    refetch: fetchAuth0User,
    isError: loadErrorAuth0,
  } = useGetAuth0User(Number(params.id), !fetchingMMUser && !!mmUser && mmUser?.acceptedInvite)

  const { data: emailActivity } = useFetchEmailActivity(Number(params.id))

  useEffect(() => {
    if (user) {
      setUser(user)
    }
  }, [user])

  if (!mmUser?.businesses) return <></>

  return (
    <Paper style={{ minHeight: 'calc(100vh - 100px)' }}>
      <div className="container-fluid p-4">
        {/* Header w/ profile picture, selected user name, actions button and back to home button */}
        <div className="mb-4">
          <div style={{ display: 'inline-flex', alignItems: 'baseline', paddingBottom: '5px' }}>
            <Button
              disableRipple
              className={classes.button}
              onClick={e => {
                e.preventDefault()
                history.push('/admin/users/list')
              }}
            >
              <ArrowBackIcon />
              &nbsp;&nbsp;
              <Typography>Back to Users</Typography>
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <ProfilePicture
              initials={mmUser ? getInitials(mmUser.firstName, mmUser.lastName) : ''}
              image={mmUser ? mmUser.profilePicture : ''}
              size={64}
            />
            &nbsp;&nbsp;&nbsp;
            <div style={{ alignSelf: 'center' }}>
              <Typography>{`${mmUser?.firstName} ${mmUser?.lastName}`}</Typography>
              <Typography>{`${mmUser?.email}`}</Typography>
            </div>
          </div>
        </div>

        <TabPanel
          elevation={0}
          activeSection={activeSection}
          sections={[
            {
              title: 'Details',
              section: (
                <DetailsTab
                  auth0User={auth0User}
                  mmUser={mmUser}
                  isLoading={loadingMMUser || loadingAuth0User}
                />
              ),
              onSelectSection: () => setActiveSection(0),
            },
            {
              title: 'Roles',
              section: <RolesTab mmUser={mmUser} fetchMMUser={fetchMMUser} />,
              onSelectSection: () => setActiveSection(1),
            },
            {
              title: 'Businesses',
              section: (
                <BusinessTab
                  userBusinesses={mmUser?.businesses}
                  mmUser={mmUser}
                  refetchUser={fetchMMUser}
                />
              ),
              onSelectSection: () => setActiveSection(2),
            },
            {
              title: 'Email History',
              section: <EmailHistoryTab emailActivity={emailActivity} mmUser={mmUser} />,
              onSelectSection: () => setActiveSection(3),
            },
          ]}
        />
      </div>
    </Paper>
  )
}
