import React, { useEffect, useState } from 'react'
import { Business, SendGridMDetails, SendGridMReceipt } from '@marketing-milk/interfaces'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Button, IconButton, Typography } from '@material-ui/core'
import { Loading, useCreateFetchHook, userService } from '@marketing-milk/frontend'
import { User } from '@marketing-milk/interfaces'
import PageviewIcon from '@material-ui/icons/Pageview'
import { useFetchEmailActivity, useFetchEmailDetails } from '../../../../../hooks'
import { EmailDetails } from '../../modals/EmailDetails'
export interface EmailHistoryTabProps {
  emailActivity: SendGridMReceipt[] | undefined
  mmUser?: User
}

export function EmailHistoryTab({ emailActivity, mmUser }: EmailHistoryTabProps) {
  const [messageID, setMessageID] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [emailDetails, setEmailDetails] = useState<SendGridMDetails | undefined>(undefined)
  const { data: details, isLoading } = useFetchEmailDetails(
    Number(mmUser?.id),
    messageID!,
    open && !!messageID
  )

  useEffect(() => {
    if (details) setEmailDetails(details)
  }, [details])

  if (!emailActivity) {
    return <Loading loadingMessage="Checking our email logs.  This may take a while." />
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
        <Typography>All Emails sent to this User</Typography>
      </div>
      <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 27rem)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Subject</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Email Address</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Opens</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Clicks</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Status</Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emailActivity?.map((e, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  <Typography>{e.subject}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>{e.recipientEmail}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>{e.opens}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>{e.clicks}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>{e.status}</Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setMessageID(e.id)
                      setOpen(true)
                    }}
                  >
                    <PageviewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {messageID && (
        <EmailDetails
          title={'Email Details'}
          body={
            <>
              {isLoading ? (
                <Loading loadingMessage="Checking our email logs. This may take a while." />
              ) : (
                <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 27rem)' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography>Status</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>Event Time</Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {emailDetails?.events.map((e, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            <Typography>{e.event_name}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{e.processed}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          }
          dialogActions={<Button onClick={() => setOpen(false)}>Close</Button>}
          open={open}
        />
      )}
    </div>
  )
}
