import React from 'react'
import { IWizardComponent } from '../'

export default ({ active, setActive, steps }: IWizardComponent) => {
  const current = steps[active]
  return (
    <div className="kt-grid kt-wizard-v2 kt-wizard-v2--white">
      <div className="kt-grid__item kt-wizard-v2__aside">
        <div className="kt-wizard-v2__nav" style={{ marginTop: '-15%' }}>
          <div className="kt-wizard-v2__nav-items">
            {steps.map((step, i) => (
              <button
                className="kt-wizard-v2__nav-item navigation-link"
                key={i}
                data-ktwizard-type="step"
                data-ktwizard-state={active === i ? 'current' : 'step'}
                onClick={() => setActive(i)}
              >
                <div className="kt-wizard-v2__nav-body">
                  <div className="kt-wizard-v2__nav-label">
                    <div className="kt-wizard-v2__nav-label-title">{step.text}</div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div
        className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper"
        style={{ padding: 5 }}
      >
        {current ? current.component : null}
      </div>
    </div>
  )
}
