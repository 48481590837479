import React from 'react'
import Header from './header'
import { Sidebar } from './sidebar'

interface Props {
  children: any
}

export const Layout = ({ children }: Props) => (
  <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
    <Sidebar />
    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
      <Header />
      <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        {children}
      </div>
    </div>
  </div>
)
