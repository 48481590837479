import Store from 'app/redux/store'
import { toast } from 'react-toastify'
import { reorder } from 'app/lib/utility'
import { bindActions } from 'redux-zero/utils'
import { BusinessManagerStage as Stage } from '@marketing-milk/interfaces'

const Funnel = bindActions(
  store => ({
    // Override (For testing)
    override: (state: any, funnel) => ({
      pages: {
        ...state.pages,
        business: {
          ...state.pages.business,
          funnel: funnel,
        },
      },
    }),

    // Reset aka go back to preset selector
    reset: state => ({
      pages: {
        ...state.pages,
        business: {
          ...state.pages.business,
          funnel: {
            active: null,
            preset: 0,
            stages: [],
          },
        },
      },
    }),

    // Set Preset
    setPreset: (state, id: number) => ({
      pages: {
        ...state.pages,
        business: {
          ...state.pages.business,
          funnel: {
            active: 0,
            preset: id,
            stages: state.data.funnel.presets.data.find(x => x.id === id).stages,
          },
        },
      },
    }),

    // Add Blank Stage
    addStage: state => {
      let stages: Stage[] = [].concat(state.pages.business.funnel.stages)

      stages.push({
        name: 'New Stage',
        desc: 'This is a blank stage ready to pull data',
        icon: 'smile',
        support: ['conversions'],
        groups: [],
        estimate: false,
        order: 100,
        conversion_name: '',
        est_conversion_name: '',
        show_total: true,
        is_kpi: false,
        is_sub_kpi: false,
        show_overview: true,
        cost_per_metric: 1,
      })

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            funnel: {
              ...state.pages.business.funnel,
              stages: stages,
              active: stages.length - 1,
            },
          },
        },
      })
    },

    // Focus on Stage
    focusStage: (state, index: number | null) => ({
      pages: {
        ...state.pages,
        business: {
          ...state.pages.business,
          funnel: {
            ...state.pages.business.funnel,
            active: index,
          },
        },
      },
    }),

    // Re order stage
    changeOrder: (state, prior: number, updated: number) => {
      let stages: Stage[] = [].concat(state.pages.business.funnel.stages)
      const { active } = state.pages.business.funnel
      const current = stages[active]
      stages = reorder(stages, prior, updated)

      for (let i = 0; i < stages.length; i++) {
        stages[i].order = i
      }

      store.setState({
        pages: {
          ...state.pages,
          business: {
            ...state.pages.business,
            funnel: {
              ...state.pages.business.funnel,
              active: active !== null ? stages.findIndex(x => x == current) : null,
              stages: stages,
            },
          },
        },
      })
    },
  }),
  Store
)

export default Funnel
