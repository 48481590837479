import { Card } from './'
import React from 'react'

interface Props extends Card {
  active: boolean
  onClick: () => void
}

export default ({ active, component, text, icon, onClick }: Props) => (
  <div className="card">
    <div className="card-header">
      <div className={active ? 'card-title' : 'card-title collapsed'} onClick={onClick}>
        <i className={`fa fa-${icon}`} />
        {text}
      </div>
    </div>
    <div className={active ? '' : 'collapse'}>
      <div className="card-body">{component}</div>
    </div>
  </div>
)
