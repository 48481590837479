import React from 'react'
import { Auth0Role, UserWithBusiness } from '@marketing-milk/interfaces'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import { AssignRoles } from '../../modals/AssignRoles'
import { Delete } from '../../modals/Delete'
import { toast } from 'react-toastify'
import axios from 'axios'
import { roleService } from '../../../../../app/service/role'
import { EGuard } from '@marketing-milk/frontend'
import { useHistory } from 'react-router-dom'
import Guard from 'components/utility/guard'

export interface RolesTabProps {
  roles?: Auth0Role[]
  mmUser?: UserWithBusiness
  fetchMMUser: () => void
}

export function RolesTab({ roles, mmUser, fetchMMUser }: RolesTabProps) {
  const history = useHistory()
  const goToRoleDetails = (roleID: string) => history.push(`/admin/roles/${roleID}/details`)

  const onSave = () => {
    fetchMMUser()
  }

  const onDelete = async (auth0Role: Auth0Role) => {
    try {
      if (mmUser) {
        await roleService.addRolesToAuth0User(
          mmUser.id,
          mmUser?.assignedRoles?.filter(r => r.id !== auth0Role.id) ?? []
        )

        toast.success('Successfully removed role from user')
        fetchMMUser()
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data) {
        e.response.data?.errors?.forEach(err => {
          toast.error(err.description)
        })
      } else {
        toast.error(
          `We could not update ${mmUser?.firstName} ${mmUser?.lastName}'s account at this time`
        )
      }
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
        <Typography>All Roles assigned to this User</Typography>
        <Guard guard={EGuard.ManageRoles}>
          {({ status }) => {
            return !status ? null : <AssignRoles mmUser={mmUser} onSave={onSave} />
          }}
        </Guard>
      </div>
      <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 27rem)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Name</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>Description</Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mmUser?.assignedRoles?.map(role => (
              <TableRow key={role.id}>
                <Guard guard={EGuard.ManageRoles}>
                  {({ status }) => {
                    return !status ? (
                      <>
                        <TableCell component="th" scope="row">
                          <Typography data-testid={`role-name-cell-${role.name}`}>
                            {role.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>{role.description}</Typography>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          className="cursor-pointer"
                          component="th"
                          scope="row"
                          onClick={() => goToRoleDetails(role.id!)}
                        >
                          <Typography data-testid={`role-name-cell-${role.name}`}>
                            {role.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className="cursor-pointer"
                          align="left"
                          onClick={() => goToRoleDetails(role.id!)}
                        >
                          <Typography>{role.description}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Delete
                            data-testid={`delete-modal-${role.name}`}
                            title={'Remove from role?'}
                            body={`Are you sure that you want to remove ${mmUser?.email} from role "${role?.name}"?`}
                            onSubmitted={onDelete}
                            item={role}
                          />
                        </TableCell>
                      </>
                    )
                  }}
                </Guard>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
