import React, { useState, useEffect } from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Auth0RolePermission } from '@marketing-milk/interfaces'

export interface DeletePermissionModalProps {
  deletePermission?: Auth0RolePermission
  onDelete: (permission: Auth0RolePermission) => void
  onCancel?: () => void
}

export function DeletePermissionModal(props: DeletePermissionModalProps) {
  const [open, setOpen] = useState(!!props.deletePermission)

  useEffect(() => {
    // Check for role to delete, if one is present show modal
    !!props.deletePermission ? setOpen(true) : setOpen(false)
  }, [props.deletePermission])

  const onDelete = () => {
    setOpen(false)
    props.deletePermission && props.onDelete(props.deletePermission)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Delete Permission</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the permission:{' '}
          <strong>{props.deletePermission && props.deletePermission.value}</strong>? This can't be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button className="text-danger" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
