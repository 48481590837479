import { ReactNode } from 'react'
import { CreateBadDataSubmission, UserUpload } from '@marketing-milk/interfaces'

export interface BadDataSubmissionContextProviderProps {
  children: ReactNode
}

export interface BadDataSubmissionContext {
  submission: CreateBadDataSubmission
  setSubmission?: (changes: Partial<CreateBadDataSubmission>) => void
  uploads: UserUpload[]
}

export const defaultSubmissionContext: BadDataSubmissionContext = {
  submission: {
    name: '',
    desc: '',
    startDate: null as any,
    endDate: null as any,
    eventName: null as any,
    uploadIDs: [],
  },
  setSubmission: undefined,
  uploads: [],
}
