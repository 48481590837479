import Users from './users'
import Social from './social'
import Options from './options'
import Overview from './overview'
import { DataSources } from './data-sources'

export default {
  Users,
  Social,
  Options,
  Overview,
  DataSources,
}
