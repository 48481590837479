import React from 'react'
import { GridColDef } from '@material-ui/x-grid'
import { PodsColumnType } from './PodsTable.types'

const flexNum = 1
export const podsTableColumns: Array<GridColDef> = [
  {
    field: 'podNumber',
    flex: flexNum,
    type: PodsColumnType.PodNumber,
  },
  {
    field: 'podName',
    flex: flexNum,
    type: PodsColumnType.PodName,
  },
  {
    field: 'branch',
    flex: flexNum,
    type: PodsColumnType.Branch,
  },
  {
    field: 'actions',
    flex: flexNum,
    type: PodsColumnType.Actions,
  },
]
