import React from 'react'
import { Link } from 'react-router-dom'

interface IPortletProps {
  buttons?: Array<IButton>
  children: any
  className?: string
  title?: any
  col?: string
  headTools?: JSX.Element
}

class Portlet extends React.Component<IPortletProps> {
  render() {
    const { buttons = [], children, className = '', title, col = '12', headTools } = this.props
    return (
      <div className={`m-portlet col-${col} ${className}`}>
        {title ? (
          <div className="m-portlet__head">
            <div className="m-portlet__head-caption">
              <div className="m-portlet__head-title">
                <h3 className="m-portlet__head-text">{title}</h3>
              </div>
            </div>
            {headTools && <div className="m-portlet__head-tools">{headTools}</div>}
            {buttons.length > 0 ? (
              <div className="m-portlet__head-tools">
                <ul className="m-portlet__nav mt-3">
                  {buttons.map((button, i) => {
                    return (
                      <li className="m-portlet__nav-item d-none d-lg-inline-block" key={i}>
                        <Button button={button} />
                      </li>
                    )
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="m-portlet__body">{children}</div>
      </div>
    )
  }
}

interface IButtonProps {
  button: IButton
}

interface IButton {
  class: string
  to: string | React.MouseEventHandler
  text: string
}

class Button extends React.Component<IButtonProps> {
  render() {
    const { button } = this.props

    return typeof button.to === 'string' ? (
      <Link className={button.class} to={button.to}>
        {button.text}
      </Link>
    ) : (
      <button className={button.class} onClick={button.to}>
        {button.text}
      </button>
    )
  }
}

export default Portlet
