import React from 'react'

class Loading extends React.Component {
  render() {
    return (
      <div style={{ minHeight: '200px' }}>
        <h4>Loading Data</h4>
        <p>Please wait as we fetch this data</p>
        <div
          className="loader"
          style={{ fontSize: '1.4em', marginLeft: '1.5%', marginTop: '-2%' }}
        />
      </div>
    )
  }
}

export default Loading
