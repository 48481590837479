import Papa from 'papaparse'
import { omit } from 'lodash'
import { toast } from 'react-toastify'
import { Button } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { SaveDraftButtonProps } from './SaveDraftButton.types'
import { clientUploadService } from '@marketing-milk/frontend'
import { previousUploadsContext } from '../../../../context/PreviousUploadsContext'

export function SaveDraftButton({ upload, newData }: SaveDraftButtonProps) {
  function removeBadColumns(data: object): object {
    return omit(data, ['id', 'errors'])
  }
  const { updateUpload } = useContext(previousUploadsContext)
  const [isLoading, setLoading] = useState(false)

  async function saveChanges() {
    try {
      setLoading(true)
      const blob = new Blob([Papa.unparse(newData.map(removeBadColumns))], { type: 'csv' })
      const file = new File([blob], upload.versions[0]?.fileName ?? 'new.csv', { type: 'csv' })
      const newVersion = await clientUploadService.uploadFileVersion(
        upload.businessID,
        upload.id,
        file
      )
      updateUpload(upload.id, {
        versions: [newVersion, ...upload.versions],
      })
      toast.success(`Version ${newVersion.version} has been uploaded`)
    } catch (e) {
      toast.error('Your changes could not be saved at this time')
      throw e
    } finally {
      setLoading(false)
    }
  }

  const [buttonIcon, buttonText] = isLoading
    ? ['fa-spinner fa-spin', 'Uploading changes...']
    : ['fa-save', 'Upload Changes']

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={saveChanges}
      size="large"
      disabled={upload.locked}
    >
      <i className={`fa ${buttonIcon} mr-2`} />
      {buttonText}
    </Button>
  )
}
