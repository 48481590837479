import Date from './date'
import Block from './block'
import Select from './select'
import Toggle from './toggle'
import WYSIWYG from './wysiwyg'
import Regular from './regular'
import Textarea from './textarea'
import Checkbox from './checkbox'
import SelectSimple from './select-simple'

export class InputComponents {
  block = Block
  checkbox = Checkbox
  date = Date
  select = Select
  textarea = Textarea
  toggle = Toggle
  regular = Regular
  wysiwyg = WYSIWYG
  select_simple = SelectSimple
}

export default new InputComponents()
