import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  DialogProps,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Auth0Role, UserWithBusiness } from '@marketing-milk/interfaces'
import DeleteIcon from '@material-ui/icons/Delete'
import { userService } from '@marketing-milk/frontend'
import axios from 'axios'
import { toast } from 'react-toastify'
import { JsxElement } from 'typescript'

export interface DeleteProps<T> {
  title: JSX.Element | string
  body: JSX.Element | string
  item: T
  onSubmitted: (item: T) => void
  withButton?: boolean
  openModal?: boolean
}

export function Delete<T>({
  title,
  body,
  onSubmitted,
  item,
  withButton = true,
  openModal = false,
  ...props
}: DeleteProps<T> & Omit<DialogProps, 'title' | 'body' | 'open'>) {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setOpen(openModal)
  }, [openModal])

  return (
    <>
      {withButton && (
        <IconButton data-testid="delete-button" aria-label="delete" onClick={() => setOpen(true)}>
          <DeleteIcon style={{ color: '#F4516B' }} />
        </IconButton>
      )}

      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        keepMounted
        onClose={props.onClose ? props.onClose : handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        {...props}
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <Divider />
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            data-testid="delete-modal-remove-button"
            color="primary"
            onClick={() => {
              setOpen(false)
              onSubmitted(item)
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
