import { SavedAudience } from '@marketing-milk/interfaces'

export interface PodsTableProps {
  audiences: SavedAudience[]
  checkedAudiences?: SavedAudience[]
  recalcAudience(audience?: SavedAudience): void
  editAudience(audience?: SavedAudience): void
  viewAudience(audience?: SavedAudience): void
  deleteAudience?: (audience?: SavedAudience) => void
  onSetDefault?: (audience: SavedAudience, isDefault: boolean) => void
  onCheck?: (selectedAudiences: SavedAudience[]) => void
}

export enum PodsColumnType {
  PodNumber = 'podNumber',
  PodName = 'podName',
  Branch = 'branch',
  Actions = 'actions',
}
