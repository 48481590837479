import React from 'react'
import Item from '../item'

interface Props {
  main: {
    [key: string]: any
  }
}

export default ({ main }: Props) => {
  const columns = [
    {
      key: 'Business Name',
      value: main.business_name,
    },
    {
      key: 'Website URL',
      value: main.website,
    },
    {
      key: 'Facebook Account',
      value: main.fb_account_id,
    },
    {
      key: 'Facebook Page',
      value: main.fb_page_id,
    },
    {
      key: 'Facebook Offline Events',
      value: main.offline_event_set_id,
    },
    {
      key: 'Facebook Dataset',
      value: main.fb_dataset_id,
    },
  ]

  if (main.google_looker_embed_url && main.google_looker_embed_url.length > 0) {
    columns.push({
      key: 'Google Looker Embed URL',
      value: main.google_looker_embed_url,
    })
  }

  return (
    <Item header="Business Data">
      <div className="row ml-3">
        {columns.map(({ key, value }) => (
          <div className="col-6" key={key}>
            <div className="form-group">
              <label>{key}:</label>
              <input className="form-control" disabled value={value as any} />
            </div>
          </div>
        ))}
      </div>
    </Item>
  )
}
