import { toast } from 'react-toastify'
import { roleService } from 'app/service/role'
import { useGetParam } from '@marketing-milk/frontend'
import { Auth0Role, Auth0RolePermission } from '@marketing-milk/interfaces'
import { useFetchRolePermissions, useFetchRole, useFetchRoleUsers } from 'hooks'

export function useRoleDetails() {
  const roleID = useGetParam('id')

  // hooks to get all role info
  const roleQuery = useFetchRole(roleID)
  const permissionsQuery = useFetchRolePermissions(roleID)
  const usersQuery = useFetchRoleUsers(roleID)

  // role helpers
  const updateRole = async (roleID: string, roleDTO: Auth0Role) => {
    try {
      await roleService.updateRole(roleID, roleDTO)
      toast.success('Role updated successfully.')
      roleQuery.refetch()
    } catch (e) {
      toast.error('There was an error updating your role. Please try again.')
      console.log(e) //TODO: send to sentry?
    }
  }

  const deleteRole = async (roleID: string) => {
    try {
      await roleService.deleteRole(roleID)
      toast.success('Role successfully deleted.')
      roleQuery.refetch()
    } catch (e) {
      toast.error('There was an error deleting your role.')
      console.log(e) //TODO: send to sentry?
    }
  }

  // permission helpers
  const deletePermissionFromRole = async (permission: Auth0RolePermission) => {
    try {
      await roleService.deleteRolePermissions(roleQuery.data!.id!, [permission])
      toast.success('Permission successfully removed from role.')
      roleQuery.refetch()
      permissionsQuery.refetch()
    } catch (e) {
      toast.error('There was an error removing the permission from your role.')
      console.log(e) //TODO: send to sentry?
    }
  }

  const addPermissions = async (permissions: Auth0RolePermission[]) => {
    try {
      await roleService.addRolePermissions(roleQuery.data!.id!, permissions)
      toast.success('Permission(s) have successfully been added to your role.')
      roleQuery.refetch()
      permissionsQuery.refetch()
    } catch (e) {
      toast.error('There was an error adding permission(s) to your role.')
      console.log(e) //TODO: send to sentry?
    }
  }

  // user helpers
  const addUsers = async (auth0UserIDs: string[]) => {
    try {
      await roleService.addRoleUsers(roleQuery.data!.id!, auth0UserIDs)
      toast.success('User(s) successfully added to this role.')
      roleQuery.refetch()
      usersQuery.refetch()
    } catch (e) {
      toast.error('There was an error adding users to this role.')
      console.log(e) //TODO: send to sentry?
    }
  }

  const deleteUser = async (auth0UserID: string) => {
    try {
      await roleService.removeRolesFromUser(auth0UserID, [roleQuery.data!])
      toast.success('User has been removed from this role.')
      roleQuery.refetch()
      usersQuery.refetch()
    } catch (e) {
      toast.error('There was an error deleting the user from this role.')
      console.log(e) //TODO: send to sentry?
    }
  }

  async function addUsersToRole(userIds: number[]) {
    try {
      await roleService.addUsersToRole(roleQuery.data?.id!, userIds)
      toast.success('User(s) successfully added to this role.')
      roleQuery.refetch()
      usersQuery.refetch()
    } catch (e) {
      toast.error('There was an error adding users to this role.')
    }
  }

  async function removeUserFromRole(userId: number) {
    try {
      await roleService.removeRoleFromUser(roleQuery.data?.id!, userId)
      toast.success('User has been removed from this role.')
      roleQuery.refetch()
      usersQuery.refetch()
    } catch (e) {
      toast.error('There was an error deleting the user from this role.')
    }
  }

  return {
    // query hooks
    roleQuery,
    permissionsQuery,
    usersQuery,
    // role helpers
    updateRole,
    deleteRole,
    // permission helpers
    deletePermissionFromRole,
    addPermissions,
    // user helpers
    addUsers,
    deleteUser,
    addUsersToRole,
    removeUserFromRole,
  }
}
