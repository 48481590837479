import React, { ReactNode } from 'react'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { SearchRounded } from '@material-ui/icons'

export interface PageHeaderProps {
  title: string
  filterValue: string
  placeholder?: string
  onChangeFilter: (filter: string) => void
  children?: ReactNode
}

export function PageHeader({
  title,
  filterValue,
  onChangeFilter,
  children,
  placeholder,
}: PageHeaderProps) {
  return (
    <Toolbar className="mb-3" component={Paper}>
      <Typography variant="h5">{title}</Typography>
      <div className="mr-0 ml-auto d-flex">
        <TextField
          style={{ maxWidth: 500, minWidth: 300 }}
          className="mr-3"
          placeholder={placeholder}
          variant="standard"
          value={filterValue}
          onChange={({ target }) => onChangeFilter(target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
        {children}
      </div>
    </Toolbar>
  )
}
