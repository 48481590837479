import { franchiseService } from '@marketing-milk/frontend'
import { Franchise } from '@marketing-milk/interfaces'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import DeleteIcon from '@material-ui/icons/Delete'

interface FranchiseDeleteProps {
  franchise: Franchise
  onDelete: () => void
}

export const FranchiseDelete = (props: FranchiseDeleteProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleDelete = async () => {
    setOpen(false)

    try {
      await franchiseService.delete(props.franchise.id)
      props.onDelete()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(`Failed to delete ${props.franchise.name}: ${e.message}`)
      } else {
        toast.error('Something unexpected went wrong. Try again.')
      }
    }
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        endIcon={<DeleteIcon />}
        style={{ backgroundColor: '#ff5555', color: 'white' }}
      >
        Delete
      </Button>

      <Dialog open={open} keepMounted>
        <DialogContent style={{ width: '400px' }}>
          Are you sure you want to delete{' '}
          <span className="font-weight-bold text-danger">{props.franchise.name}</span>? The
          businesses within this franchise will <strong>not</strong> be deleted.
        </DialogContent>
        <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
          <Button onClick={() => setOpen(false)} variant="contained">
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleDelete}
            variant="contained"
            style={{ backgroundColor: '#ff5555', color: 'white' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
