import React from 'react'
import { Link } from 'react-router-dom'
import Portlet from 'components/reusable/portlet'

export default () => (
  <div className="col-md-6">
    <Portlet
      title={
        <span className="text-danger">
          <i className="fa fa-exclamation-triangle" /> An Error Occurred
        </span>
      }
    >
      <div className="row">
        <div className="col-md-6">
          <strong>Server Error</strong>
          <p>An error has occurred on our side and it's been reported to our developers.</p>
          <Link className="btn btn-outline-dark" to="/admin/control-panel">
            <i className="fa fa-home" /> Home
          </Link>
        </div>
        <div className="col-md-6 text-right">
          <img alt="logo small" src="/assets/admin/img/logo-small.png" height={200} />
        </div>
      </div>
    </Portlet>
  </div>
)
