import React from 'react'
import Select from 'react-select'
import Interface from '../../interface/input'

export default ({
  column,
  className = '',
  disabled = false,
  onChange,
  options,
  value,
}: Interface) => {
  const optionsParsed = options!.map(x => ({
    label: x,
    value: x,
  }))
  return (
    <Select
      className={className.replace('form-control', '')}
      isDisabled={disabled}
      //@ts-ignore //not sure why this started bugging randomly but dont have time to debug it
      onChange={e => onChange(column, e!.value)}
      options={optionsParsed}
      value={optionsParsed.find(x => x.value === value)}
      getOptionLabel={(x: any) => x.label}
      getOptionValue={(x: any) => x.value}
    />
  )
}
