import Store from 'app/redux/store'
import { NestAPI } from 'app/nestapi'
import Storage from 'app/lib/storage'
import Redux from 'app/interface/redux'
import { AxiosResponse } from 'axios'
import { bindActions } from 'redux-zero/utils'
import Fetch from 'app/redux/actions/data/fetch'
import { User } from '@marketing-milk/interfaces'
import { setBearerGlobally } from '../../../../api-helpers'
import jwt_decode from 'jwt-decode'

const Session = bindActions(
  store => ({
    // Reuse stored session or initialize empty one
    init: async () => {
      const token = Storage.get('jwt')
      if (!!token) {
        return Session.loginWithToken(token)
      } else {
        return Session.logout()
      }
    },

    loginWithToken: async (state: Redux, jwt: string): Promise<void> => {
      try {
        setBearerGlobally(jwt)
        // @ts-ignore - ignoring the doNotIntercept config because this isn't an Axios typing but can still be passed manually
        const nestSession: AxiosResponse<User> = await NestAPI.get('auth')
        return Session.start(nestSession, jwt)
      } catch (e) {
        return Session.logout()
      }
    },

    auth0LoginWithToken: async (state: Redux, jwt: string): Promise<void> => {
      setBearerGlobally(jwt)
      const nestSession: AxiosResponse<User> = await NestAPI.get('auth')
      return Session.start(nestSession, jwt)
    },

    reupToken: (state: Redux, jwt: string): void => {
      setBearerGlobally(jwt)

      const { permissions } = jwt_decode(jwt) as { permissions: string[] }

      store.setState({
        core: {
          ...state.core,
          session: {
            ...state.core.session,
            user: {
              ...state.core.session.user,
              permissions,
            },
          },
        },
      })
    },

    // Store user session inside redux and storage
    start: (state: Redux, user: User, token: string): void => {
      Fetch()
      localStorage.setItem('jwt', token)
      const decoded: { permissions: string[] } = jwt_decode(token)

      store.setState({
        core: {
          ...state.core,
          session: {
            active: true,
            ready: true,
            user: { ...user, permissions: decoded.permissions },
          },
        },
      })
    },

    // Clear user session from redux and store
    logout: (state: Redux): void => {
      setBearerGlobally(null)

      Storage.del('jwt')
      store.setState({
        core: {
          ...state.core,
          session: {
            active: false,
            ready: true,
          },
        },
      })
    },
  }),
  Store
)

export default Session
