import React from 'react'
import {
  OwnershipGroup,
  PaginatedOwnershipGroupResponse,
  PaginationQueryParams,
} from '@marketing-milk/interfaces'
import { useQuery } from 'react-query'
import { ownershipGroupService } from '@marketing-milk/frontend'

export function useFetchAllOwnershipGroups() {
  const pageSize: number = 15
  const [ownershipGroups, setOwnershipGroups] = React.useState<OwnershipGroup[]>()
  const [paginationParams, setPaginationParams] = React.useState<PaginationQueryParams>({
    searchTerm: '',
    searchProp: 'name',
    sortProp: 'createdAt',
    sortDirection: 'DESC',
    page: 1,
    take: pageSize,
  })

  const query = useQuery<PaginatedOwnershipGroupResponse, string>(
    ['ownership-groups-paginated', paginationParams],
    () => ownershipGroupService.getAll(paginationParams),
    {
      keepPreviousData: true,
      staleTime: 5000,
      retry: false,
    }
  )

  const { data } = query

  React.useEffect(() => {
    if (!data) {
      return
    }
    setOwnershipGroups(data.ownershipGroups)
  }, [data])

  return {
    ownershipGroups,
    count: data?.count || 0,
    current: data?.ownershipGroups.length,
    pageSize,
    ...paginationParams,
    setPaginationParams,
    ...query,
  }
}
