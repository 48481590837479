import React, { useState, useEffect } from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { User } from '@marketing-milk/interfaces'

export interface DeleteUserModalProps {
  deleteUser?: User
  removeUser: (userId: number) => void
  onCancel?: () => void
}

export function DeleteUserModal(props: DeleteUserModalProps) {
  const [open, setOpen] = useState(!!props.deleteUser)

  useEffect(() => {
    // Check for user to delete, if one is present show modal
    !!props.deleteUser ? setOpen(true) : setOpen(false)
  }, [props.deleteUser])

  const onDelete = () => {
    setOpen(false)
    props.deleteUser && props.removeUser(props.deleteUser.id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove this user from this role:{' '}
          <strong>{props.deleteUser && props.deleteUser.email}</strong>? This can't be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button className="text-danger" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
