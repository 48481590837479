import React, { useState } from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useFetchAllUsers } from 'hooks'
import { CustomSelect } from '../users/shared/CustomSelect'
import { User } from '@marketing-milk/interfaces'

export interface AddUsersModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  addedUsers?: User[]
  onSubmit?: (userIds: number[]) => void
}

export function AddUsersModal(props: AddUsersModalProps) {
  const { isOpen, setIsOpen, addedUsers, onSubmit: handleSubmit } = props
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const { data, refetch, isLoading, ...usersQuery } = useFetchAllUsers()
  const hasSelectedUsers = selectedUsers?.length > 0

  const users = React.useMemo(() => {
    return data?.users.filter(user => !addedUsers?.map(value => value.id).includes(user.id))
  }, [data?.users, addedUsers])

  const handleClose = () => {
    setIsOpen(false)
    setSelectedUsers([])
  }

  const onSubmit = () => {
    if (hasSelectedUsers) {
      handleSubmit?.(selectedUsers)
    }
    handleClose()
  }

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleClose}>
        <DialogTitle>Add Users</DialogTitle>
        <DialogContent>
          <DialogContentText>Assign more users to this role.</DialogContentText>
          <CustomSelect
            options={users?.map((user: User) => ({
              label: `${user.firstName} ${user.lastName}`.trim(),
              value: user.id,
              item: user,
            }))}
            onChange={value => setSelectedUsers(value?.map(v => v.item.id))}
            clearSelection={!isOpen}
            placeHolderText="Select user(s)..."
            fieldName="users"
            isMulti={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!hasSelectedUsers} onClick={onSubmit}>
            Add Users
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
