import { toast } from 'react-toastify'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { clientUploadService } from '@marketing-milk/frontend'
import { SubmitVersionButtonProps } from './SubmitVersionButton.types'
import { SubmitVersionModal } from '../submit-modal/SubmitVersionModal'
import { EventName, ReviewStatus, UserUploadVersion } from '@marketing-milk/interfaces'
import { previousUploadsContext } from '../../../context/PreviousUploadsContext'

export function SubmitVersionButton({
  businessID,
  upload,
  latestVersion,
  badDataEventNames,
}: SubmitVersionButtonProps) {
  const { updateUpload } = useContext(previousUploadsContext)
  const [badDataEvent, setBadDataEvent] = useState<EventName>()
  useEffect(() => {
    upload.eventName &&
      setBadDataEvent(badDataEventNames.includes(upload.eventName) ? upload.eventName : undefined)
  }, [upload])

  function createVersionSubmission(): void {
    const versions: UserUploadVersion[] = [...upload!.versions]
    versions.find(version => version.id === latestVersion!.id)!.reviewStatus = 'submitted'
    updateUpload(upload.id, {
      versions,
      locked: true,
    })
  }

  async function cancelVersionSubmission(): Promise<void> {
    await clientUploadService.cancelSubmission(businessID, upload!.id, latestVersion!.id)
    const versions: UserUploadVersion[] = [...upload!.versions]

    versions.find(version => version.id === latestVersion!.id)!.reviewStatus = 'not_submitted'
    updateUpload(upload.id, {
      versions,
      locked: true,
    })
  }

  async function sendToIngest(): Promise<void> {
    try {
      const uploadVersions: UserUploadVersion[] = upload!.versions
      await clientUploadService.sendToIngest(businessID, upload!.id, latestVersion!.id)
      uploadVersions.find(version => version.id === latestVersion!.id)!.uploadStatus = 'upload'
      updateUpload(upload.id, {
        versions: uploadVersions,
        locked: true,
      })
      toast.success(
        'Your upload has been sent to for processing. Be sure to check back over the next few days for more results'
      )
    } catch (e) {
      toast.error('Something went wrong and your upload could not be sent at this time.')
      const uploadVersions: UserUploadVersion[] = upload!.versions
      uploadVersions.find(version => version.id === latestVersion!.id)!.uploadStatus = 'error'
      updateUpload(upload.id, {
        versions: uploadVersions,
        locked: true,
      })
      throw e
    }
  }

  function getSubmitButton(): ReactNode {
    if (latestVersion === undefined) {
      return null
    }

    if (latestVersion.uploaded) {
      return <p>This upload has already been submitted to the Ingestor.</p>
    }

    const submissionStatusToButton: Record<ReviewStatus, ReactNode> = {
      not_submitted: (
        <SubmitVersionModal
          businessID={businessID}
          upload={upload!}
          version={latestVersion}
          onCreation={createVersionSubmission}
        />
      ),
      submitted: (
        <button className="btn btn-warning spec-cancel-btn" onClick={cancelVersionSubmission}>
          <i className="fas fa-times mr-2" /> Cancel Submission
        </button>
      ),
      rejected: (
        <button className="btn btn-danger spec-cancel-btn" disabled>
          <i className="fas fa-exclamation-triangle mr-2" /> Upload Rejected
        </button>
      ),
      approved: (
        <button
          className="btn btn-success spec-cancel-btn"
          disabled={upload!.locked}
          onClick={sendToIngest}
        >
          <i className="fas fa-cloud mr-2" /> Process Data
        </button>
      ),
    }

    return submissionStatusToButton[latestVersion.reviewStatus]
  }

  return (
    <>
      {latestVersion?.uploaded || !badDataEvent ? (
        getSubmitButton()
      ) : (
        <p className="mt-4 text-danger">
          <i className="fas fa-exclamation-triangle" />
          &nbsp;You have unresolved bad data submissions that must be resolved before any new data
          uploads may be approved for event name:&nbsp;
          <span className="font-weight-bold">{badDataEvent}</span>.
        </p>
      )}
    </>
  )
}
