import { UserWithBusiness } from '@marketing-milk/interfaces'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import React from 'react'

type ResendWelcomeEmailProps = {
  onResendRequest: () => void
  showModal: boolean
  closeModal: () => void
}

export function ResendWelcomeEmailModal({
  onResendRequest,
  showModal,
  closeModal,
}: ResendWelcomeEmailProps) {
  const onConfirmSendRequest = () => {
    onResendRequest()
    closeModal()
  }
  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={showModal}
        keepMounted
        onClose={closeModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Resend Welcome Email Confirmation</DialogTitle>

        <Divider />
        <DialogContent>
          <>
            <Typography style={{ fontSize: 15, paddingBottom: 15 }}>
              A welcome email has been sent to this user within the past 24 hours. If you need to
              send another, please click the button below.
            </Typography>

            <i>
              <Typography>
                Note: if the user still has not received the email, please verify that it is not
                being sent to their spam folder. You may send them the following link to add us to
                their trusted users: <br />
                <a href="https://www.marketingmilk.com/whitelist/">
                  https://www.marketingmilk.com/whitelist/
                </a>
              </Typography>
            </i>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" onClick={onConfirmSendRequest}>
            SendEmail
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
