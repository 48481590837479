import Route from './route'
import Errors from './errors'
import Session from './session'
import Core from 'app/interface/redux/types/core'

const DefaultCore: Core = {
  route: Route,
  errors: Errors,
  session: Session,
}

export default DefaultCore
