import React from 'react'
import Select from 'react-select'
import { BusinessManagerGroup as Group } from '@marketing-milk/interfaces'

export interface SelectFunnelStageGroupProps {
  funnelStageGroups: Group[]
  onChooseGroup: (groupId: number) => void
  selected?: number
}

export const SelectFunnelStageGroup = ({
  funnelStageGroups,
  selected,
  onChooseGroup,
}: SelectFunnelStageGroupProps) => {
  const options = funnelStageGroups.map(_ => ({
    label: `${_.name} (${_.sources.toString()})`,
    value: _.id,
  }))

  return (
    <Select
      options={options}
      value={selected ? options.find(_ => _.value === selected) : null}
      onChange={(opt: any) => onChooseGroup(opt.value)}
    />
  )
}
