import React from 'react'
import { BadUploadList } from '../../components/bad-upload-table/BadUploadList'
import { SelectDateRange } from './select-date-range/SelectDateRange'

export function WhatDateRange() {
  return (
    <>
      <h4>When did these problems occur?</h4>
      <SelectDateRange defaultFocus="startDate" />
      <hr />
      <BadUploadList />
    </>
  )
}
