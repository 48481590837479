import Builder from './builder'
import { toast } from 'react-toastify'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import Loading from 'components/message/loading'
import { RouteComponentProps } from 'react-router'
import ErrorActions from 'app/redux/actions/core/errors'
import BusinessActions from 'app/redux/actions/pages/business'
import DatasetActions from 'app/redux/actions/data/funnel/dataset'
import { businessManagerService } from '../../../app/service/business-manager'
import { BusinessConfig, BusinessManagerBusiness } from '@marketing-milk/interfaces'
import { NestAPI } from '../../../app/nestapi'

interface Props extends Redux, RouteComponentProps {}
interface State {
  loading: boolean
}

class Edit extends PureComponent<Props> {
  state: State = {
    loading: true,
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const { business }: any = this.props.match.params

    const result: BusinessManagerBusiness = await NestAPI.get(
      `businesses/manage/business/${business}`
    )
    await DatasetActions.loadBusiness(business)

    BusinessActions.init({
      ...result,
      id: business,
    })

    this.setState({
      loading: false,
    })
  }

  onSubmit = async () => {
    const {
      pages: { business },
    } = this.props
    let toastId
    try {
      toastId = toast.info(
        <span>
          Saving business information <i className="fa fa-spinner fa-spin ml-2" />
        </span>,
        { autoClose: false }
      )
      // not worth here to attempt to detangle and solidify the redux interface. The Backend has very clear type-safe validation here
      await businessManagerService.updateBusiness(business.id!, business as any as BusinessConfig)

      ErrorActions.clear()
      toast.update(toastId, {
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        render: 'Business has been updated',
      })
    } catch (e: any) {
      if (e.response?.data?.data) {
        const { data } = e.response.data
        const columns = Object.keys(data)
        const errors = columns.map(x => ({
          column: x,
          message: Array.isArray(data[x]) ? `${x}: ${data[x][0]}` : data[x],
        }))
        ErrorActions.setErrors(errors)
      }
      toast.update(toastId, {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        render: 'Business could not be updated',
      })
    }
  }

  render() {
    const { loading } = this.state
    return loading ? <Loading /> : <Builder onSubmit={this.onSubmit} new={false} />
  }
}

export default Connect(Edit)
