import React from 'react'

interface LoadingProps {
  loadingMessage?: string
}

export function Loading(props: LoadingProps) {
  let { loadingMessage = 'Loading data' } = props

  return (
    <div className="kt-widget7 kt-widget7--skin-light">
      <div className="kt-widget7__desc">
        <i className="fas fa-5x fa-spinner rotate text-brand" />
      </div>
      <div className="kt-widget7__content">
        <div className="kt-widget7__info">
          <h3 className="kt-widget7__username">{loadingMessage}</h3>
        </div>
      </div>
    </div>
  )
}
