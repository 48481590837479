import React from 'react'
import Redux from 'app/interface/redux'
import Form from 'components/base/form'
import Connect from 'app/redux/connect'
import Basic from 'app/redux/actions/pages/business/basic'
import Input from 'components/base/form/interface/input'

const Expenses = ({
  pages: {
    business: { main },
  },
}: Redux) => (
  <Form
    save={false}
    onChange={Basic.update}
    onSubmit={() => null}
    groups={[
      {
        header: 'Expenses',
        rows: [
          {
            column: 6,
            fields: [
              {
                column: 'mktg_retainer',
                text: 'Marketing Retainer',
                type: 'regular',
                subType: 'number',
                value: main.mktg_retainer,
              } as Input,
              {
                column: 'monthly_spend',
                text: 'Monthly Ad Spend',
                type: 'regular',
                subType: 'number',
                value: main.monthly_spend,
              } as Input,
              {
                column: 'first_initial_purchase',
                text: 'First Initial Purchase',
                type: 'regular',
                subType: 'number',
                value: main.first_initial_purchase,
              } as Input,
            ],
          },
        ],
      },
    ]}
  />
)

export default Connect(Expenses)
