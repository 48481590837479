export default {
  set: (name: string, data: object): void => {
    localStorage.setItem(name, JSON.stringify(data))
  },

  get: (name: string): object | string => {
    const result = localStorage.getItem(name) || ''
    return isValid(result) ? JSON.parse(result) : result
  },

  del: (name: string): void => {
    localStorage.removeItem(name)
  },
}

const isValid = (str: any): boolean => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}
