import React from 'react'
import Redux from 'app/interface/redux'
import Form from 'components/base/form'
import Connect from 'app/redux/connect'
import Basic from 'app/redux/actions/pages/business/basic'
import Input from 'components/base/form/interface/input'

const Goal = ({
  pages: {
    business: { main },
  },
}: Redux) => (
  <Form
    save={false}
    onChange={Basic.update}
    onSubmit={() => null}
    groups={[
      {
        header: 'Goal',
        rows: [
          {
            column: 12,
            fields: [
              {
                column: 'goal_text',
                text: 'Description',
                type: 'textarea',
                value: main.goal_text,
              } as Input,
            ],
          },
        ],
      },
    ]}
  />
)

export default Connect(Goal)
