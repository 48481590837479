import React from 'react'
import Redux from 'app/interface/redux'
import Form from 'components/base/form'
import Connect from 'app/redux/connect'
import Input from 'components/base/form/interface/input'
import { AttrWindowActions } from '../../../../../../../../app/redux/actions/pages/business/attribution-windows'
import { AttrDuration } from '@marketing-milk/interfaces'

const options: AttrDuration[] = ['1d', '7d', '28d']

const Attribution = ({
  pages: {
    business: { main },
  },
}: Redux) => (
  <>
    <Form
      save={false}
      onChange={AttrWindowActions.update}
      onSubmit={() => null}
      groups={[
        {
          header: 'Offline Attribution',
          rows: [
            {
              column: 6,
              fields: [
                {
                  column: 'offline.view',
                  text: 'View Attribution',
                  type: 'select_simple',
                  options: options,
                  value: main.attr_windows.offline.view,
                } as Input,
                {
                  column: 'offline.click',
                  text: 'Click Attribution',
                  type: 'select_simple',
                  options: options,
                  value: main.attr_windows.offline.click,
                } as Input,
              ],
            },
          ],
        },
        {
          header: 'Online Attribution',
          rows: [
            {
              column: 6,
              fields: [
                {
                  column: 'online.view',
                  text: 'View Attribution',
                  type: 'select_simple',
                  options: options,
                  value: main.attr_windows.online.view,
                } as Input,
                {
                  column: 'online.click',
                  text: 'Click Attribution',
                  type: 'select_simple',
                  options: options,
                  value: main.attr_windows.online.click,
                } as Input,
              ],
            },
          ],
        },
      ]}
    />
    <span className="text-info ml-4">
      Please note, changes on your dashboard may take 5-10 minutes to reflect. If trying to compare
      different attribution settings in real-time, use the Attribution Check in the Useful Tools
      section.
    </span>
  </>
)

export default Connect(Attribution)
