import React, { useContext } from 'react'
import { TextField } from '@material-ui/core'
import { badDataSubmissionContext } from '../../../submission-context/BadDataSubmissionContext'

export function InputReportDesc() {
  const { submission, setSubmission } = useContext(badDataSubmissionContext)
  return (
    <TextField
      label="Describe The Issue"
      value={submission.desc}
      fullWidth
      onChange={e => setSubmission!({ desc: e.target.value })}
      multiline
      rows={4}
    />
  )
}
