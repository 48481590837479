import Goal from './goal'
import Phase from './phase'
import Expenses from './expenses'
import Calculator from './calculator'
import Attribution from './attribution'
import Repeat from './repeat-check'

export default {
  Goal,
  Phase,
  Expenses,
  Calculator,
  Attribution,
  Repeat,
}
