import React from 'react'
import Base from './base'

class Good extends React.Component {
  render() {
    return <Base {...this.props} />
  }
}

export default Good
