import { branchService } from '@marketing-milk/frontend'
import { Branch } from '@marketing-milk/interfaces'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import DeleteIcon from '@material-ui/icons/Delete'

interface BranchDeleteProps {
  branch: Branch
  onDelete: () => void
}

export const BranchDelete = (props: BranchDeleteProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleDelete = async () => {
    setOpen(false)

    try {
      await branchService.delete(props.branch.id)
      props.onDelete()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(`Failed to delete ${props.branch.branchName}: ${e.message}`)
      } else {
        toast.error('Something unexpected went wrong. Try again.')
      }
    }
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<DeleteIcon />}
        style={{ backgroundColor: '#ff5555', color: 'white' }}
      >
        Delete
      </Button>

      <Dialog open={open} keepMounted>
        <DialogContent style={{ width: '400px' }}>
          Are you sure you want to delete{' '}
          <span className="font-weight-bold text-danger">{props.branch.branchName}</span>? The
          businesses within this branch will <strong>not</strong> be deleted, however the pods
          belonging to it will be deleted.
        </DialogContent>
        <DialogActions style={{ flex: 'row', justifyContent: 'space-between', padding: '1em' }}>
          <Button onClick={() => setOpen(false)} variant="contained">
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleDelete}
            variant="contained"
            style={{ backgroundColor: '#ff5555', color: 'white' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
