import Info from './info'
import Groups from './groups'
import Features from './features'
import { BusinessManagerStage as Stage } from '@marketing-milk/interfaces'

export default {
  Info,
  Features,
  Groups,
}

export interface Component extends Stage {
  isDeveloper?: boolean
}
