import { useState, useEffect } from 'react'
import { businessManagerService } from 'app/service/business-manager'
import { MatchRate } from '@marketing-milk/interfaces'

export type MatchRateWithId = MatchRate & { id: string }

export type UseHistoricalMatchRateReturn = {
  data: MatchRateWithId[] | null
  isLoading: boolean
  error: Error | null
}

const useHistoricalMatchRate = (
  businessID: number,
  eventType: string[],
  shouldGetHistoricalMatchRates: boolean
): UseHistoricalMatchRateReturn => {
  const [data, setData] = useState<MatchRateWithId[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!businessID || !eventType || !shouldGetHistoricalMatchRates) {
      return
    }

    const fetchData = async () => {
      setIsLoading(true)
      const matchRates: MatchRateWithId[] = []

      try {
        for (const event of eventType) {
          const matchRate = await businessManagerService.getHistoricalMatchRate(businessID, event)
          matchRates.push({ ...matchRate, id: event })
        }

        setData(matchRates)
      } catch (err) {
        const error =
          err instanceof Error
            ? err
            : new Error('An unexpected error occurred fetching historical match rates: ' + err)
        setError(error)
        setData([{ id: '', matchRate: 0 }])
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [businessID, eventType, shouldGetHistoricalMatchRates])

  return { data, isLoading, error }
}

export default useHistoricalMatchRate
