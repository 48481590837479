import { EventName, UserUpload, UserUploadVersion } from '@marketing-milk/interfaces'

export interface SubmitVersionModalProps {
  businessID: string
  upload: UserUpload
  version: UserUploadVersion
  onCreation: () => void
}

export interface SubmitVersionModalState {
  lastUpload?: UserUpload
  eventName?: EventName
  showModal: boolean
  showSpinner: boolean
}

export const defaultSubmitVersionModalState: SubmitVersionModalState = {
  eventName: undefined,
  showModal: false,
  showSpinner: false,
}

export interface Option {
  label: EventName
  value: EventName
}
