import React from 'react'
import Stages from './stages'
import Editor from './editor'
import Presets from './presets'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'

const Start = ({
  pages: {
    business: {
      funnel: { active, preset },
    },
  },
}: Redux) => {
  return preset === 0 ? <Presets /> : active !== null ? <Editor /> : <Stages />
}

export const Standalone = Start
export default Connect(Start)
