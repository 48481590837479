import Presets from 'app/interface/redux/types/data/funnel/presets'

const Default: Presets = {
  data: [
    // Custom
    {
      id: 1,
      icon: 'fas fa-cogs',
      name: 'Custom',
      desc: 'Enter a description that best fits what types of conversions are made in this stage',
      stages: [
        {
          name: 'Stage 1',
          icon: 'fa fa-question',
          desc: 'Stage 1',
          support: ['conversions'],
          order: 1,
          groups: [],
          estimate: false,
          conversion_name: '',
          est_conversion_name: '',
          show_total: true,
          is_kpi: false,
          is_sub_kpi: false,
          show_overview: true,
          cost_per_metric: 1,
        },
      ],
    },

    // Standard
    {
      id: 2,
      icon: 'fas fa-check-square',
      name: 'Standard',
      desc: 'The basic funnel that works for most businesses',
      stages: [
        {
          name: 'Distribution',
          icon: 'fa fa-users-class',
          desc: 'Your overall performance of your Facebook ads',
          support: ['conversions'],
          order: 1,
          groups: [
            {
              name: 'Impressions',
              desc: 'Amount of times your ads appeared on the Facebook timeline',
              sources: ['impressions'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Impressions',
          est_conversion_name: '',
          show_total: false,
          is_kpi: false,
          is_sub_kpi: false,
          show_overview: false,
          cost_per_metric: 1000,
        },
        {
          name: 'Research',
          icon: 'fas fa-search',
          desc: 'Your overall metrics of users visiting your website',
          support: ['conversions'],
          order: 2,
          groups: [
            {
              name: 'Direct Page Views',
              desc: 'Amount of times a customer visited your website directly via the url',
              sources: ['fbpixel/PageView'],
              extrapolation_type: 'Legacy',
            },
            {
              name: 'Indirect Page Views',
              desc: 'Amount of times a customer visited a page on your website from an external source',
              sources: ['fbpixel/ViewContent'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Page Views',
          est_conversion_name: '',
          show_total: true,
          is_kpi: false,
          is_sub_kpi: false,
          show_overview: true,
          cost_per_metric: 1,
        },
        {
          name: 'Leads',
          icon: 'fas fa-user-plus',
          desc: 'Your overall metrics of lead acquisition',
          support: ['conversions'],
          order: 3,
          groups: [
            {
              name: 'Direct Facebook Lead',
              desc: 'A lead that signed up directly on Facebook',
              sources: ['fbleads'],
              extrapolation_type: 'Legacy',
            },
            {
              name: 'Phone Call',
              desc: 'A lead that saw your ad and then called your business',
              sources: ['offline_events/AddToCart'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Leads',
          est_conversion_name: '',
          show_total: true,
          show_overview: true,
          is_kpi: false,
          is_sub_kpi: false,
          cost_per_metric: 1,
          stage_target: 'leads',
        },
        {
          name: 'Purchases',
          icon: 'fas fa-usd-square',
          desc: 'Your overall metrics of actual revenue coming into your business',
          support: ['conversions', 'revenue'],
          order: 4,
          groups: [
            {
              name: 'Purchases',
              desc: 'Amount of purchases from your business',
              sources: ['offline_events/Purchase'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Purchases',
          est_conversion_name: '',
          show_total: true,
          show_overview: true,
          is_kpi: true,
          is_sub_kpi: false,
          cost_per_metric: 1,
        },
      ],
    },

    // Fitness
    {
      id: 3,
      icon: 'fas fa-dumbbell',
      name: 'Fitness',
      desc: 'Created for fitness studios to show the customer journey from lead, to intro, to member',
      stages: [
        {
          name: 'Distribution',
          icon: 'fa fa-users-class',
          desc: 'Your overall performance of your Facebook ads',
          support: ['conversions'],
          order: 1,
          groups: [
            {
              name: 'Impressions',
              desc: 'Amount of times your ads appeared on the Facebook timeline',
              sources: ['impressions'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Impressions',
          est_conversion_name: '',
          show_total: true,
          is_kpi: false,
          is_sub_kpi: false,
          show_overview: true,
          cost_per_metric: 1000,
        },
        {
          name: 'Research',
          icon: 'fas fa-search',
          desc: 'Your overall metrics of users visiting your website',
          support: ['conversions'],
          order: 2,
          groups: [
            {
              name: 'Direct Page Views',
              desc: 'Amount of times a customer visited your website directly via the url',
              sources: ['fbpixel/PageView'],
              extrapolation_type: 'Legacy',
            },
            {
              name: 'Indirect Page Views',
              desc: 'Amount of times a customer visited a page on your website from an external source',
              sources: ['fbpixel/ViewContent'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Page Views',
          est_conversion_name: '',
          show_total: true,
          show_overview: true,
          is_kpi: false,
          is_sub_kpi: false,
          cost_per_metric: 1,
        },
        {
          name: 'Leads',
          icon: 'fas fa-user-plus',
          desc: 'Your overall metrics of lead acquisition',
          support: ['conversions'],
          order: 3,
          groups: [
            {
              name: 'Direct Facebook Lead',
              desc: 'A lead that signed up directly on Facebook',
              sources: ['fbleads'],
              extrapolation_type: 'Legacy',
            },
            {
              name: 'Phone Call',
              desc: 'A lead that saw your ad and then called your business',
              sources: ['offline_events/AddToCart'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: true,
          conversion_name: 'Leads',
          show_total: true,
          show_overview: true,
          is_kpi: true,
          is_sub_kpi: false,
          est_conversion_name: 'New Customers',
          cost_per_metric: 1,
          stage_target: 'leads',
        },
        {
          name: 'Intros',
          icon: 'fas fa-door-open',
          desc: 'An overview of customers booking intros at your business',
          support: ['conversions'],
          order: 4,
          groups: [
            {
              name: 'Intros',
              desc: 'Amount of intros booked at your business',
              sources: ['offline_events/Contact'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Intros',
          est_conversion_name: '',
          show_total: true,
          show_overview: true,
          is_kpi: false,
          is_sub_kpi: false,
          cost_per_metric: 1,
        },
        {
          name: 'Clients',
          icon: 'fas fa-usd-square',
          desc: 'An overview of customers signing up for memberships at your business',
          support: ['conversions', 'revenue'],
          order: 5,
          groups: [
            {
              name: 'Members',
              desc: 'Amount of memberships at your business',
              sources: ['offline_events/StartTrial'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Clients',
          est_conversion_name: '',
          show_total: true,
          show_overview: true,
          is_kpi: false,
          is_sub_kpi: false,
          cost_per_metric: 1,
        },
      ],
    },

    // Leads
    {
      id: 4,
      icon: 'fas fa-user-plus',
      name: 'Leads',
      desc: 'The basic funnel that works for businesses focused on acquiring leads',
      stages: [
        {
          name: 'Distribution',
          icon: 'fa fa-users-class',
          desc: 'Your overall performance of your Facebook ads',
          support: ['conversions'],
          order: 1,
          groups: [
            {
              name: 'Impressions',
              desc: 'Amount of times your ads appeared on the Facebook timeline',
              sources: ['impressions'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Impressions',
          est_conversion_name: '',
          show_total: false,
          is_kpi: false,
          is_sub_kpi: false,
          show_overview: true,
          cost_per_metric: 1000,
        },
        {
          name: 'Research',
          icon: 'fa fa-search',
          desc: 'Your overall metrics of users visiting your website',
          support: ['conversions'],
          order: 2,
          groups: [
            {
              name: 'Direct Page Views',
              desc: 'Amount of times a customer visited your website directly via the url',
              sources: ['fbpixel/PageView'],
              extrapolation_type: 'Legacy',
            },
            {
              name: 'Indirect Page Views',
              desc: 'Amount of times a customer visited a page on your website from an external source',
              sources: ['fbpixel/ViewContent'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: false,
          conversion_name: 'Page Views',
          est_conversion_name: '',
          show_total: true,
          is_kpi: false,
          is_sub_kpi: false,
          show_overview: true,
          cost_per_metric: 1,
        },
        {
          name: 'Leads',
          icon: 'fa fa-user-plus',
          desc: 'Your overall metrics of lead acquisition',
          support: ['conversions'],
          order: 3,
          groups: [
            {
              name: 'Direct Facebook Lead',
              desc: 'A lead that signed up directly on Facebook',
              sources: ['fbleads'],
              extrapolation_type: 'Legacy',
            },
            {
              name: 'Phone Call',
              desc: 'A lead that saw your ad and then called your business',
              sources: ['offline_events/AddToCart'],
              extrapolation_type: 'Legacy',
            },
            {
              name: 'Website Leads',
              desc: 'A lead that signed up via your website.',
              sources: ['fbpixel/Lead'],
              extrapolation_type: 'Legacy',
            },
          ],
          estimate: true,
          conversion_name: 'Leads',
          est_conversion_name: 'New Customers',
          show_total: true,
          is_kpi: true,
          is_sub_kpi: false,
          show_overview: true,
          cost_per_metric: 1,
          stage_target: 'leads',
        },
      ],
    },
  ],
  loading: true,
  timestamp: +new Date(),
}

export default Default
