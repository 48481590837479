import EditBusinessTab from '../pages/edit'
import UsefulTools from '../pages/useful-tools'
import APIBridgesTab from '../pages/api-bridges'
import React, { ReactNode, useState, useEffect } from 'react'
import EventMappingsTab from '../pages/event-mappings'
import { ClientUploads } from '../pages/client-uploads/ClientUploads'
import { BusinessManagerHeaderProps } from './BusinessManagerHeader.types'
import { Avatar, Button, Card, CardContent, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import { BadData } from '../pages/bad-data'
import { useLocation } from 'react-router-dom'
import { ErrorCenter } from '../error-center/ErrorCenter'
import { BusinessError } from '@marketing-milk/interfaces'
import { toast } from 'react-toastify'
import { businessManagerService } from '../../../../../app/service/business-manager'

const businessTabs = [
  'settings',
  'bridges',
  'event-mappings',
  'uploads',
  'bad-data',
  'tools',
] as const
type BusinessTab = (typeof businessTabs)[number]

// Cast here trying to check if arbitrary string is in more strictly typed array
const isBusinessTab = (val: string): val is BusinessTab => businessTabs.includes(val as BusinessTab)

const tabToNumber: Record<BusinessTab, number> = {
  settings: 0,
  bridges: 1,
  'event-mappings': 2,
  uploads: 3,
  'bad-data': 4,
  tools: 5,
}

// https://v5.reactrouter.com/web/example/query-parameters
function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function BusinessManagerHeader({ business, onSubmit, isNew }: BusinessManagerHeaderProps) {
  const [activeTab, setActiveTab] = useState(0)
  const [errors, setErrors] = useState(business.errors ?? [])
  const query = useQuery()

  useEffect(() => {
    const tab = query.get('tab')

    if (tab && isBusinessTab(tab)) {
      setActiveTab(tabToNumber[tab])
    }
  }, [query])

  const resolveError = async (error: BusinessError) => {
    let toastId
    try {
      toastId = toast.info(
        <span>
          Checking if business error is resolved <i className="fa fa-spinner fa-spin ml-2" />
        </span>,
        { autoClose: false }
      )
      // not worth here to attempt to detangle and solidify the redux interface. The Backend has very clear type-safe validation here
      const success = await businessManagerService.resolveIssueWithBusiness(business.id!, error)
      if (success) {
        toast.update(toastId, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          render: 'Error has been resolved',
        })
        setErrors(errors.filter(e => e !== error))
      } else {
        toast.update(toastId, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          render: 'Error has not been resolved, be sure to read the instructions carefully',
        })
      }
    } catch (e: any) {
      toast.update(toastId, {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        render: 'Error has not been resolved, an unknown error occurred. Please contact support',
      })
    }
  }

  const businessTabs: Array<{ text: string; icon: string; query: BusinessTab; view: ReactNode }> = [
    {
      text: 'Settings',
      icon: 'cog',
      query: 'settings',
      view: <EditBusinessTab submit={onSubmit} new={isNew} />,
    },
    {
      text: 'API Bridges',
      icon: 'network-wired',
      query: 'bridges',
      view: <APIBridgesTab />,
    },
    {
      text: 'Event Mappings',
      icon: 'sign',
      query: 'event-mappings',
      view: <EventMappingsTab />,
    },
    {
      text: 'Uploads',
      icon: 'file',
      query: 'uploads',
      view: <ClientUploads />,
    },
    {
      text: 'Bad Data',
      icon: 'warning',
      query: 'bad-data',
      view: <BadData />,
    },
    {
      text: 'Useful Tools',
      icon: 'wrench',
      query: 'tools',
      view: <UsefulTools />,
    },
  ]

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            {errors.length ? (
              <Grid item xs={12}>
                <ErrorCenter errors={errors} onResolve={resolveError} />
              </Grid>
            ) : null}
            <Grid item xs={8}>
              <Grid container spacing={4}>
                <Grid item>
                  <Avatar
                    alt={business.main.business_name}
                    src={`https://graph.facebook.com/${business.main.fb_page_id}/picture?type=large`}
                    style={{ height: 75, width: 75 }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5">{business.main.business_name}</Typography>
                  <br />
                  <div className="d-flex">
                    <Typography variant="body1">
                      <i className="fa fa-users mr-2" />
                      {business.users.length}
                    </Typography>
                    <Typography className="ml-4" variant="body1">
                      <i className="fa fa-building mr-2" />
                      {business.main.city}
                      {business.main.state ? `, ${business.main.state}` : ''}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="text-right" item xs={4}>
              <a
                href={`${process.env.REACT_APP_DASHBOARD}/${business.id}/dashboard`}
                target="_blank"
              >
                <Button variant="contained" color="primary">
                  View Dashboard
                </Button>
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Tabs
                value={activeTab}
                onChange={(e, newTab) => setActiveTab(newTab)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                {businessTabs.map((tab, i) => (
                  <Tab
                    key={`tab_${i}`}
                    label={tab.text}
                    icon={<i className={`fas fa-${tab.icon}`} />}
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      {businessTabs.map((_, i) => (
        <div key={`tabpane_${i}`} style={{ display: activeTab === i ? 'block' : 'none' }}>
          {_.view}
        </div>
      ))}
    </>
  )
}
