import Link from './link'
import React, { PureComponent } from 'react'
import Guard from 'components/utility/guard'
import ListenOutsideClick from 'react-onclickoutside'
import { Dropdown as Props } from 'app/interface/config/sidebar'

interface State {
  active: boolean
}

class Dropdown extends PureComponent<Props, State> {
  state = {
    active: false,
  }

  onClick = (): void => {
    this.setState({
      active: !this.state.active,
    })
  }

  // IDE will say its 'unused' but it is used by the ListenOutsideClick
  handleClickOutside = () => {
    if (this.state.active) {
      this.setState({
        active: false,
      })
    }
  }

  render() {
    const { active } = this.state
    const { guard, text, icon, links } = this.props
    return (
      <Guard guard={guard}>
        {({ status }) => {
          return !status ? null : (
            <li
              className={`kt-menu__item kt-menu__item--submenu ${
                active ? 'kt-menu__item--open' : ''
              }`}
            >
              <button className="kt-menu__link kt-menu__toggle btn-empty" onClick={this.onClick}>
                <span className="kt-menu__link-icon">
                  <i className={`fa fa-${icon} mr-2`} />
                </span>
                <span className="kt-menu__link-text">{text}</span>
                <i className="kt-menu__ver-arrow fa fa-caret-right fa-1x" />
              </button>
              <div className="kt-menu__submenu">
                <ul className="kt-menu__subnav">
                  {links.map((link, i) => (
                    <Link key={i} {...link} />
                  ))}
                </ul>
              </div>
            </li>
          )
        }}
      </Guard>
    )
  }
}

export default ListenOutsideClick(Dropdown)
